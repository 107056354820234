import { deserializeAndValidatePacket } from './packet';

export default class Handler {
  /**
   * This class is used to register and call event handlers for remote events emitted by a remote Emitter instance.
   *
   * @param {String} namespace - the namespace to handle remote events in
   */
  constructor(namespace) {
    this.namespace = namespace;
    this._event_handlers = {};

    window.addEventListener('message', this._callEventHandlers.bind(this), { capture: false, passive: true });
  }

  /**
   * Registers an event handler to be executed in the local window upon a remote event
   *
   * @param {String} event_name - name of the event to register a handler for
   * @param {Function} callback - event handler/callback function for the event
   */
  on(event_name, callback) {
    this._event_handlers[event_name] = this._event_handlers[event_name] || [];
    this._event_handlers[event_name].push(callback);
  }

  /**
   * Event listener, that listens for remote events being emitted from a remote Emitter instance
   * and calls any event handlers that have been registered with that event.
   *
   * @param {MessageEvent} event - event object from the remote window calling postMessage
   */
  _callEventHandlers(event) {
    const { packet, is_valid } = deserializeAndValidatePacket(event.data, this.namespace);

    if (!is_valid) return;

    const event_name = packet.event_name;

    if (this._event_handlers[event_name] && this._event_handlers[event_name].length !== 0) {
      this._event_handlers[event_name].forEach(callback => {
        callback();
      });
    }
  }
}
