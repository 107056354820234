<template>
  <div class="w-full h-full onboarding-background">
    <div class="onboarding-flex-container" data-test-id="onboarding-index">
      <div class="w-full">
        <div class="logo-header" :class="brand" />
        <p
          v-if="brand === 'minim'"
          style="max-width: 242px;"
          class="onboarding-secondary-text m-auto">
          {{ $I18n.t('mobile.onboarding.index.description') }}
        </p>
      </div>

      <img
        v-if="brand === 'minim'"
        :src="landingImage"
        class="onboarding-center-image p-20"
      >

      <div class="m-b-24">
        <router-link
          tag="button"
          :to="{ name: 'onboarding_begin_path', query: { hideBackBtn: true } }"
          class="minim-mobile-primary-button m-b-24"
          data-test-id="onboarding_getting_started_btn"
        >
          {{ $I18n.t('mobile.onboarding.index.get_started') }}
        </router-link>

        <p class="logout-text onboarding-secondary-text">
          <span @click="logOut">
            {{ $I18n.t('mobile.onboarding.index.logout') }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GenericLandingLogo from '../images/index/generic-landing-logo.png';

export default {
  data() {
    return {
      landingImage: GenericLandingLogo
    };
  },

  created() {
    this.$store.commit('OnboardingImageStore/preloadGenericImages');
  },

  computed: {
    ...mapState('BrandingStore', ['brandNiceName', 'brand']),
    ...mapState('LanStore', ['lan', 'lans']),
    ...mapGetters('OnboardingImageStore', ['getImage'])
  },

  methods: {
    async logOut() {
      await this.$store.dispatch('UserStore/logout');
      window.app.reload();
    }
  }
};
</script>
