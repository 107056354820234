<template>
  <div
    :data-test-id="`${typeName}-usage-type`"
    :class="['usage-type-icon', { 'supported': (isSupported && !speedTestIsRunning) }]"
  >
    <minim-mobile-icon
      class="m-t-6 m-b-6"
      :iconName="iconName"
      :color="fontAndIconColor"
      size="medium-large"
    />
    <p :class="['minim-mobile-text secondary-font small centered', `text-${fontAndIconColor}-color`]">
      {{ $I18n.t(`mobile.speed_tests.supported_usage_types.${typeName}`) }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    typeName: {
      type: String,
      required: true
    },

    typeIcon: {
      type: String,
      required: true
    },

    speedTest: {
      type: Object,
      required: true
    },

    speedTestIsRunning: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('SpeedTestStore', ['usageTypeIsSupported']),

    isSupported() {
      return this.usageTypeIsSupported(this.typeName, this.speedTest.download_speed_kbps);
    },

    iconName() {
      if (this.speedTest.has_failed || this.speedTestIsRunning) return 'questionmark';
      else if (this.isSupported) return 'checkmark-large';
      else return this.typeIcon;
    },

    fontAndIconColor() {
      return (this.speedTestIsRunning || !this.isSupported)
        ? 'neutral-dark'
        : 'neutral-lightest';
    }
  }
};
</script>
