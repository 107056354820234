<template>
  <div class="minim-mobile-card mca-border bg-negative-color">
    <div class="text-center">
      <div class="m-b-24">
        <minim-mobile-icon
          iconName="alert-large"
          color="text-neutral-lightest-color"
          size="x-large"
        />
      </div>
      <h4 class="minim-mobile-text primary-font large light text-neutral-lightest-color">
        {{ $I18n.t('mobile.components.deprecated_banner.header') }}
      </h4>
      <p class="minim-mobile-text secondary-font large light p-t-16 text-neutral-lightest-color" v-html="this.$I18n.t('mobile.components.deprecated_banner.content')" />
      <p class="minim-mobile-text secondary-font large light p-t-16 text-neutral-lightest-color">
        {{ $I18n.t('mobile.components.deprecated_banner.sub_content') }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
};
</script>
