<template>
  <ul class="m-0 p-l-32 p-r-32 p-b-32">
    <template>
      <access-point
        v-for="(pair, index) in sortedPairedAccessPoints"
        showShareButton
        :class="index !== pairedAccessPoints.length - 1 ? 'm-b-24' : null"
        :key="`ap-list-item-${index}`"
        :accessPointId="pair[0].id"
        :enabled="isEnabled(pair)"
        :ssid="pair[0].ssid"
        :password="pair[0].shared_key"
        :radioText="singleWirelessAuthRadioText(pair)"
        :connectedDevicesCount="singleWirelessAuthDeviceCount(pair)"
        :isPrivate="pair[0].private"
        :isIsolated="pair[0].is_guest"
      />
    </template>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

import AccessPoint from './access_point';
import formatters from 'mobile/shared/utils/formatters';

export default {
  components: {
    'access-point': AccessPoint
  },

  props: {
    accessPoints: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters('LanStore', ['currentLanSupportsFeature']),
    ...mapGetters('RadioStore', ['getRadioKind', 'getRadio']),
    ...mapGetters('UnumStore', ['primaryUnum']),
    ...mapGetters('DeviceConnectionReportStore', ['getOnlineDeviceIdsForAccessPoint']),

    supportsSingleWirelessAuth() {
      return this.currentLanSupportsFeature('single_wireless_authentication');
    },

    // Puts items with a 2.4 GHz radio in the front of the list and 5 GHz in the back.
    // This is the order in which they should be displayed in the UI
    sortedAccessPoints() {
      return [...this.accessPoints].sort((a, b) => {
        const aRadioKind = this.getRadioKind(a.radio_id);
        const bRadioKind = this.getRadioKind(b.radio_id);

        if (aRadioKind === bRadioKind) {
          return 0;
        } else if (aRadioKind === 'wifi_2_4') {
          return -1;
        } else {
          return 1;
        }
      });
    },

    pairedAccessPoints() {
      return this.sortedAccessPoints.reduce((pairedAccessPoints, ap) => {
        const indexOfPairedAP = pairedAccessPoints.findIndex(aps => aps[0].ssid === ap.ssid);

        if (indexOfPairedAP === -1) {
          pairedAccessPoints.push([ap]);
        } else {
          pairedAccessPoints[indexOfPairedAP].push(ap);
        }

        return pairedAccessPoints;
      }, []);
    },

    sortedPairedAccessPoints() {
      let order = { 'home': 0, 'work': 1 };

      return [...this.pairedAccessPoints].sort((a,b) => {
        let a_label = a[0].label;
        let b_label = b[0].label;

        if (a_label === b_label) {
          return a[0].ssid.toLowerCase() > b[0].ssid.toLowerCase() ? 1 : -1;
        }

        return order[a_label] > order[b_label] ? 1 : -1;
      });
    }
  },

  methods: {
    niceNameForRadioKind(radioId) {
      const radioKind = this.getRadioKind(radioId);

      return this.$I18n.t(`mobile.access_points.card.radio_kinds.${radioKind}`);
    },

    singleWirelessAuthRadioText(pairedAccessPoints) {
      const radioNiceNames = pairedAccessPoints.map(ap => this.niceNameForRadioKind(ap.radio_id));
      const text = formatters.createGrammaticallyCorrectList(radioNiceNames);

      return text;
    },

    singleWirelessAuthDeviceCount(pairedAccessPoints) {
      const totalConnectedDevicesCount = pairedAccessPoints.reduce((count, ap) => {
        count += this.getConnectedDeviceCountForAccessPoint(ap);
        return count;
      }, 0);

      return totalConnectedDevicesCount;
    },

    getConnectedDeviceCountForAccessPoint(accessPoint) {
      return this.getOnlineDeviceIdsForAccessPoint(accessPoint.id).length;
    },

    isEnabled(pairedAccessPoints) {
      return pairedAccessPoints.some(ap => {
        // should only be considered enabled if both the radio and the ap are enabled.
        // if it's ever just one that's enabled it won't actually work
        return ap.enabled && this.getRadio(ap.radio_id).config_enabled;
      });
    }
  }
};
</script>
