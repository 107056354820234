<template>
  <cancel-button-container
    mode="backToTab"
    tabToGoBackTo="router"
  >
    <div class="add-device-network-container">
      <div>
        <div class="m-b-32">
          <h1 class="onboarding-header-text">
            {{ $I18n.t('mobile.onboarding.add_devices.title') }}
          </h1>
          <p class="onboarding-secondary-text">
            {{ $I18n.t('mobile.onboarding.add_devices.body') }}
          </p>
        </div>
        <div class="minim-mobile-list add-device-list-container">
          <li
            class="flex-container align-center justify-between minim-mobile-list-item"
          >
            <div class="flex-container align-center m-r-24">
              <div class="p-l-16 m-r-12"><img :src="getHardwareKindImage" class="center-image" style="height: 40px; width: 40px"></div>
              <p class="m-0 minim-mobile-text secondary-font large">
                {{lan.nice_name}}
              </p> 
            </div>
            <minim-mobile-radio-button :selected="true" class="m-r-16"/>
          </li>
        </div>
      </div>
      <div>
        <button
          class="minim-mobile-primary-button m-b-24"
          style="font-weight: 200"
          @click="initAddMeshExtenderFlow"
        >
          {{ $I18n.t('mobile.onboarding.add_devices.continue') }}
        </button>
        <button
          class="minim-mobile-primary-button ghost primary-ghost-button"
          style="font-weight: 200"
          @click="$router.push({name: 'add_new_device_path'})"
        >
          {{ $I18n.t('mobile.onboarding.add_devices.now_network_btn') }}
        </button>
      </div>
    </div>
  </cancel-button-container>
</template>
<script>

import CancelButtonContainer from '../components/shared/cancel_button_container.vue';
import MinimMobileRadioButton from 'mobile/shared/components/global/minim_mobile_radio_button';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    'cancel-button-container': CancelButtonContainer,
    'minim-mobile-radio-button': MinimMobileRadioButton
  },

  data(){
    return {
    };
  },

  async beforeMount() {
    await this.$store.dispatch('UnumStore/index');
  },

  computed: {
    ...mapState('LanStore', ['lan']),
    ...mapGetters('UnumStore', ['primaryUnum', 'unums']),

    getHardwareKindImage() {
      return (this.primaryUnum || {}).hardware_kind_image;
    }
  },

  methods: {
    initAddMeshExtenderFlow() {
      this.$store.commit('OnboardingImageStore/preloadHardwareImages', this.primaryUnum.hardware_kind_id);
      this.$store.commit('OnboardingImageStore/preloadGenericImages');
      this.$store.commit('OnboardingStore/clearOnboarding');

      let unumsThatNeedUpdate = this.unums.filter(unum => unum.is_active && unum.is_firmware_update_available);
      unumsThatNeedUpdate = unumsThatNeedUpdate.sort(unum => (unum.id === this.primaryUnum.id ? -1 : 1));

      const updateRequired = unumsThatNeedUpdate.some(unum => unum.is_active && unum.is_firmware_update_required);

      if (unumsThatNeedUpdate.length && updateRequired) {
        this.$router.push({
          name: 'onboarding_firmware_update_path',
          query: {
            is_add_extender_flow: true,
            mesh_node: false,
            unums_to_update_ids: unumsThatNeedUpdate.map(({ id }) => id),
            update_is_required: updateRequired,
            tab_name: 'onboarding'
          }
        });
      } else {
        this.$router.push({
          name: 'qr_code_fork_path',
          params: {
            mesh_node: true
          },
          query: {
            back_path: { name: 'add_device_confirmation' }
          }
        });
      }
    }
  }
};
</script>
