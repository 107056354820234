const routes = [
  {
    path: '/customer_support_info/index',
    component: () => import(/* webpackChunkName: "support" */ './views/customer_support_info/index.vue'),
    name: 'customer_support_info_index_path'
  },
  {
    path: '/customer_support_info/show',
    component: () => import(/* webpackChunkName: "support" */ './views/customer_support_info/show.vue'),
    name: 'customer_support_info_show_path'
  },
  {
    path: '/customer_support_info/provide_feedback',
    component: () => import(/* webpackChunkName: "support" */ './views/customer_support_info/provide_feedback.vue'),
    name: 'customer_support_info_provide_feedback_path'
  },
  {
    path: '/help_guide/index',
    component: () => import(/* webpackChunkName: "support" */ './views/help_guide/index.vue'),
    name: 'help_guide_index_path'
  },
  {
    path: '/subscription_pop_up/:productKey',
    component: () => import(/* webpackChunkName: "support" */ './views/subscription_pop_up'),
    name: 'subscription_pop_up',
    meta: {
      hideBottomNavbar: true,
      hideTopNavbar: true,
      hideNetworkOfflineBanner: true,
      statusBarStyle: {
        bgColorHex: '#000000',
        textMode: 'light'
      }
    }
  }
];

export default routes;
