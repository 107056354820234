import apply_metadata from 'mobile/shared/utils/apply_metadata';

import OnboardingIndex from './views/index.vue';
import BeginOnboarding from './views/begin_onboarding.vue';

//New Add A Device view

import AddNewDevice from './views/add_new_device.vue';
import AddExtender from './views/add_extender.vue';

const routes = [
  {
    path: '/onboarding/',
    component: OnboardingIndex,
    name: 'onboarding_path'
  },
  {
    path: '/onboarding/begin',
    component: BeginOnboarding,
    name: 'onboarding_begin_path'
  },
  {
    path: '/onboarding/qr_code_fork',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/qr_code_fork.vue'),
    name: 'qr_code_fork_path'
  },
  {
    path: '/onboarding/setup_device_instructions',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/setup_device_instructions.vue'),
    name: 'setup_device_instructions_path'
  },
  {
    path: '/onboarding/select_hardware_kind',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/select_hardware_kind.vue'),
    name: 'onboarding_select_hardware_kind_path'
  },
  {
    path: '/onboarding/enter_mac_address',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/enter_mac_address.vue'),
    name: 'onboarding_enter_mac_address_path'
  },
  {
    path: '/onboarding/scan_qr_code',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/scan_qr_code.vue'),
    name: 'scan_qr_code_path'
  },
  {
    path: '/onboarding/poll_router_for_connection',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/poll_router_for_connection.vue'),
    name: 'poll_router_for_connection_path'
  },
  {
    path: '/onboarding/firmware_update',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/firmware_update.vue'),
    name: 'onboarding_firmware_update_path'
  },
  {
    path: '/onboarding/wifi_optimization',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/wifi_optimization.vue'),
    name: 'onboarding_wifi_optimization_path',
    meta: {
      statusBarStyle: null
    }
  },
  {
    path: '/onboarding/specify_network_name',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/specify_network_name.vue'),
    name: 'specify_network_name_path',
  },
  {
    path: '/onboarding/network_setup',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/network_setup.vue'),
    name: 'network_setup_path'
  },
  {
    path: '/onboarding/network_setup_form',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/network_setup_form.vue'),
    name: 'network_setup_form_path'
  },
  {
    path: '/onboarding/add_device_fork',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/add_device_fork.vue'),
    name: 'add_device_fork_path'
  },
  {
    path: '/onboarding/manual_extender_registration',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/manual_extender_registration.vue'),
    name: 'manual_extender_registration_path'
  },
  {
    path: '/onboarding/activate_extender_instructions',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/activate_extender_instructions.vue'),
    name: 'activate_extender_instructions_path'
  },
  {
    path: '/onboarding/add_extender_to_network',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/add_extender_to_network.vue'),
    name: 'add_extender_to_network_path'
  },
  {
    path: '/onboarding/ethernet_instructions',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/ethernet_instructions.vue'),
    name: 'onboarding_ethernet_instructions_path'
  },
  {
    path: '/onboarding/onboarding_complete',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/onboarding_complete.vue'),
    name: 'onboarding_complete_path'
  },
  {
    path: '/onboarding/place_extender_instructions',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/place_extender_instructions.vue'),
    name: 'place_extender_instructions_path'
  },
  {
    path: '/onboarding/join_existing_network',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/join_existing_network/index.vue'),
    name: 'join_existing_network_path'
  },
  {
    path: '/onboarding/get_in_touch',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/join_existing_network/get_in_touch.vue'),
    name: 'get_in_touch_path'
  },
  {
    path: '/onboarding/setup_wizard/choose_connection_type',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/setup_wizard/choose_connection_type.vue'),
    name: 'choose_connection_type_path',
    meta: {
      statusBarStyle: null
    }
  },
  {
    path: '/onboarding/setup_wizard/manual_connection_instructions',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/setup_wizard/manual_connection_instructions.vue'),
    name: 'manual_connection_instructions_path'
  },
  {
    path: '/onboarding/setup_wizard/admin_credentials',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/setup_wizard/admin_credentials.vue'),
    name: 'admin_credentials_path'
  },
  {
    path: '/onboarding/setup_wizard/set_connection',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/setup_wizard/set_connection/index.vue'),
    name: 'set_connection_path'
  },
  {
    path: '/onboarding/custom_setup_instructions',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/custom_setup_instructions/index.vue'),
    name: 'custom_setup_instructions_path'
  },
  {
    path: '/onboarding/failed_to_activate',
    component: () => import(/* webpackChunkName: "onboarding" */ './views/failed_to_activate.vue'),
    name: 'failed_to_activate_path',
    props: true
  },
  {
    path: '/onboarding/add_new_device',
    component: AddNewDevice,
    name: 'add_new_device_path'
  },
  {
    path: '/onboarding/add_device_confirmation',
    component: AddExtender,
    name: 'add_device_confirmation'
  }
];

export default apply_metadata(routes, {
  layout: 'onboarding',
  skipAppRefreshOnResume: true,
  statusBarStyle: {
    bgColorHex: '#FFFFFF',
    textMode: 'dark'
  }
});
