import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapGetters('NativeInfoStore', ['currentPlatform']),

    generateStoreUrl() {
      const appStoreUrls = {
        motosync: {
          ios: 'https://apps.apple.com/us/app/motosync/id1561012160',
          android: 'https://play.google.com/store/apps/details?id=com.motosync.mobile',
          browser: 'https://www.minim.co/router-setup',
        },
        minim: {
          ios: 'https://apps.apple.com/us/app/minim/id1257458936',
          android: 'https://play.google.com/store/apps/details?id=co.minim.mobile',
          browser: 'https://www.minim.co/router-setup',
        }
      };
      return appStoreUrls[this.brand][this.currentPlatform.toLowerCase()];
    }
  },

  methods: {
    openAppStore() {
      this.$native.exec('open_external_url', this.generateStoreUrl);
    }
  }
};
