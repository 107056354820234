<template>
  <div class="minim-mobile-card security-status-card">
    
    <div class="flex-container justify-between">
      <div class="flex-container align-center grow-1">
        <h2 class="minim-mobile-text primary-font small">{{ $I18n.t('profiles') }}</h2>
        <minim-mobile-icon
          class="informational-button"
          style="top: 3px;"
          iconName="info"
          size="x-small"
          name="profile-info-tip"
        />
        <info-tippy
          to="profile-info-tip"
          ref="profile-info-tip"
          trigger="click"
          :textAlignLeft="true"
        >
          <span class="minim-mobile-text secondary-font medium text-neutral-lightest-color">
            {{ $I18n.t('mobile.profiles.index.info') }}
          </span>
        </info-tippy>
      </div>
      <minim-mobile-card-link
        :text="$I18n.t('manage')"
        :to="{
          name: 'people_path',
          params: {
            lanId: $route.params.lanId
          }
        }"
      />
    </div>

    <div
      :class="['status-icon-container', (uniqiuePausedDevicesCount > 0 ? 'danger' : 'safe')]"
    >
      <minim-mobile-icon
        :iconName="pausedDevicesIcon"
        color="neutral-darker"
        size="x-large"
      />
    </div>

    <h3 class="minim-mobile-text primary-font medium-large centered">
      <span>{{ pausedDevicesText }}</span>
    </h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoTippy from 'mobile/shared/components/buttons/info_tippy.vue';

export default {
  components: {
    'info-tippy': InfoTippy
  },

  computed: {
    ...mapGetters({
      pausedDevices: 'PauseStatusStore/pausedDevices'
    }),

    pausedDevicesText() {
      if(this.uniqiuePausedDevicesCount > 0) {
        return this.$I18n.t('mobile.profiles.index.pause_all_devices_card.devices_paused', { unique_paused_device_count: this.uniqiuePausedDevicesCount });
      }
      return this.$I18n.t('mobile.profiles.index.pause_all_devices_card.no_devices_paused');
    },

    pausedDevicesIcon() {
      return this.uniqiuePausedDevicesCount > 0 ? 'paused' : 'profile';
    },

    /* Devices can be paused for two reasons, being paused indefinitely by a parent and ending up there via a parental control restriction,
     * This causes them to show up in the paused devices array twice. However, when displaying the count of paused devices we should only show
     * the number of unique devices. That's what this propety is for. */
    uniqiuePausedDevicesCount() {
      return (this.pausedDevices || []).filter((value, index, self) => {
        // Get the first index with a matching device ID
        const firstMatchingIndex = self.findIndex(val => value.device_id == val.device_id);

        // Return if the found index matches the current index. If not, this device will be filtered OUT of the final count
        return firstMatchingIndex === index;
      }).length;
    }
  }
};
</script>
