<template>
  <div class="custom-overlay">
    <div class="custom-overlay-button-container">
      <slot name="btnContainer"></slot>
      <button v-on:click.stop @click="$emit('close')"
        class="minim-mobile-primary-button minim-icon-btn-rounded x-small-rounded negative-color shadow-large">
        <minim-mobile-icon iconName="close" color="neutral-lightest-color" size="x-small" />
      </button>
    </div>
  </div>
</template>
  
<script>

export default {
  props: {
    // The position of the button container you want to position for example: start, center, end
    justifyContent: {
      type: String,
      default: 'end'
    },
    // The style of the buttons inside the button container you want to display: for example flex, block
    btnDisplayStyle: {
      type: String,
      default: 'flex'
    },
  },
};
</script>

<style scoped>
.custom-overlay {
  z-index: 9;
  background: rgba(255, 255, 255, 0.65);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 3px;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  height: 100%;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.custom-overlay-button-container {
  display: flex;
}
</style>
  