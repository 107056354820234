const SKELETON_CLASS_NAME = 'skeleton-overlay';

// The following functions apply and remove any styles provided to the directive to the element the directive is on. These styles are applied directly to
// the element, and not the psuedo element that is used for the skeleton overlay, so do not expect that behavior. If you want to target the psuedo element,
// creare a custom CSS class and pass that to do so.
function applyStylesToEl(el, styles) {
  Object.keys(styles).forEach(key => {
    el.style[key] = styles[key];
  });
}

function removeStylesFromEl(el, styles) {
  Object.keys(styles).forEach(key => {
    el.style[key] = null;
  });
}

/**
 * Custom Vue directive for applying skeleton loader overlay styles to an element based on it's current state.
 *
 * Can be used by adding "v-skeleton" to any element.
 * Accepts either a single boolean value, (ex: v-skeleton="true", the value should signify whether or not skeletons should be shown for that element)
 * or it can accept an object with various configurations to be applied to the skeleton overlay styling. If supplying an object that object should have a
 * property "showSkeleton" with a boolean value to signify whether or not to show the skeleton.
 *
 * Warning: This directive should only be used if the content that you are trying to overlay is 100% safe to render in a Vue template. There should be no way
 * for the content of the element to throw any errors, due to something being undefined or something of that nature. If it would be complex to protect against
 * that sort of thing, it would probably just be easier to use a component or plain old HTML/CSS to create the skeleton then to use this directive.
 *
 * @param {object} el - the element that the directive is being applied to, automatically passed in by Vue.
 * @param {object} binding - another object provided by Vue, destructed here to only use relevant properties from the object. "value" is the value passed to the directive.
 */
export default function skeleton(el, { value }) {
  if (typeof value === 'boolean') {
    value ? el.classList.add(SKELETON_CLASS_NAME) : el.classList.remove(SKELETON_CLASS_NAME);
    return;
  }

  const { showSkeleton, classes = [], styles = {} } = value;

  if (showSkeleton) {
    el.classList.add(SKELETON_CLASS_NAME, ...classes);
    applyStylesToEl(el, styles);
  } else {
    el.classList.remove(SKELETON_CLASS_NAME, ...classes);
    removeStylesFromEl(el, styles);
  }
}
