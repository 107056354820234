<template>
  <div class="text-center m-r-28 m-l-28" style="padding-bottom: 170px; z-index: 99999;">
    <h2
      class="unum-card-header-text"
      style="z-index: 99999;"
      v-html="$I18n.t('mobile.components.unum_card.cloud_services_disrupted')"
    />
    <p 
      style="z-index: 99999;"
      class="minim-mobile-text secondary-font medium text-neutral-lightest-color m-t-12  m-l-32 m-r-32">
      {{ $I18n.t('mobile.components.unum_card.feature_not_available') }}
    </p>
    <span class="flex-container justify-center m-t-24 m-b-8 align-center minim-mobile-text secondary-font medium text-neutral-lightest-color" @click="openUrl">
      <span style="z-index: 99999;">{{ $I18n.t('mobile.components.unum_card.see_current_status') }}</span>
      <minim-mobile-icon
        iconName="actionable"
        size="x-small"
        color="neutral-lightest"
        style="z-index: 99999;"
      />
    </span>
  </div>
</template>

<script>

export default {
  methods: {
    openUrl() {
      this.$native.exec('open_external_url', 'https://status.minim.co/');
    }
  }
};
</script>
