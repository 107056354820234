import persistence from 'mobile/shared/utils/persistence';
import minim_api from 'mobile/shared/utils/minim_api';

import store from 'mobile/vuex/index';
import router from 'mobile/router.js';

// Event listener to handle the app being backgrounded
async function onPause() {
  await persistence.saveApplicationState();
  await window.native.exec('set_preference', { key: 'lastBackgroundedAt', value: Date.now() });
}

// Event listener to handle the app being re-opened after being backgroundeds
async function onResume() {
  await persistence.restoreApplicationState();
  await persistence.clearSavedState();

  const currentRouteMetaData = router.currentRoute.meta || {};
  const skipAppRefresh = !!currentRouteMetaData.skipAppRefreshOnResume;
  const lastBackgroundedAt = await window.native.exec('fetch_preference', 'lastBackgroundedAt');

  if (!skipAppRefresh) {
    const sixHours = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
    const oneMinute = 60 * 1000; // 1 minute in milliseconds

    if ((new Date() - lastBackgroundedAt) >= sixHours) {
      // If the user's app has been backgrounded for 6 hours or more we should trigger a hard reload
      // of the app to ensure the latest deployed code for the app gets loaded onto their phone
      window.app.reload();
    } else if ((new Date() - lastBackgroundedAt) >= oneMinute) {
      // Else if it's been at least a minute since the user backgrounded the app
      // trigger a soft refresh of the app by invalidating the application key.
      // This will cause all currently mounted components to re-mount ensuring that data is refreshed for them.
      // This should only be called called AFTER the old application state is
      // restored to prevent bugs from the old state not being present.
      store.commit('invalidateApplicationKey');
    }
  }

  if(router.currentRoute.path.includes('onboarding')) {
    store.commit('OnboardingImageStore/setPreloadedHardwareImages');
    store.commit('OnboardingImageStore/preloadGenericImages');
  }

  if (store.getters['LanStore/currentLan']) {
    try {
      minim_api.post('api/v1/lans/{lan_id}/mobile_app_access');
    } catch (err) {
      console.error(err);
    }
  }
}

function onTouchEnd(e) {
  // Workaround against an iOS mobile Safari bug
  // Occurs because we have touch event listeners being used in some of our dependencies. In iOS Safari when these are set
  // and a user clicks on a text input more than once a touch event is fired and focus is removed from the input element,
  // disabling the users ability to type. To workaround this, on every touchend event we add focus back to the window and back to the element
  const TEXT_INPUT_TYPES = ['text', 'email', 'password', 'textarea'];

  if (TEXT_INPUT_TYPES.includes(e.target.type)) {
    window.focus(); // first give focus back to the window
    setTimeout(() => { e.target.focus(); }, 0); // then after the event loop tick, give it back to the input target
  }
}

function onAndroidBackButtonPress() {
  const backButtonOverride = store.getters['HistoryStore/getNativeBackButtonOverride'];

  if (backButtonOverride) {
    if (typeof backButtonOverride === 'function') {
      backButtonOverride();
    } else {
      router.push(backButtonOverride);
    }

    return;
  }

  const currentRoute = router.currentRoute;
  const previousRoute = store.getters['HistoryStore/getPreviousTabPath'];

  if (previousRoute) {
    // If we've stored a path in the back stack, navigate to that on android back button press
    router.push({
      path: previousRoute.path,
      name: previousRoute.name,
      params: previousRoute.params,
      query: previousRoute.query
    });
  } else if (router.currentRoute.name === 'lan_path') {
    // else if the current route is the lan index page then navigate to the network switcher page
    router.push({ name: 'network_switcher_path', query: { tab_name: 'router' } });
  } else if (router.currentRoute.name === 'network_switcher_path') {
    // else if the current route is the home page then we want to exit the app
    window.native.exec('exit_app');
  } else if (currentRoute.meta && currentRoute.meta.tab_root) {
    // else if were on a page that is the root of that tab in the app, we want the back button to direct the user to the home page
    router.push({
      name: 'lan_path',
      params: { lanId: store.getters['LanStore/currentLan'] },
      query: { tab_name: 'router' }
    });
  } else {
    // otherwise the back button should do nothing
    return;
  }
}

export function listenForNativeEvents(platform) {
  window.native.on('pause', onPause);
  window.native.on('resume', onResume);

  if (platform === 'ios') {
    document.addEventListener('touchend', onTouchEnd);
  }

  if (platform === 'android') {
    window.native.on('backbutton', onAndroidBackButtonPress);
  }
}
