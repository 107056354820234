<template>
  <minim-mobile-modal :open="open" @close="close" :showCloseButton="true">
    <div class="flex-container column align-center">
      <p class="minim-mobile-text primary-font centered medium">
        {{ headerText }}
      </p>
      <div>
        <p class="minim-mobile-text secondary-font large centered p-t-32 p-b-32">
          {{ bodyText }}
        </p>
      </div>
      <button class="minim-mobile-primary-button" @click="handleUpdateNow">
        {{ $I18n.t('mobile.app_update_modal.btn_text') }}
      </button>
    </div>
  </minim-mobile-modal>
</template>

<script>

import { mapState } from 'vuex';
import OpenAppStoreMixin from 'mobile/shared/mixins/open_app_store_mixin';

export default {
  mixins: [OpenAppStoreMixin],

  props: {
    open: {
      type: Boolean,
      default: false
    },

    headerText: {
      type: String,
      required: true
    },

    bodyText: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState('NativeInfoStore', ['build_info'])
  },

  methods: {
    async close() {
      await this.$native.exec('set_preference', { key: `ignored_update_${this.build_info.version}`, value: true });
      this.$emit('close');
    },

    handleUpdateNow() {
      this.openAppStore();
      this.$emit('close');
    }
  }
};
</script>
