<template>
  <div>
    <div
      v-if="showHighlightBubble"
      class="feature-highlight-bubble"
      :style="highlightBubbleStyle"
    />

    <minim-mobile-modal
      :open="showHighlights"
      :showCloseButton="false"
      class="p-48 text-center"
      :zIndexLg="true"
    >
      <div v-if="showHighlights" class="text-center m-t-12">
        <h4 class="minim-mobile-text primary-font medium m-b-32">
          {{ $I18n.t(currentHighlight.header) }}
        </h4>
        <p
          v-for="(textBlock, index) in currentHighlight.description"
          :key="`${currentHighlightIndex}-highlight-description-${index}`"
          class="minim-mobile-text secondary-font large light m-b-16"
        >
          {{ $I18n.t(textBlock) }}
        </p>
        <div class="flex-container column align-center p-32">
          <div v-if="highlights.length > 1" class="steps flex-container align-center p-b-32">
            <div
              v-for="(_, i) in highlights.length"
              class="feature-highlight-step-indicator"
              :class="currentHighlightIndex === i ? 'active bg-primary-color' : ''"
              :key="`highlight-step-indicator-${i}`"
            />
          </div>
          <button data-test-id="mobile-feature-highlight-next-button" class="minim-mobile-primary-button bg-primary-color" @click="nextOrEnd">
            {{ lastSlide ? this.$I18n.t('ok') : this.$I18n.t('next') }}
          </button>
        </div>
      </div>
    </minim-mobile-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { markHighlightSeen } from 'mobile/vuex/stores/highlights_store';

function getOffset(el) {
  const rect = el.getBoundingClientRect();

  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}

export default {
  props: {
    open: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      currentHighlightIndex: 0,
      highlightBubbleReady: false
    };
  },

  watch: {
    showHighlights() {
      if (this.showHighlights) {
        setTimeout(() => this.highlightBubbleReady = true, 1000);
      }
    }
  },

  computed: {
    ...mapState('HighlightsStore', ['highlights']),
    ...mapGetters({
      currentLanSupportsAllFeatures: 'LanStore/currentLanSupportsAllFeatures',
      currentUser: 'UserStore/currentUser'
    }),

    lastSlide() {
      return this.currentHighlightIndex === this.highlights.length - 1 ;
    },

    buttonText() {
      return !this.lastSlide ? this.$I18n.t('ok') : this.$I18n.t('next');
    },

    currentHighlight() {
      return this.highlights[this.currentHighlightIndex];
    },

    showHighlights() {
      return this.highlights.length > 0;
    },

    showHighlightBubble() {
      return this.showHighlights && this.highlightBubbleReady;
    },

    highlightBubbleStyle() {
      if (!this.currentHighlight) {
        return null;
      }

      const el = document.getElementById(this.currentHighlight.highlighted_element_id);
      if (!el) {
        return null;
      }

      const offset = getOffset(el);
      const padding = 15;

      const styles = {
        // Set the size of the highlight bubble to be slightly larger than the original element
        width: `${el.offsetWidth + padding}px`,
        height: `${el.offsetHeight + padding}px`,

        // Set the position of the highlight bubble to be the same as the original element
        // adjust for the additonal padding that was added to the element
        top: `${offset.top - (padding / 2)}px`,
        left: `${offset.left - (padding / 2)}px`,
      };

      if (el.offsetWidth > el.offsetHeight) {
        // If the bubble is wider than tall, make it a rounded edge rectangle instead
        // of a circle.
        styles.borderRadius = '24px';
      }

      return styles;
    }
  },

  methods: {
    async nextOrEnd() {
      if (!this.currentHighlight) {
        // if the user taps the OK button multiple times quickly, this method gets
        // called multiple times before the first invocation can actually clear the
        // HighlightsStore and hide the modal.
        return;
      }

      // store the current highlight key so we can mark it seen after we move the
      // user to the next highlight or clear the highlight data.
      const currentKey = this.currentHighlight.key;
      this.highlightBubbleReady = false;

      if(this.lastSlide) {
        this.$store.commit('HighlightsStore/setHighlightsData', []);
        this.currentHighlightIndex = 0;
      } else {
        this.currentHighlightIndex += 1;
        setTimeout(() => this.highlightBubbleReady = true, 1000);
      }

      // mark the highlight as seen last to hopefully keep the UI feeling snappy.
      await markHighlightSeen(currentKey);
    }
  }
};
</script>
