<template>
  <h2 class="unum-card-header-text" v-html="headerText" />
</template>

<script>
import { mapGetters } from 'vuex';
// This variable is declared outside of data so it persists between the component
// mounting and unmounting. We only want it to change after the user has hard closed
// the app and re-opened. Until that happens, the adjective should persist.
let currentAdjective = null;

export default {
  created() {
    if (!currentAdjective) {
      currentAdjective = this.getRandomAdjective();
    }
  },

  computed: {
    ...mapGetters('UnumStore', [
      'primaryUnum',
      'allUnumsAreUnmanaged'
    ]),
    headerText() {
      var text;
      if(this.allUnumsAreUnmanaged) {
        text = this.$I18n.t('mobile.components.unum_card.modem_or_device', {
          adjective: this.makeAdjectiveUpperCase(currentAdjective),
          text: this.primaryUnum.is_modem ? this.$I18n.t('mobile.onboarding.device_kinds.modem')
            : this.$I18n.t('mobile.onboarding.device_kinds.device')
        });
      } else {
        text = this.$I18n.t('mobile.components.unum_card.your_network_looks', {
          adjective: currentAdjective || this.$I18n.t('mobile.components.unum_card.amazing') // default to "amazing" in case currentAdjective isn't ever set somehow
        });
      }
      return text;
    }
  },

  methods: {
    getRandomAdjective() {
      const adjectives = [
        'amazing',
        'excellent',
        'exceptional',
        'fabulous',
        'fantastic',
        'magnificent',
        'spectacular',
        'superb',
        'terrific'
      ];

      let adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
      adjective = this.$I18n.t(`mobile.components.unum_card.${adjective}`);

      return adjective;
    },

    makeAdjectiveUpperCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

  }
};
</script>
