<template>
  <div v-if="tabs && tabs.length > 1" class="minim-mobile-tabbed-card">
    <ul class="tabs">
      <tab
        v-for="(tabName, tabIndex) in tabs"
        @changeTab="changeTab"
        :tabIndex="tabIndex"
        :tabName="tabName"
        :widthPercentage="widthPercentage"
        :orientation="determineTabOrientation(tabIndex)"
        :selected="tabIsSelected(tabIndex)"
        :key="`${tabName}-${tabIndex}`"
        :tabIconName="tabIconName"
      />
    </ul>
    <div :class="{
      'content': true,
      'rounded-border-top-right': roundContentCardTopRightBorder,
      'rounded-border-top-left': roundContentCardTopLeftBorder
    }">
      <slot :name="selectedTabName" />
    </div>
  </div>
  <div class="minim-mobile-card unpadded" v-else>
    <span class="p-l-32 p-t-32 flex-container align-center">
      <minim-mobile-icon
        v-if="tabIconName"
        :iconName="tabIconName"
        class="m-r-6 m-b-4"
        color="neutral-darkest"
        size="x-small"
      />
      <h2 class="minim-mobile-text primary-font small">{{ selectedTabName }}</h2>
    </span>
    <slot :name="selectedTabName" />
  </div>
</template>

<script>
import Tab from './tab';

export default {
  components: {
    'tab': Tab
  },

  props: {
    tabs: {
      type: Array,
      required: true,
      validator(value) {
        if (value.length !== new Set(value).size) {
          console.warn('All tab names must be unique');
          return false;
        }
        return true;
      }
    },

    initialTabIndex: {
      type: Number,
      required: false,
      default: 0
    },

    tabIconName: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      selectedTabIndex: null
    };
  },

  mounted() {
    this.selectedTabIndex = this.$props.initialTabIndex;
  },

  computed: {
    roundContentCardTopRightBorder() {
      return !(this.selectedTabIndex === (this.tabs.length - 1));
    },

    roundContentCardTopLeftBorder() {
      return !(this.selectedTabIndex === 0 );
    },

    widthPercentage() {
      return (100 / this.tabs.length);
    },

    selectedTabName() {
      return this.tabs[this.selectedTabIndex];
    }
  },

  methods: {
    changeTab(tabIndex) {
      this.selectedTabIndex = tabIndex;

      this.$emit('tabChanged', this.tabs[this.selectedTabIndex]);
    },

    tabIsSelected(tabIndex) {
      return this.selectedTabIndex === tabIndex;
    },

    /*
     * This determines what "orientation" (i.e placement) a given tab has based on it's position in the
     * array of tabs. The value returned from this is used to decide what is rendered and what CSS classes
     * are output in the <tab /> component for the tab.
     */
    determineTabOrientation(tabIndex) {
      const lastTabIndex = this.tabs.length - 1;

      if (this.selectedTabIndex === tabIndex) {
        if (tabIndex === 0) return 'left'; // if the current tab is the selected tab AND the first tab then it is oriented to the left
        if (tabIndex === lastTabIndex) return 'right'; // if the current tab is the selected tab AND the last tab then it is oriented to the right
        else return 'center'; // if the current tab is the selected tab AND NOT the first or last tab then it is oriented in the center
      }

      // if the current tab is the first or last tab, and it is not selected,
      // it is "underneath" (AKA overlapped by) all the other tabs
      if (tabIndex === 0 || tabIndex === lastTabIndex) {
        return 'underneath';
      }

      // if the current tab is not slected, nor the first or last tab,
      // and is lower in the list than the selected tab, then it is oriented to the right
      if (tabIndex < this.selectedTabIndex) {
        return 'right';
      }

      // if the current tab is not slected, nor the first or last tab,
      // and is higher in the list than the selected tab, then it is oriented to the left
      if (tabIndex > this.selectedTabIndex) {
        return 'left';
      }
    }
  }
};
</script>
