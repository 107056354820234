<template>
  <div :class="`home-component ${$I18n.locale}`">
    <component :is="layout" />

    <highlights-modal />

    <!-- Portal target for the minim mobile modal. Placed here, specifically, below every other component/element,
        so that it can be displayed over every other element on the page, on every single page. -->
    <portal-target name="minim-mobile-modal-portal" multiple />

    <!-- Portal target for the minim-modal component -->
    <portal-target name="modal-portal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { applyBranding } from 'mobile/shared/utils/in_app_branding';
import GetProperPathMixin from 'mobile/shared/mixins/root_path_mixin';

import HighlightsModal from './shared/components/modals/highlights_modal';

import AuthenticationLayout from './shared/components/layouts/authentication_layout.vue';
import MainLayout from './shared/components/layouts/main_layout.vue';
import OnboardingLayout from './shared/components/layouts/onboarding_layout.vue';
import VideoWalkthroughLayout from './shared/components/layouts/video_walkthrough_layout.vue';

export default {
  components: {
    'highlights-modal': HighlightsModal,
    'authentication-layout': AuthenticationLayout,
    'main-layout': MainLayout,
    'onboarding-layout': OnboardingLayout,
    'video-walkthrough-layout': VideoWalkthroughLayout
  },
  mixins: [GetProperPathMixin],

  computed: {
    ...mapGetters({
      lan: 'LanStore/currentLan',
      primaryColor: 'LanStore/primaryColor',
      terms: 'ServiceTermsStore/getNonDataConsentTerms'
    }),

    layout() {
      // If a layout is specified via metadata use that, else default to the main layout
      return (this.$route.meta.layout || 'main') + '-layout';
    }
  },

  async mounted() {
    this.getProperPath();
  },

  watch: {
    primaryColor(value) {
      applyBranding(value);
    }
  }
};
</script>
