<template>
  <section>
    <div class="flex-container justify-between m-t-32">
      <h2 v-skeleton="showSkeleton" class="minim-mobile-text primary-font small">Filter lists</h2>
      <minim-mobile-card-link
        :showSkeleton="showSkeleton"
        text="Manage"
        :to="{
          name: 'person_content_filters_management_path',
          params: {
            lanId: $route.params.lanId,
            personId
          }
        }
      "/>
    </div>

    <div class="flex-container align-center m-t-32">
      <minim-mobile-icon
        v-skeleton="{ showSkeleton, classes: ['rounded-infinite'], styles: { height: '60px', width: '60px' } }"
        iconName="filters"
        size="large"
        color="primary"
      />
      <div class="m-l-16">
        <h2
          v-skeleton="{ showSkeleton, styles: { width: '124px', height: '20px' } }"
          class="minim-mobile-text primary-font large"
        >
          {{ $I18n.n(filteredDomains) }}
        </h2>
        <p
          v-skeleton="{ showSkeleton, styles: { width: '124px', height: '20px' } }"
          class="minim-mobile-text secondary-font medium text-neutral-darker-color p-t-4"
        >
          websites being filtered
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    filteredDomains: {
      type: Number,
      default: 0
    },

    personId: {
      type: String,
      required: true
    },

    showSkeleton: {
      type: Boolean,
      required: true
    }
  }
};
</script>
