import Commander from './commander';
import Executor from './executor';
import Emitter from './emitter';
import Handler from './handler';

const event_types = Object.freeze({
  COMMAND: 'command',
  EVENT: 'event'
});

export default class RemoteBridge {
  /**
   * This class composes four different sub classes to push commands and to listen to and handle events
   * in a remote window. For it to work properly it requires that an instance exists in BOTH the local window
   * and the remote window that you want it to interact with.
   *
   * @param {String} local_name - name for the local window the instance is running in
   * @param {String} remote_name - name for the remote window we want the instance to interact with
   * @param {Boolean} running_on_device - whether or not the app is running on a mobile device
   * @param {Window} remote_window - the remote window instance we want to interact with
   * @param {Object} functions - A set of functions to make available to a remote RemoteBridge instance
   * @param {Array} events - An array of event names to track in the current window and re-emit to the remote window
   * @param {Object} fallback_functions - A set of fallback functions for if we're running in an environment where the original functions set would not be available
   */
  constructor(local_name, remote_name, running_on_device, remote_window, functions, events, fallback_functions) {
    this.running_on_device = running_on_device;

    this.commander = new Commander(
      this._generateNamespace(local_name, remote_name, event_types.COMMAND),
      local_name,
      remote_name,
      running_on_device,
      remote_window,
      fallback_functions
    );

    this.executor = new Executor(
      this._generateNamespace(remote_name, local_name, event_types.COMMAND),
      remote_window,
      functions
    );

    this.emitter = new Emitter(
      this._generateNamespace(local_name, remote_name, event_types.EVENT),
      remote_window,
      events
    );

    this.handler = new Handler(this._generateNamespace(remote_name, local_name, event_types.EVENT));
  }

  /**
   * Public wrapper for this.command.exec - which sends a new command to the remote window
   *
   * @param {Strring} command_name - the name of the command to send to the remote window
   * @param {Any} param - a single param to pass to the command
   */
  async exec(command_name, param) {
    return await this.commander.exec(command_name, param);
  }

  /**
   * Public wrapper for this.handler.on - which registers a local handler function for remote events
   *
   * @param {String} event_name - the name of the event to add a handler for
   * @param {Function} callback - the callback/event handler function
   */
  on(event_name, callback) {
    this.handler.on(event_name, callback);
  }

  /**
   * Generates a namespace for messages
   *
   * @param {String} emitter_name - the name of the window emitting the event
   * @param {String} reciever_name - the name of the window recieving the event
   * @param {String} event_type - the type of event
   */
  _generateNamespace(emitter_name, reciever_name, event_type) {
    return `${emitter_name}-to-${reciever_name}-${event_type}`;
  }
}
