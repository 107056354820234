<template>
  <h2 class="unum-card-header-text">
    {{ headerText }}
  </h2>
</template>

<script>
import { mapGetters } from 'vuex';
import UnumStatusMixin from 'mobile/shared/mixins/unum_status_mixin';

export default {
  mixins: [UnumStatusMixin],

  computed: {
    ...mapGetters('UnumStore', ['rebootingUnums']),

    headerText() {
      return this.generateUnumStatusText(this.rebootingUnums, this.$I18n.t('mobile.components.unum_card.rebooting'));
    }
  }
};
</script>
