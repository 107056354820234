<template>
  <div v-if="pollingStatus && !closed" :class="bgColor" class="configurations-banner-container">
    <div v-if="pollingStatus === 'polling'" class="flex-container align-center justify-between w-full">
      <div class="flex-container column align-start justify-between w-full">
        <p v-html="header" class="p-b-4 minim-mobile-text secondary-font large color-text-light"></p>
        <p class="minim-mobile-text secondary-font medium color-text-light">{{ text }}</p>
      </div>
      <minim-mobile-icon
        :iconName="iconName"
        color="white"
        size="medium-large"
        class="rotate"
      />
    </div>

    <div v-else-if="pollingStatus === 'success'" 
      class="flex-container align-center justify-between w-full"
      data-test-id="configuration-success-banner">
      <div class="flex-container column align-start justify-between w-full">
        <p v-html="header" class="p-b-4 minim-mobile-text secondary-font large color-text-light"></p>
        <p class="minim-mobile-text secondary-font medium color-text-light">{{ text }}</p>
      </div>
      <minim-mobile-icon
        id="checkMarkIcon"
        :iconName="iconName"
        color="white"
        size="medium-large"
      />
      <minim-mobile-icon
        id="closeBtn"
        @click="close"
        iconName="close"
        color="white"
        size="small"
        class="close-button hide"
      />
    </div>

    <div v-else class="flex-container column align-start w-full">
      <div class="flex-container align-start justify-between w-full">
        <p v-html="header" class="minim-mobile-text secondary-font large color-text-light"></p>
        <minim-mobile-icon
          @click="close"
          iconName="close"
          color="white"
          size="small"
          v-if="showCloseBtn"
        />
      </div>
      <div class="flex-container justify-between">
        <button class="primary-negative-btn" @click="applyConfig">
          {{ $I18n.t('try_again')}}
        </button>
        <button
          class="ghost-negative-btn m-l-8"
          @click="$router.push({
            name: 'customer_support_info_index_path'
          })"
        >
          {{ $I18n.t('contact_support')}}
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

import minim_api from 'mobile/shared/utils/minim_api';
import $ from 'jquery';

const POLL_INTERVAL = 5000; // 5 seconds

export default {

  props: {
    showCloseBtn: {
      type: Boolean
    },

    hideBanner: {
      type: Boolean,
      default: false
    }
  },

  async mounted(){
    if(this.hideBanner){
      this.closed = true;
      return;
    }

    if(this.pollingStatus === 'success'){
      this.fadeIcons();
    }

    let responses = await this.pollUnumsForDiffs(responses);

    if(responses.some(r => this.areConfigsPresent(r))){
      this.scrollToTop();
      if(responses.some(r => r.is_applying_config)){
        this.$store.commit('ConfigurationsStore/setPollingStatus', 'polling');
        this.pollForConfigs();
      } else {
        this.$store.commit('ConfigurationsStore/setPollingStatus', 'failure');
        return;
      }
    } else {
      return;
    }
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  data(){
    return {
      interval: null,
      closed: false
    };
  },
  computed: {
    ...mapGetters('UnumStore', ['onlineUnums']),
    ...mapGetters('ConfigurationsStore', ['getPollingStatus']),

    header(){
      switch(this.pollingStatus){
      case 'polling':
        return this.$I18n.t('mobile.access_points.show.updating_network_header');
      case 'success':
        return this.$I18n.t('mobile.access_points.show.update_successful_header');
      default:
        return this.$I18n.t('mobile.access_points.show.update_failure_header');
      }
    },

    text(){
      switch(this.pollingStatus){
      case 'polling':
        return this.$I18n.t('mobile.access_points.show.updating_network_text');
      case 'success':
        return this.$I18n.t('mobile.access_points.show.update_successful_text');
      default:
        return '';
      }
    },

    bgColor(){
      switch(this.pollingStatus){
      case 'polling':
        return 'bg-primary-color';
      case 'success':
        return 'bg-positive-color';
      default:
        return 'bg-negative-color';
      }
    },

    iconName(){
      switch(this.pollingStatus){
      case 'success':
        return 'circle-checkmark';
      case 'polling':
        return 'circle-wifi';
      default:
        return '';
      }
    },

    pollingStatus(){
      return this.getPollingStatus;
    }
  },
  methods: {

    async pollUnumsForDiffs(){
      let responses = [];

      for (let i = 0; i < this.onlineUnums.length; i++) {
        const unumId = this.onlineUnums[i].id;
        const response = await minim_api.get(`api/v1/lans/{lan_id}/unums/${unumId}/configurations/diffs_from_expected`);

        responses.push(response.data);
      }

      return responses;
    },

    async close(){
      this.closed = true;

      if(this.pollingStatus === 'failure'){
        await this.$native.exec('set_config_item', { key: 'hide_config_banner', value: true });
      }

      if(this.pollingStatus === 'success'){
        this.$store.commit('ConfigurationsStore/setPollingStatus', null);
      }

      return;
    },

    areConfigsPresent(data){
      const configs = ['changed_ssid', 'changed_password', 'added_ssid'];

      const idx = data.diffs.findIndex(diff => {
        return configs.includes(diff.key);
      });
      return idx !== -1;
    },

    pollForConfigs(){
      let failure_count = 0;
      this.interval = setInterval(async () => {
        let responses = await this.pollUnumsForDiffs(responses);

        if(responses.some(r => r.is_applying_config)) {
          // reset failure count as the config is still applying
          failure_count = 0;
          this.$store.commit('ConfigurationsStore/setPollingStatus', 'polling');
        } else {
          if(responses.some(r => this.areConfigsPresent(r))){
            // config reports a difference, increase failure count but dont immediately report the failure
            failure_count++;
          }
          if(failure_count > 1) {
            // config application reported a diff twice in a row, must really be a difference. report the failure
            this.$store.commit('ConfigurationsStore/setPollingStatus', 'failure');
            clearInterval(this.interval);
          } else {
            this.$store.commit('ConfigurationsStore/setPollingStatus', 'success');
            this.fadeIcons();
            clearInterval(this.interval);
          }
        }
      }, POLL_INTERVAL);
    },

    async applyConfig() {
      await this.$native.exec('set_config_item', { key: 'hide_config_banner', value: false });
      this.$store.commit('ConfigurationsStore/setPollingStatus', 'polling');

      await minim_api.post('api/v1/lans/{lan_id}/apply_config.json');
    },

    fadeIcons(){
      setTimeout(
        function(){
          $('#checkMarkIcon').fadeTo(1000, 0);
          $('#closeBtn').fadeIn(1000);
        }, 2000
      );
    },

    scrollToTop() {
      $('#app-body').animate({ scrollTop: 0 }, 800);
    }
  }
};
</script>
