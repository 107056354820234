<template>
  <div class="mobile-speed-tests-legend-item">
    <div class="m-l-16 m-r-16 flex-container justify-center column text-center">
      <h5
        v-if="maxedOut"
        v-skeleton="{ showSkeleton, styles: { width: '44px', height: '25px' } }"
        class="minim-mobile-text primary-font x-large centered m-b-8"
      >
        {{ roundKbpsToMbps(hardware_kind.max_speed_test_speed_kbps) }}
      </h5>
      <h5
        v-else
        v-skeleton="{ showSkeleton, styles: { width: '44px', height: '25px' } }"
        v-html="speedHtml"
        class="minim-mobile-text primary-font x-large centered m-b-8"
      />
      <p
        v-skeleton="{ showSkeleton, styles: { width: '44px', height: '25px' } }"
        class="minim-mobile-text secondary-font text-neutral-darker-color medium centered m-b-8"
        :class="speedTestIsRunning ? 'opacity-0': null"
      >
        {{ units }}
      </p>
      <minim-mobile-icon
        :skeletal="showSkeleton"
        :iconName="iconName"
        :color="iconColor"
        size="small"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpeedTestsMixin from 'mobile/shared/mixins/speed_tests_mixin';

export default {
  props: {
    showSkeleton: {
      type: Boolean,
      required: true
    },

    iconName: {
      type: String,
      required: true
    },

    iconColor: {
      type: String,
      required: true
    },

    speed: {
      type: [String, Number],
      required: true
    },

    units: {
      type: String,
      required: true
    },

    speedTestIsRunning: {
      type: Boolean,
      required: true
    },

    maxedOut: {
      type: Boolean,
      default: false
    }
  },

  mixins: [SpeedTestsMixin],

  computed: {
    ...mapGetters({
      getHardwareKind: 'HardwareKindStore/getHardwareKind',
      primaryUnum: 'UnumStore/primaryUnum'
    }),

    speedHtml() {
      return this.speedTestIsRunning
        ? '<i class="fas fa-spinner fa-spin neutral-dark" />'
        : this.speed;
    },

    hardware_kind(){
      return this.getHardwareKind(this.primaryUnum.hardware_kind_id) || {};
    }
  }
};
</script>
