<template>
  <div class="relative pull-to-refresh-container">
    <div v-if="loading" style="position: absolute !important; top: 24px; left: 50%; margin-left: -24px;" >
      <i class="refresh-icon fas fa-spin fa-spinner text-primary-color" style="position: absolute !important;"></i>
    </div>
    <div :style="refreshStyle">
      <transition name="fade">
        <slot v-if="!hideViewOnRefresh || (hideViewOnRefresh && !loading)"/>
      </transition>
    </div>
  </div>
</template>

<script>
const pStart = { x: 0, y: 0 };
const pCurrent = { x: 0, y: 0 };

export default {
  props: {
    allowRefresh: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxPullDownAmount: {
      type: Number,
      required: false,
      default: 100
    },
    onRefresh: {
      type: Function,
      required: true
    },
    hideViewOnRefresh: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      scrolling: false,
      timer: null,
      loading: false,
      refreshStyle: null
    };
  },

  mounted() {
    document.getElementById('app').addEventListener('touchstart', e => this.swipeStart(e), false);
    document.getElementById('app').addEventListener('touchmove', e => this.swipe(e), false);
    document.getElementById('app').addEventListener('touchend', e => this.swipeEnd(e), false);
    document.getElementById('app-body').addEventListener('scroll', e => this.scroll(e));
  },

  beforeDestroy() {
    document.getElementById('app').removeEventListener('touchstart', e => this.swipeStart(e));
    document.getElementById('app').removeEventListener('touchmove', e => this.swipe(e));
    document.getElementById('app').removeEventListener('touchend', e => this.swipeEnd(e));
    document.getElementById('app-body').removeEventListener('scroll', e => this.scroll(e));
  },

  methods: {
    scroll() {
      this.scrolling = true;
      if(this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.scrolling = false;
      }, 500);
    },

    cleanupLoadingState() {
      this.refreshStyle = { 'padding-top': '0px', 'transition': 'all 0.25s ease' };
    },

    refresh() {
      if (!this.allowRefresh) {
        return;
      }
      this.loading = true;
      this.$emit('pageRefresh', true);

      setTimeout(async () => {
        try {
          await this.onRefresh();
        }
        catch (err) {
          console.log(err);
        }

        this.loading = false;
        this.cleanupLoadingState();
        this.$emit('pageRefresh', false);
      }, 2000);
    },

    swipeStart(e) {
      if (typeof e['targetTouches'] !== 'undefined') {
        let touch = e.targetTouches[0];
        pStart.x = touch.screenX;
        pStart.y = touch.screenY;
      } else {
        pStart.x = e.screenX;
        pStart.y = e.screenY;
      }
    },

    swipe(e) {
      if (typeof e['changedTouches'] !== 'undefined') {
        let touch = e.changedTouches[0];
        pCurrent.x = touch.screenX;
        pCurrent.y = touch.screenY;
      } else {
        pCurrent.x = e.screenX;
        pCurrent.y = e.screenY;
      }

      const changeY = pStart.y < pCurrent.y ? Math.abs(pStart.y - pCurrent.y) : 0;
      const changeX = pStart.x < pCurrent.x ? Math.abs(pStart.x - pCurrent.x) : 0;
      const paddingTop = (changeY < 75 && !this.loading) ? changeY : this.maxPullDownAmount;

      if (!this.scrolling && document.body.scrollTop === 0) {
        // reason why sometimes it trigger is becasue, we are not checking it swipes horinzonal or not eg: changeX
        // so, when horizontally swipe, changeY is always less than 15px, and changeX always greater 15px
        // in order to check this -- if changeX < 20 && changeY > 40 and less than maxPullDownAmount, then
        // it means it swipes downward :) 
        if (!this.loading && changeX < 20 && changeY > 75 && changeY < this.maxPullDownAmount) {
          this.refresh();
        }
        this.refreshStyle = { 'padding-top': `${paddingTop}px` };
      }
    },

    swipeEnd() {
      if (document.body.scrollTop === 0 && !this.loading) {
        this.cleanupLoadingState();
      }
    }
  }
};
</script>