<template>
  <div @click="handleClick" class="minim-mobile-secondary-button">
    <span
      v-skeleton="{ showSkeleton, styles: { width: '176px', height: '22px' } }"
      class="minim-mobile-text secondary-font large text-neutral-darker-color"
    >
      {{ text }}
    </span>
    <span
      v-skeleton="{ showSkeleton, styles: { width: '176px', height: '22px' } }"
      class="minim-mobile-text secondary-font large text-neutral-darker-color"
      v-html="htmlText"
    >
    </span>
    <minim-mobile-icon
      v-skeleton="{ showSkeleton, classes: ['rounded-extra'], styles: { width: '22px', height: '22px' } }"
      iconName="forward"
      size="x-small"
      color="neutral-darker"
    />
  </div>
</template>

<script>
export default {
  props: {
    showSkeleton: {
      type: Boolean,
      required: false
    },

    text: {
      type: String,
      default: ''
    },

    htmlText: {
      type: String,
      default: ''
    }
  },

  methods: {
    handleClick() {
      // Emit a click event for the parent to handle
      this.$emit('click');
    }
  }
};
</script>