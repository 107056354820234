<template>
  <div>
  <a
    :id="`${name}TabIcon`"
    @click="onClick"
    :class="['nav-item', selected ? `selected bg-primary-dark-color` : null]"
  >
    <minim-mobile-icon :iconName="iconName" :color="brandColor" size="small" />
    <span v-if="selected" :class="`text text-${brandColor}-color`">
      {{ text }}
    </span>
  </a>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    homePath: {
      type: Object,
      default: null
    },

    iconName: {
      type: String,
      required: true,
    },

    text: {
      type: String
    },

    selected: {
      type: Boolean,
      default: false,
    },

    scrollToTopIfSelected: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('UserStore', ['user']),
    ...mapGetters('NativeInfoStore', ['currentPlatform']),
    ...mapGetters({
      lan: 'LanStore/currentLan',
    }),

    tabPath() {
      let p = this.$store.getters['HistoryStore/getTabPath'](this.name);

      if (p && this.$route.path != p.path && p.path !== '/') {
        return this.$store.getters['HistoryStore/getTabPath'](this.name).path + `?tab_name=${this.name}`;
      }
      return '';
    },

    shouldScrollToTopOnClick() {
      return this.scrollToTopIfSelected && this.selected;
    },

    brandColor() {
      if(this.lan.primary_color) return 'neutral-lightest';
      switch (this.$store.getters['BrandingStore/getBrand']) {
      case 'minim':
      case 'motosync':
        return this.selected ? 'primary' : 'neutral-darker';
      default:
        return 'neutral-lightest';
      }
    }
  },

  methods: {
    scrollToTop() {
      $('#app-body').animate({ scrollTop: 0 }, 800);
    },

    async onClick() {
      if (this.shouldScrollToTopOnClick) {
        this.scrollToTop();
      } else if (this.name === 'shop') {

        await this.$store.dispatch('UserStore/show', this.user.id);
        this.$native.exec('open_webview', {
          key: 'shop',
          startUrl: 'https://motorolanetwork.com',
          query: {
            'utm_source': `mobile_${this.user.persona}`
          }
        });
      } else {
        if (this.tabPath || this.homePath) {
          this.$router.push(this.tabPath || this.homePath);
        }
      }
    },
  },
};
</script>
