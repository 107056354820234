import store from 'mobile/vuex/index.js';
import { setI18nLanguage } from 'shared/lib/i18n.js';

/**
 * Utility to help persist the application's state between being paused/put to sleep and resuming.
 */
export default {
  async saveApplicationState() {
    const currentStoreState = JSON.stringify(store.state);

    await window.native.exec('set_preference', { key: 'store', value: currentStoreState });
  },

  async restoreApplicationState() {
    const savedStoreState = JSON.parse(await window.native.exec('fetch_preference', 'store'));

    // Try to restore the application state, but if we're missing any essential elements
    // required for the app to function bail and totally reload the app
    if (savedStoreState) {
      store.replaceState(savedStoreState);
      await setI18nLanguage();
    } else {
      await this.clearSavedState();
      window.app.reload();
    }
  },

  async clearSavedState() {
    await window.native.exec('remove_preference', 'store');
  }
};
