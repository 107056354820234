<template>
  <div>
    <header>
      <portal-target name="left_header" />
      <div class='right-header-container'>
        <portal-target name="right_header"/>
      </div>
    </header>

    <div id="app-body" class="authentication-body">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'authentication-layout',

  data() {
    return {
      appBody: null
    };
  },

  created() {
    this.appBody = document.getElementsByTagName('body')[0];
    this.appBody.classList.add('authentication-body');
  },

  beforeDestroy() {
    this.appBody.classList.remove('authentication-body');
  }
};
</script>
