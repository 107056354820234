<template>
  <minim-mobile-modal @close="close" :open="open">
    <div v-if="loading" class="p-48 text-center">
      <minim-mobile-spinner size="large" />
    </div>
    <template v-else>
      <h2 class="minim-mobile-text primary-font medium centered">{{ header }}</h2>
      <slot></slot>
      <div class="flex-container justify-center">
        <button class="minim-mobile-primary-button negative-color ghost small m-6" @click="confirm">
          {{ $I18n.t('yes') }}
        </button>
        <button class="minim-mobile-primary-button negative-color small m-6 shadow-large" @click="close">
          {{ $I18n.t('no') }}
        </button>
      </div>
    </template>  
  </minim-mobile-modal>
</template>

<script>

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },

    header: {
      type: String,
      default: ''
    },

    confirmAction: {
      type: Function,
    },

    errorMessage: {
      type: String,
      default: 'There was an error processing this request. Please try again.'
    }
  },

  data() {
    return {
      loading: false
    };
  },
  
  methods: {
    async confirm() {
      this.loading = true;

      setTimeout( async () => {
        try {
          this.loading = true;
          await this.confirmAction();
        } catch (err) {
          console.log(err);
          this.$toasted.global.minim_error(this.errorMessage);
        } finally {
          this.loading = false;
          this.close();
        }
      }, 400);
    },

    close(){
      this.$emit('close');
    },
  }
};
</script>
