<template>
  <skeleton-card v-if="loading && !hasData" />

  <div class="minim-mobile-card unpadded" v-else >
    <access-points-list-header class="p-32 p-b-0"/>
    <access-points-list :accessPoints="getAccessPoints" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SkeletonCard from './skeleton_card';
import AccessPointsList from './access_points_list';
import AccessPointsListHeader from './access_points_list_header';

export default {
  components: {
    'skeleton-card': SkeletonCard,
    'access-points-list': AccessPointsList,
    'access-points-list-header': AccessPointsListHeader,
  },

  props: {
    loadingDataInParent: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      loadingAccessPointsData: true
    };
  },

  async mounted() {
    await this.$store.dispatch('AccessPointStore/index');
    this.loadingAccessPointsData = false;
  },


  computed: {
    ...mapGetters('AccessPointStore', ['getAccessPoints']),

    loading() {
      return this.loadingDataInParent || this.loadingAccessPointsData;
    },

    hasData() {
      // If either of these return a truthy value, it means there's data in the access points store.
      return !!this.getAccessPoints.length;
    }
  }
};
</script>
