const routes = [
  {
    path: '/lans/:lanId/settings',
    component: () => import(/* webpackChunkName: "settings" */ './components/network/network_setting_list.vue'),
    name: 'network_path'
  },
  {
    path: '/settings/account',
    component: () => import(/* webpackChunkName: "settings" */ './components/account/account_settings_list.vue'),
    name: 'account_path',
    meta: {
      hideNetworkOfflineBanner: true
    }
  },
  {
    path: '/settings/subscriptions',
    component: () => import(/* webpackChunkName: "settings" */ './views/subscriptions/index.vue'),
    name: 'subscriptions_path',
    meta: {
      hideNetworkOfflineBanner: true
    }
  },
  {
    path: '/settings/subscriptions/:productId/landing',
    component: () => import(/* webpackChunkName: "settings" */ './views/subscriptions/landing.vue'),
    name: 'subscriptions_landing_path',
    meta: {
      hideBottomNavbar: true,
      topNavbarClass: 'inverted',
      hideNetworkOfflineBanner: true,
      statusBarStyle: {
        bgColorHex: '#000000',
        textMode: 'light'
      }
    }
  },
  {
    path: '/settings/subscriptions/:productId/subscribe',
    component: () => import(/* webpackChunkName: "settings" */ './views/subscriptions/subscribe.vue'),
    name: 'subscription_subscribe_path',
    meta: {
      hideNetworkOfflineBanner: true
    }
  },
  {
    path: '/settings/subscriptions/:subscriptionId/settings',
    component: () => import(/* webpackChunkName: "settings" */ './views/subscriptions/settings.vue'),
    name: 'subscription_settings_path',
    meta: {
      hideNetworkOfflineBanner: true
    }
  },
  {
    path: '/settings/privacy',
    component: () => import(/* webpackChunkName: "settings" */ './components/privacy/privacy_settings_list.vue'),
    name: 'privacy_path'
  },
  {
    path: '/settings/application_build_info',
    component: () => import(/* webpackChunkName: "settings" */ './components/app_info/application_build_info.vue'),
    name: 'application_build_info_path'
  },
  {
    path: '/settings/notifications',
    component: () => import(/* webpackChunkName: "settings" */ './components/notification/notifications.vue'),
    name: 'notification_settings_path'
  },
  {
    path: '/lans/:lanId/settings/users',
    component: () => import(/* webpackChunkName: "settings" */ './views/users.vue'),
    name: 'users_settings_path'
  },
  {
    path: 'lans/:lanId/settings/invite',
    component: () => import(/* webpackChunkName: "settings" */ './views/invite_user.vue'),
    name: 'invite_user_path',
  },
  {
    path: '/lans/:lanId/settings/forwarded_port/index',
    component: () => import(/* webpackChunkName: "settings" */ './views/forwarded_port/index.vue'),
    name: 'forwarded_ports_path'
  },
  {
    path: '/lan/:lanId/settings/forwarded_port/new',
    component: () => import(/* webpackChunkName: "settings" */ './views/forwarded_port/new.vue'),
    name: 'add_forwarded_port_path',
    props: true
  },
  {
    path: '/lan/:lanId/settings/forwarded_port/:id/edit',
    component: () => import(/* webpackChunkName: "settings" */ './views/forwarded_port/edit.vue'),
    name: 'edit_forwarded_port_path',
    props: true
  },
  {
    path: '/lan/:lanId/settings/forwarded_port/:id/settings',
    component: () => import(/* webpackChunkName: "settings" */ './views/forwarded_port/settings.vue'),
    name: 'forwarded_port_settings_path',
    props: true
  },
  {
    path: '/lan/:lanId/settings/led_dimmer',
    component: () => import(/* webpackChunkName: "settings" */ './views/led_dimmer.vue'),
    name: 'led_dimmer_path'
  },
  {
    path: '/lan/:lanId/settings/advanced_network_settings',
    component: () => import(/* webpackChunkName: "settings" */ './views/advanced_network_settings.vue'),
    name: 'advanced_network_settings_path'
  },
  {
    path: '/lan/:lanId/settings/advanced_network_settings/wifi_optimization',
    component: () => import(/* webpackChunkName: "settings" */ './views/wifi_optimization.vue'),
    name: 'wifi_optimization_path'
  },
  {
    path: '/lan/:lanId/settings/advanced_network_settings/wifi_optimization/channel_settings',
    component: () => import(/* webpackChunkName: "settings" */ './views/channel_settings.vue'),
    name: 'channel_settings_path'
  },
  {
    path: '/lan/:lanId/settings/local_network',
    component: () => import(/* webpackChunkName: "settings" */ './views/local_network.vue'),
    name: 'local_network_settings_path'
  },
  {
    path:'settings/video_walkthrough',
    component: () => import(/* webpackChunkName: "settings" */ './views/video_walkthrough.vue'),
    name: 'video_walkthrough_path',
    meta: { layout: 'video-walkthrough' }
  },
  {
    path: '/settings/admin',
    // Admin can go in it's own webpack chunk, don't need to load it for 99% of users
    component: () => import(/* webpackChunkName: "admin" */ './views/admin.vue'),
    name: 'admin_path'
  },
  {
    path: '/lans/:lanId/settings/forget_multiple_unum_selection/:unumIds?',
    component: () => import(/* webpackChunkName: "settings" */ './views/forget_multiple_unum_selection.vue'),
    name: 'forget_multiple_unum_selection_path',
    meta: { layout: 'onboarding' }
  },
  {
    path: '/lans/:lanId/settings/forget_multiple_unum_selection_confirmation/:unumIds',
    component: () => import(/* webpackChunkName: "settings" */ './views/forget_multiple_unum_selection_confirmation.vue'),
    name: 'forget_multiple_unum_selection_confirmation_path',
    meta: { layout: 'onboarding' }
  },
];

export default routes;
