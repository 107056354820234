<template>
  <div class="mobile-login-container bg-white">
    <div class="center p-b-40">
      <login-icon />
      <h2 class="header">
        {{ header }}
      </h2>
      <p class="minim-mobile-text secondary-font large" v-html="text">
      </p>
    </div>

    <div>
      <button @click="isRetailCustomer ? logOut() : returnHome()" class="minim-mobile-primary-button large m-b-24">
        {{ buttonText }}
      </button>
      <button v-if="isRetailCustomer" @click="returnHome()" class="minim-mobile-primary-button large negative-color ghost m-b-24">
        {{ $i18n.t('terms_of_service.data_consent.cancel_deactivation') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import LoginIcon from '../../components/shared/login_icon';

export default {
  components: {
    'login-icon': LoginIcon
  },

  async mounted(){
    await this.$store.dispatch('LanStore/index');
  },

  computed: {
    ...mapState('LanStore', ['lans']),

    isRetailCustomer(){
      return (!this.lans || this.lans.every((lan) => lan.features.includes('retail-isp')));
    },

    cancelDeadlineAt() {
      if (!this.$route.params.cancel_deadline_at) {
        return 'in <span class="data-deletion-deadline">7 days</span>';
      }
      const date = new Date(this.$route.params.cancel_deadline_at);
      return `on <span class="data-deletion-deadline">${date.toLocaleDateString(this.$i18n.locale, {year: 'numeric', month: 'long', day: 'numeric'})}</span>`;
    },

    header(){
      return this.isRetailCustomer ? this.$I18n.t('terms_of_service.privacy_compliance.account_deactivated_header') : this.$I18n.t('terms_of_service.privacy_compliance.deactivation_requested_header');
    },

    text(){
      return this.isRetailCustomer ? this.$I18n.t('terms_of_service.privacy_compliance.account_deactivated_text', {deadline: this.cancelDeadlineAt}) : this.$I18n.t('terms_of_service.privacy_compliance.deactivation_requested_text');
    },

    buttonText(){
      return this.isRetailCustomer ? this.$i18n.t('log_out') : this.$i18n.t('terms_of_service.data_consent.return_to_app');
    }
  },

  methods: {
    logOut() {
      return this.$store.dispatch('UserStore/logout').then(() => {
        window.app.reload();
      });
    },
    returnHome() {
      window.app.reload();
    }
  }
};
</script>
