<template>
  <div class="relative" :style="{ minHeight: `${minHeight}px` }">
    <div ref="graphContainer" :class="`graph-container ${graphContainerClasses}`">
      <div v-if="loadingInitialData" class="p-64 text-center">
        <minim-mobile-spinner size="large" />
      </div>

      <p v-else-if="hasNoSeriesData" class="minim-mobile-text primary-font medium centered text-neutral-darker-color no-graph-data-text">
        {{ $I18n.t('graphs.not_enough_data') }}
      </p>

      <highcharts
        v-else
        class="mobile-highcharts-component"
        ref="highcharts"
        :options="graphOptions"
      />

      <!-- Overlay the graph with a spinner when we've fetched the initial set of data and are now fetching more -->
      <div v-if="!loadingInitialData && loading" class="loading-overlay">
        <minim-mobile-spinner size="large" opacity="1" />
      </div>
    </div>
  </div>
</template>

<script>
import minim_api from 'mobile/shared/utils/minim_api';
import debounce from 'mobile/shared/utils/debounce';
import applyGraphFormatters from 'graph_formatters/index.js';

export default {
  props: {
    graphName: {
      type: String,
      required: true
    },

    timeScale: {
      type: String,
      required: false,
      default: 'last_hour'
    },

    queryOpts: {
      type: Object,
      required: true
    },

    // Whether or not the graph should poll for new data
    autoRefresh: {
      type: Boolean,
      reuired: false,
      default: false
    },

    // The rate at which to refresh the graph
    refreshInterval: {
      type: Number,
      required: false,
      default: (60 * 1000 * 2) // 2 minutes
    },

    isZoomed: {
      type: Boolean,
      required: true
    },

    graphContainerClasses: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      graphOptions: null,
      loading: true,
      interval: null,
      minHeight: 260,
      zoomHistory: [],
      currentStartPoint: null, // keeps track of the current zoom start position (the leftmost datapoint in the graph)
      currentEndPoint: null, // keeps track of the current zoom start position (the rightmost datapoint in the graph)
      chart: null
    };
  },

  async mounted() {
    await this.fetchGraphOptions();

    if (this.autoRefresh) {
      this.interval = setInterval(() => {

        // as long as we're not zoomed refresh the data
        if (!this.isZoomed) {
          this.loading = true;
          this.debouncedFetchGraphOptions();
        }
      }, this.refreshInterval);
    }
  },

  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },

  watch: {
    timeScale() {
      // Immediately start the loading animation, but still call the debounced version of the fetchGraphOptions button
      // so that if the user is spamming the time scale picker we aren't making a billion requests
      this.loading = true;
      this.debouncedFetchGraphOptions();
    }
  },

  computed: {
    loadingInitialData() {
      return this.loading && !this.graphOptions;
    },

    hasNoSeriesData() {
      return (
        // all these checks are sadly needed for safe access
        !this.graphOptions
        || !this.graphOptions.series
        || !this.graphOptions.series[0]
        || !this.graphOptions.series[0].data
      );
    }
  },

  methods: {
    async fetchGraphOptions() {
      const graphRequestData = {
        graph: this.graphName,
        time_scale: this.timeScale,
        query_opts: { ...this.queryOpts }
      };

      const rawGraphOptions = (await minim_api.get('graph/show.json', { params: graphRequestData })).data;

      if (rawGraphOptions) this.graphOptions = this.processGraphOptions(rawGraphOptions);

      this.$nextTick(() => {
        if (this.$refs.highcharts && this.$refs.highcharts.chart){
          this.chart = this.$refs.highcharts.chart;
          this.$emit('chart', this.chart);
        }
        if (this.$refs.graphContainer) this.minHeight = this.$refs.graphContainer.clientHeight;
        this.finishLoading();
      });
    },

    debouncedFetchGraphOptions: debounce(async function() { await this.fetchGraphOptions(); }, 500),

    processGraphOptions(rawGraphOptions) {
      let processedGraphOptions = rawGraphOptions;

      // Apply any graph formatter functions passed up from the graph models
      applyGraphFormatters(processedGraphOptions);

      // Set custom mobile styling config opts
      processedGraphOptions.customData.is_mobile = true;
      processedGraphOptions.yAxis.title = null;

      // If there is an xAxis set a custom event handler to be fired when the user zooms
      // the event handler will record zoom history and allow users to undo zoom events
      if (processedGraphOptions.xAxis) {
        processedGraphOptions.xAxis.events = { setExtremes: this.handleZoom.bind(this) };
      }

      return processedGraphOptions;
    },

    handleZoom(event) {
      const startPoint = event.min;
      const endPoint = event.max;

      // if we've actually zoomed, AKA the start and end point are different
      // from the current start and end point, start recording history
      // (start and end point will be the same when we undo a zoom and we dont want to record history in that case)
      if (startPoint !== this.currentStartPoint && endPoint !== this.currentEndPoint) {
        if (this.currentStartPoint && this.currentEndPoint) {
          // Only push to the zoom history array if we already have a start and end point. This means that we've already zoomed
          // at least once and now we're zooming again so we need to store the previous zooms
          this.zoomHistory.push({ startPoint, endPoint });
        }

        // Set the current start and end point, and let thee parent component kmow that we've zoomed
        this.currentStartPoint = startPoint;
        this.currentEndPoint = endPoint;
        this.$emit('zoomChange', true);
      } else {
        if (this.zoomHistory.length) this.zoomHistory.pop();
      }
    },

    undoZoom() {
      if (this.zoomHistory.length) {
        // If there is a previous zoom, take it and apply it as the current zoom
        const lastZoom = this.zoomHistory[this.zoomHistory.length - 1];
        this.currentStartPoint = lastZoom.startPoint;
        this.currentEndPoint = lastZoom.endPoint;
        this.chart.xAxis[0].setExtremes(lastZoom.startPoint, lastZoom.endPoint);
      } else {
        // Else just reset the graph back to normal since this is now the equivalent of "undoing" the zoom
        this.resetZoom();
      }
    },

    resetZoom() {
      this.currentStartPoint = null;
      this.currentEndPoint = null;
      this.chart.xAxis[0].setExtremes(null, null);
      this.$emit('zoomChange', false);
    },

    finishLoading() {
      this.loading = false;
      this.$emit('finishedLoading');
    }
  }
};
</script>
