<template>
  <div 
    class="absolute flex-col text-center h-full z-index-1 border-rounded-20 transition-opacity transition-delay-1 shadow-large overflow-hidden w-full p-32"
    style="height: 238px"
    :class="[heightClass, bgColorClass]" 
  >
    <slot>
      <div v-if="maxedOut">
        <p class="minim-mobile-text primary-font medium text-neutral-lightest-color light">
          {{ $I18n.t('mobile.speed_tests.banner.maxed_out.fast') }}
        </p>
        <p class="minim-mobile-text secondary-font large text-neutral-lightest-color m-t-16 light">
          {{ $I18n.t('mobile.speed_tests.banner.maxed_out.maxed_out') }}
        </p>
        <div class="flex justify-center m-t-24">
          <button 
            class="minim-mobile-primary-button neutral-lightest-color positive-text"
            @click="$emit('hide')"
          >
            {{ $I18n.t('ok') }}
          </button>
        </div>
      </div>
      <div v-else>
        <p class="minim-mobile-text primary-font medium text-neutral-lightest-color light">
          {{ $I18n.t('mobile.speed_tests.banner.reboot.slower') }}
        </p>
        <p class="minim-mobile-text secondary-font large text-neutral-lightest-color m-t-16 light">
          {{ $I18n.t('mobile.speed_tests.banner.reboot.prompt') }}
        </p>
        <div class="flex justify-center m-t-24">
          <button 
            class="minim-mobile-primary-button ghost light small m-0"
            @click="$emit('hide')"
          >
            {{ $I18n.t('no_thanks') }}
          </button>
          <button 
            class="minim-mobile-primary-button neutral-lightest-color small m-0 m-l-8 negative-text"
            @click="$emit('reboot')"
          >
            {{ $I18n.t('reboot') }}
          </button>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },

    maxedOut: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    heightClass() {
      return this.isVisible ? '' : 'opacity-0';
    },

    bgColorClass() {
      return this.maxedOut ? 'bg-positive' : 'bg-negative';
    }
  },

  methods: {
    reboot() {

    },
  }
};
</script>
