<template>
  <section class="mobile-speed-tests-legend">
    <legend-item
      :showSkeleton="showSkeleton"
      :speed="roundKbpsToMbps(speedTest.download_speed_kbps) || '-'"
      :units="determineKbpsOrMbps(speedTest.download_speed_kbps)"
      :maxedOut="speedTest.download_speed_maxed_out"
      iconName="download-arrow"
      iconColor="download"
      :speedTestIsRunning="speedTestIsRunning"
    />
    <legend-item
      :showSkeleton="showSkeleton"
      :speed="roundKbpsToMbps(speedTest.upload_speed_kbps) || '-'"
      :units="determineKbpsOrMbps(speedTest.upload_speed_kbps)"
      :maxedOut="speedTest.upload_speed_maxed_out"
      iconName="upload-arrow"
      iconColor="upload"
      :speedTestIsRunning="speedTestIsRunning"
    />
    <legend-item
      :showSkeleton="showSkeleton"
      :speed="latency"
      :units="$I18n.t('units.milliseconds')"
      :maxedOut="false"
      iconName="latency-arrow"
      iconColor="latency"
      :speedTestIsRunning="speedTestIsRunning"
    />
  </section>
</template>

<script>
import LegendItem from './legend_item';
import SpeedTestsMixin from 'mobile/shared/mixins/speed_tests_mixin';

export default {
  mixins: [SpeedTestsMixin],

  components: {
    'legend-item': LegendItem
  },

  props: {
    showSkeleton: {
      type: Boolean
    },

    speedTest: {
      type: Object
    },

    speedTestIsRunning: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    latency() {
      if (this.speedTest.latency_ms) return Math.round(this.speedTest.latency_ms);

      return '-';
    }
  }
};
</script>
