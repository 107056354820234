<template>
  <minim-mobile-modal :open="open" @close="close">
    <div class="flex-container column align-center">
      <p class="minim-mobile-text primary-font centered medium">
        {{ $I18n.t('mobile.access_points.card.max_aps_modal.header') }}
      </p>
      <div>
        <p class="minim-mobile-text secondary-font large centered p-t-32 p-b-32">
          {{ $I18n.t('mobile.access_points.card.max_aps_modal.body') }}
        </p>
      </div>
      <button class="minim-mobile-primary-button" @click="close">
        {{ $I18n.t('ok') }}
      </button>
    </div>
  </minim-mobile-modal>
</template>

<script>

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
