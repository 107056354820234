<template>
  <div>
    <!-- Usage of v-show rather than v-if here is to keep the <portal-target /> in the top-navbar component
    always mounted in the DOM so any <portal /> elements accessing it will always have a place to go -->
    <transition name="slide" v-if="inAppBrandingLogo">
      <logo-header v-show="isShowLogo && scrolledToTop" />
    </transition>
    <top-navbar :showShadow="!scrolledToTop" />

    <div id="app-body" class="app-body" :key="`app-body-${applicationKey}`">
      <div class="main-content">
        <network-offline-banner />
        <router-view :key="$route.fullPath" />
      </div>
    </div>
    <bottom-navbar />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LogoHeader from '../headers/logo_header';
import TopNavbar from 'mobile/shared/components/navigation/top_navbar';
import BottomNavbar from 'mobile/shared/components/navigation/bottom_navbar';
import NetworkOfflineBanner from '../banners/network_offline_banner.vue';

export default {
  name: 'main-layout',
  components: {
    'logo-header': LogoHeader,
    'top-navbar': TopNavbar,
    'bottom-navbar': BottomNavbar,
    'network-offline-banner': NetworkOfflineBanner
  },
  data() {
    return {
      appBodyElement: null,
      scrolledToTop: true
    };
  },

  updated() {
    this.appBodyElement = document.getElementById('app-body');
    if(this.appBodyElement) {
      this.appBodyElement.addEventListener('scroll', this.handleScroll);
    }
  },

  destroyed() {
    if(this.appBodyElement) {
      this.appBodyElement.removeEventListener('scroll', this.handleScroll);
    }
  },

  computed: {
    ...mapGetters({lan: 'LanStore/currentLan'}),
    ...mapState({ applicationKey: state => state.applicationKey }),

    showLogoHeader() {
      if (this.$route.meta && this.$route.meta.showLogoHeader) {
        return true;
      } else {
        return false;
      }
    },

    isShowLogo() {
      return this.$route.name == 'lan_path';
    },

    hideTopNav() {
      return this.$route.meta && this.$route.meta.hide_top_nav;
    },

    inAppBrandingLogo() {
      return this.lan.mobile_app_logo;
    },
  },

  methods: {
    // MUST use standard lambda syntax here rather than arrow function syntax
    // to maintain the correct "this"
    handleScroll() {
      if (this.appBodyElement.scrollTop <= 0) {
        this.scrolledToTop = true;
      } else {
        this.scrolledToTop = false;
      }
    }
  }
};
</script>