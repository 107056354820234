<template>
  <div class="ad-card-container skeleton-overlay-darker" :style="backgroundObject" v-skeleton="showSkeleton">
    <div>
      <h2 class="ad-card-text center p-b-24">
        {{headerText}}
      </h2>
      <button
        @click="openShopPage"
        class="minim-mobile-primary-button medium ad-card-button"
      >
        {{buttonText}}
      </button>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';

export default {
  props: {
    headerText: {
      type: String
    },
    buttonText: {
      type: String
    },
    imgSrc: {
      type: String
    },
    showSkeleton: {
      type: Boolean
    }
  },

  data(){
    return {
      backgroundObject: {
        backgroundImage: `url(${this.imgSrc})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    };
  },

  computed: {
    ...mapState('UserStore', ['user']),
  },

  methods: {
    openShopPage(){
      this.$native.exec('open_webview', {
        key: 'ad_card',
        startUrl: 'https://motorolanetwork.com/shopmotosync',
        query: {
          'moto_promo': 'unmanaged_upgrade',
          'utm_source': `mobile_${this.user.persona}`
        }
      });
    },
  }
};
</script>

<style scoped>

.ad-card-container {
  width: 96%;
  height: 359px;
  padding: 32px;
  margin: 8px auto;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  position: relative;
}
.ad-card-text {
  color: #FFFFFF;
  font-family: 'Pulp Display' !important;
}
.ad-card-button {
  background: #FF554D;
  border-radius: 24px;
  color: #FFFFFF;
  font-weight: 400 !important
}
</style>
