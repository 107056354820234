import Logger from './logger';
import store from 'mobile/vuex/index';

function setprimaryUnumHardwareKind(params) {
  const primaryUnum = store.getters['UnumStore/primaryUnum'];
  const primaryUnumHwKind = (primaryUnum || {}).hardware_kind_id;
  return {...params, hardware_kind_id: primaryUnumHwKind || null};
}

export default {
  qa_mode: false,
  amplitudeIdentity: null,
  timers: {},

  init(platform) {
    const queryString = window.location.search;
    if(queryString.includes('qa_mode=true')) {
      this.qa_mode = true;
    }
    window.amplitude.getInstance().init(window.AMPLITUDE_PUBLIC_API_KEY);
    this.logEvent('opened_app');
    this.amplitudeIdentity = new window.amplitude.Identify();
    this.amplitudeIdentity.set('platform', platform);
  },

  setUserData(user) {
    window.amplitude.setUserId(user.id);
    this.amplitudeIdentity.set('email', user.email);
    window.amplitude.getInstance().identify(this.amplitudeIdentity);
  },

  logEvent(eventName, params = {}) {
    if (this.qa_mode) {
      return;
    }

    params = setprimaryUnumHardwareKind(params);
    Logger.debug(`Sending event: "${eventName}" to amplitude with params:`, params);

    window.amplitude.getInstance().logEvent(eventName, params);
  },

  startTimer(timerName) {
    if (!timerName) {
      Logger.error('Attempted to start a timer without passing a timerName');
      return;
    }

    if (this.timers[timerName]) {
      Logger.warn(`Overwriting existing timer with name: ${timerName}`);
    }

    this.timers[timerName] = new Date();
  },

  popTimer(timerName) {
    if (!timerName) {
      Logger.error('Attempted to stop a timer without passing a timerName');
      return 0;
    }

    if (!this.timers[timerName]) {
      Logger.error(`Attempted to stop a timer with a name of ${timerName} that did not exist!`);
      return 0;
    }

    const elapsedTime = (new Date - this.timers[timerName]) / 1000;
    delete this.timers[timerName];

    return elapsedTime;
  }
};
