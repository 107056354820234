<template>
  <minim-mobile-text-field
    @input="handleInput"
    :label="label"
    :value="value"
    ref="inputField"
    :disabled="disabled || editingDisabled"
    :errors="errors"
    :mode="mode"
    :placeholder="placeholder"
    :type="inputType"
    :testId="testId"
    :toolTipText="toolTipText"
  >
    <span v-if="showLockIcon" slot="leftMostIcon">
      <minim-mobile-icon
        iconName="lock-16"
        color="neutral-dark"
        size="x-small"
      />
    </span>
    <span v-if="!hideIcon" @click="togglePasswordVisibility" slot="rightMostIcon">
      <minim-mobile-icon
        :iconName="iconName"
        :color="iconColor"
        size="small"
      />
    </span>
  </minim-mobile-text-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false
    },

    value: {
      type: String,
      required: false,
      default: ''
    },

    mode: {
      type: String,
      required: false,
      validator(value) {
        return value ? ['disabled', 'highlighted'].includes(value) : true;
      }
    },

    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    hideIcon: {
      type: Boolean,
      default: false,
      required: false
    },

    showLockIcon: {
      type: Boolean,
      default: true,
      required: false
    },

    editingDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    errors: {
      type: Array,
    },

    testId: {
      type: String,
      default: ''
    },

    toolTipText: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      showPassword: false
    };
  },

  methods: {
    handleInput($event) {
      if (this.editingDisabled) return;
      this.$emit('input', $event);
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
      this.$emit('passwordVisibilityToggled', this.showPassword);
    },

    // Used via refs in parent components to remove focus from the input programmatically
    blurInput() {
      this.$nextTick(() => {
        // inputField is a minim-mobile-text-field, so the method is called blurInput, not blur
        this.$refs.inputField.blurInput();
      });
    }
  },

  computed: {
    iconName() {
      return this.showPassword ? 'hide' : 'view';
    },

    disabled() {
      return this.mode === 'disabled';
    },

    highlighted() {
      return this.mode === 'highlighted';
    },

    iconColor() {
      switch (this.mode) {
      case 'highlighted':
        return 'neutral-lightest';
      case 'disabled':
        return 'neutral-darker';
      default:
        return 'neutral-darkest';
      }
    },

    inputType() {
      return this.showPassword ? 'text' : 'password';
    }
  }
};
</script>
