const BRAND_TAG_ID = 'inAppBrandingStyles';

function toHexSubstr(c) {
  c = Math.round(c);

  if (c < 0) c = 0;
  if (c > 255) c = 255;

  let s = c.toString(16);
  if (s.length < 2) s = '0' + s;

  return s;
}

/**
 * Takes in a 6 digit RGB hex and generates a lighter or darker version of it based off some given amount.
 * The amount should be between -1 to 1. Passing a negative value will make the color darker and passing a positive value will
 * make it lighter. The amount can be thought of as a percentage between the current color and the max darkness/brightness (0/255)
 */
function adjustColorBrightness(color, amount) {
  let r = parseInt(color.substr(1, 2), 16);
  let g = parseInt(color.substr(3, 2), 16);
  let b = parseInt(color.substr(5, 2), 16);

  if (amount < 0) {
    r = (1 + amount) * r;
    g = (1 + amount) * g;
    b = (1 + amount) * b;
  } else {
    r = (1 - amount) * r + amount * 255;
    g = (1 - amount) * g + amount * 255;
    b = (1 - amount) * b + amount * 255;
  }

  return '#' + toHexSubstr(r) + toHexSubstr(g) + toHexSubstr(b);
}

function generateInnerHTML(primaryColor) {
  const primaryColorDark = adjustColorBrightness(primaryColor, -0.15);
  const skeletonColor = adjustColorBrightness(primaryColor, 0.25);

  return `
    .text-primary-color { color: ${primaryColor} !important; }
    .text-primary-dark-color { color: ${primaryColorDark} !important; }

    .bg-primary-color { background: ${primaryColor} !important; }
    .bg-primary-dark-color { background: ${primaryColorDark} !important; }

    .skeleton.skeleton-bg-primary,
    .skeleton-overlay.skeleton-bg-primary::before {
      background: ${skeletonColor} !important;
    }

    .primary-ghost-button {
      backround: transparent !important;
      border: solid 1px ${primaryColor} !important;
      color: ${primaryColorDark} !important;
    }

    .mobile-timeline-filter-toggle.primary { border-color: ${primaryColor} !important; }
    .mobile-timeline-filter-toggle.primary.selected { background: ${primaryColor} !important; }

    .unum-card-header-section.normal-state { background: ${primaryColor} !important; }
  `;
}

function createBrandingTag(primaryColor) {
  const brandingTag = document.createElement('style');

  brandingTag.type = 'text/css';
  brandingTag.setAttribute('id', BRAND_TAG_ID);

  brandingTag.innerHTML = generateInnerHTML(primaryColor);

  document.getElementsByTagName('head')[0].appendChild(brandingTag);
}

function updateBrandingTag(primaryColor, brandingTag) {
  brandingTag.innerHTML = generateInnerHTML(primaryColor);
}

function removeBrandingTag(brandingTag) {
  brandingTag.parentNode.removeChild(brandingTag);
}

const SIX_DIGIT_RGB_HEX_REGEX = /^#[0-9a-f]{6}$/i;

export function applyBranding(primaryColor) {
  const brandingTag = document.getElementById(BRAND_TAG_ID);
  const hasValidPrimaryColor = primaryColor && primaryColor.match(SIX_DIGIT_RGB_HEX_REGEX); // need to validate the color so we dont get janky CSS

  if (hasValidPrimaryColor && !brandingTag) {
    createBrandingTag(primaryColor);
  } else if (hasValidPrimaryColor && brandingTag) {
    updateBrandingTag(primaryColor, brandingTag);
  } else if (!hasValidPrimaryColor && brandingTag) {
    removeBrandingTag(brandingTag);
  }
}
