<template>
  <ul class="supported-usage-types">
    <li
      v-skeleton="{ showSkeleton, classes: ['rounded-infinite', 'skeleton-bg-positive']}"
      v-for="({ associatedIconName }, typeName) in usageTypes"
      :key="typeName"
    >
      <usage-type-icon
        :typeName="typeName"
        :typeIcon="associatedIconName"
        :speedTest="speedTest"
        :speedTestIsRunning="speedTestIsRunning"
      />
    </li>
  </ul>
</template>

<script>
import UsageTypeIcon from './usage_type_icon';
import { mapGetters } from 'vuex';

export default {
  components: {
    'usage-type-icon': UsageTypeIcon
  },

  props: {
    showSkeleton: {
      type: Boolean,
      required: true
    },

    speedTest: {
      type: Object,
      required: true
    },

    speedTestIsRunning: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('SpeedTestStore', ['usageTypes'])
  }
};
</script>
