import command_statuses from './command_statuses';
import { serializePacket, deserializeAndValidatePacket } from './packet';

export default class Executor {
  /**
   * This class is meant to be used to execute functions/commands posted by a remote Commander class
   * within the local window's context
   *
   * @param {String} namespace - the namespace to send packets within
   * @param {Window} remote_window - the remote window the instance should interact with
   * @param {Object} functions - Set of functions that can be called upon from the remote window to be executed in the local window
   */
  constructor(namespace, remote_window, functions) {
    this.namespace = namespace;
    this.remote_window = remote_window;
    this.functions = functions;

    window.addEventListener('message', this._onCommandPosted.bind(this), { capture: false, passive: true });
  }

  /**
   * Event handler to handler incoming commands from a remote Commander instance
   *
   * @param {MessageEvent} event - the event object from postMessage
   */
  _onCommandPosted(event) {
    const { packet, is_valid } = deserializeAndValidatePacket(event.data, this.namespace);

    if (!is_valid) return;

    const { id, param, command_name } = packet;
    const command_exists = !!this.functions[command_name];

    if (command_exists) {
      this._runCommand(id, param, command_name);
    } else {
      this._sendCommandResponse(command_statuses.REJECT, id, `Remote command "${command_name}" not found...`);
    }
  }

  /**
   * Runs a command that was specified by a remote Commander instance in the local window
   *
   * @param {String} id - unique ID of the command being executed
   * @param {Any} param - a single param to pass to the command
   * @param {String} command_name - the name of the command being executed
   */
  async _runCommand(id, param, command_name) {
    const command = this.functions[command_name];

    let status = command_statuses.RESOLVE;
    let ret = command(param);

    const is_promise = !!ret && !!ret.then;

    if (is_promise) {
      try {
        ret = await ret;
      } catch (error) {
        ret = error;
        status = command_statuses.REJECT;
      }
    }

    this._sendCommandResponse(status, id, ret);
  }

  /**
   * Sends a command's response back to the remote Commander instance that requested the command.
   *
   * @param {String} status - whether or not the command succeeded - should be either 'reject' or 'resolve'
   * @param {String} id - unique ID of the executed command
   * @param {Any} data - return value of the executed command
   */
  _sendCommandResponse(status, id, data) {
    const packet = serializePacket({
      namespace: this.namespace,
      status,
      id,
      data
    });

    this.remote_window.postMessage(packet, '*');
  }
}
