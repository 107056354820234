<template>
  <div
    @click="onClick"
    class="unum-circle"
    :class="size"
  >
    <div class="circle-container" style="margin: auto">
      <div
        class="circle"
        :class="{'unset-shadow': (showSkeleton || hideBoxShadow), 'medium-shadow': (size == 'small')}"
      >
        <div v-if="showSkeleton" class="skeleton rounded-infinite w-full h-full overflow-hidden" />

        <!-- Need to use v-show here, as opposed to v-else because v-show
            allows the image to still load whereas v-if does not -->
        <img
          v-show="!showSkeleton && imageLoaded"
          @load="imageLoaded = true"
          :src="hardwareKind.hardware_image"
          class="unum-img"
        >
      </div>
      <minim-mobile-icon
        v-if="icon && !showSkeleton && !unum.is_unmanaged"
        :iconName="icon"
        color="neutral-lightest"
        size="x-small"
        class="info-icon unum-status-icon"
        :class="iconClasses"
      />
    </div>

    <p
      v-skeleton="showSkeleton"
      class="unum-name"
      :style="getUnumNameStyle"
    >
      {{ unum.name }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UnumStatusMixin from 'mobile/shared/mixins/unum_status_mixin';

export default {
  mixins: [UnumStatusMixin],

  props: {
    unum: {
      type: Object,
      required: true
    },

    size: {
      type: String,
      default: 'large'
    },

    isLinkToShowPage: {
      type: Boolean,
      default: false
    },

    hideBoxShadow: {
      type: Boolean,
      default: false
    },

    unumNameStyle: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      imageLoaded: false
    };
  },

  mounted() {
    this.fetchHwKind();
  },

  computed: {
    ...mapGetters('LanStore',['currentLan']),
    ...mapGetters('HardwareKindStore',['getHardwareKind']),

    hardwareKind() {
      return this.getHardwareKind(this.unum.hardware_kind_id) || {};
    },

    hardwareKindIsPresent() {
      return Object.keys(this.hardwareKind || {}).length > 0;
    },

    showSkeleton() {
      return !Object.keys(this.unum || {}).length;
    },

    getUnumNameStyle() {
      return this.unumNameStyle + 'overflow: hidden;text-overflow: ellipsis;max-width: 80% !important;';
    },

    iconClasses() {
      switch(this.unumStatus(this.unum)) {
      case 'rebooting':
        return 'rebooting-animation bg-negative-light-color';
      case 'updating':
        return 'bg-positive-light-color';
      case 'offline':
        return 'bg-negative-light-color';
      default:
        return '';
      }
    },

    icon() {
      switch(this.unumStatus(this.unum)) {
      case 'rebooting':
        return 'reboot-badge';
      case 'offline':
        return 'alert';
      case 'updating':
        return 'update-badge';
      default:
        return null;
      }
    }
  },

  methods: {
    onClick() {
      if (this.showSkeleton) return;

      if (this.isLinkToShowPage) {
        this.$router.push({
          name: 'lan_unum_show_path',
          params: {
            lanId: this.currentLan.id,
            id: this.unum.id
          }
        });
      }
    },

    async fetchHwKind() {
      if (this.unum.hardware_kind_id) {
        await this.$store.dispatch('HardwareKindStore/show', this.unum.hardware_kind_id);
      }
    }
  },

  watch: {
    unum() {
      this.fetchHwKind();
    }
  }
};
</script>
