import Vue from 'vue/dist/vue.esm';
import Zendesk from '@dansmaculotte/vue-zendesk';

import store from 'mobile/vuex/index.js';
import minim_api from 'mobile/shared/utils/minim_api';

export function initializeZendesk() {
  let departments;

  if (store.state.BrandingStore.brand === 'motosync') {
    departments = {
      enabled: ['Motorola Chat Support'],
      select: 'Motorola Chat Support'
    };
  } else {
    departments = {
      enabled: ['Tier 2 - ISP/Operator Support'],
      select: 'Tier 2 - ISP/Operator Support'
    };
  }

  Vue.use(Zendesk, {
    key: '7268e72b-48ab-441c-b0dd-038b2e27877e',
    hideOnLoad: true,
    settings: {
      webWidget: {
        color: {
          theme: '#006373'
        },
        chat: {
          suppress: false,
          departments
        },
        contactForm: {
          suppress: false
        },
        helpCenter: {
          suppress: true
        },
        talk: {
          suppress: true
        },
        answerBot: {
          suppress: true
        },
        navigation: {
          popoutButton: {
            enabled: false
          }
        },
        authenticate: {
          chat: {
            async jwtFn(callback) {
              try {
                const userId = store.state.UserStore.user.id;
                const jwt = (await minim_api.get(`api/v1/users/${userId}/zendesk_jwt`)).data.token;
                callback(jwt);
              } catch (err) {
                console.error(err);
              }
            }
          }
        }
      }
    }
  });
}
