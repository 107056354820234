export function serializePacket(packet) {
  return JSON.stringify(packet);
}

export function deserializeAndValidatePacket(packet, expected_namespace) {
  let parsed_packet;

  try {
    parsed_packet = JSON.parse(packet);
  } catch (err) {
    if(window.on_device) console.log(err);
    return { packet: null, is_valid: false };
  }

  return {
    packet: parsed_packet,
    is_valid: (parsed_packet.namespace === expected_namespace)
  };
}
