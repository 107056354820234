import I18n from 'shared/lib/i18n.js';
import store from '../../vuex';

export default {
  methods: {
    hardwareType(hardwareKindId, isGateway, isUnmanaged = false) {
      if (isGateway) {
        return this.isModem(hardwareKindId) ? I18n.t('mobile.hardware_types.modem_plus_router') : I18n.t('mobile.hardware_types.router');
      }

      if (this.isModem(hardwareKindId)) {
        return I18n.t('mobile.hardware_types.modem');
      }

      if (isUnmanaged) {
        return this.$I18n.t('mobile.hardware_types.unmanaged');
      }else {
        return hardwareKindId === 'zoom_7020' ? I18n.t('mobile.hardware_types.satellite') : I18n.t('mobile.hardware_types.extender');
      }
    },

    isModem(hardwareKindId) {
      return (store.getters['HardwareKindStore/getHardwareKind'](hardwareKindId) || {}).is_modem;
    },

    extenderTerm(hardwareKindId) {
      return hardwareKindId === 'zoom_7020' ? I18n.t('mobile.hardware_types.satellite') : I18n.t('mobile.hardware_types.extender');
    }
  }
};
