<template>
  <div v-skeleton="{
    showSkeleton,
    classes: ['rounded-extra', 'skeleton-bg-primary'],
    styles: { width: '214px', 'margin-left': 'auto', 'margin-right': 'auto' }
  }">
    <button class="minim-mobile-primary-button" v-if="disabled" :disabled="true">
      {{ disabledText }}
    </button>

    <div v-else-if="running || progress !== 0" class="mobile-animated-primary-button">
      <div class="progress-background">
        <p  class="text-primary-color">{{ runningText }}</p>
      </div>
      <div class="progress-overlay bg-primary-color" :style="`width: ${this.progress}%`">
        <p :style="brand === 'motosync' ? 'margin-left: 28px': ''">{{ runningText }}</p>
      </div>
    </div>

    <button
      v-else
      class="minim-mobile-primary-button bg-primary-color"
      :data-test-id="testId"
      @click="handleClick"
      :centered="true"
    >
      {{ readyText }}
    </button>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    showSkeleton: {
      type: Boolean,
      required: true
    },

    running: {
      type: Boolean,
      required: true
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    waitPeriodMs: {
      type: Number,
      required: false,
      default: 400
    },

    runningText: {
      type: String,
      required: true
    },

    disabledText: {
      type: String,
      required: false
    },

    readyText: {
      type: String,
      required: true
    },

    testId: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      progress: 0,
      interval: null
    };
  },

  mounted() {
    if (this.running) {
      this.progress = 25;
      this.runAnimation();
    }
  },

  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  computed: {
    ...mapState('BrandingStore', ['brand'])
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },

    runAnimation() {
      this.interval = setInterval(() => {
        if (!this.running) {
          clearInterval(this.interval);
          this.quicklyFinishAnimation();
        } else if (this.progress < 80) {
          this.progress += 1;
        }
      }, this.waitPeriodMs);
    },

    quicklyFinishAnimation() {
      this.interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 1;
        } else {
          clearInterval(this.interval);
          this.progress = 0;
        }
      }, 10);
    }
  },

  watch: {
    running() {
      if (this.running) this.runAnimation();
    }
  }
};
</script>
