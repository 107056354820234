<template>
  <div class="mobile-login-container bg-white">
    <div>
      <div class="center p-b-40">
        <login-icon />
        <h2 class="header">
          {{ $I18n.t('terms_of_service.privacy_compliance.header') }}
        </h2>
        <p class="minim-mobile-text secondary-font large">
          {{  $I18n.t('terms_of_service.privacy_compliance.subheader') }}
        </p>
      </div>
    </div>

    <div class="center bottom submit-buttons">

      <button @click="accept"
        class="minim-mobile-primary-button large m-b-24"
      >
        {{ $I18n.t('terms_of_service.privacy_compliance.accept') }}
      </button>

      <button @click="$router.push({
        name: 'doesnt_meet_age_tos_path'})"
        class="minim-mobile-primary-button negative-color large m-b-24"
      >
        {{ $I18n.t('terms_of_service.privacy_compliance.do_not_accept') }}
      </button>

      <button
          class="minim-mobile-primary-button shadow-none primary-font small light text-neutral-darker-color m-t-2 flex-container align-center"
          style="width: 279px; background: none"
          @click="logOut"
      >
        {{ $I18n.t('log_out') }}
        <minim-mobile-icon
            iconName="actionable"
            color="neutral-dark"
            size="x-small"
            class="m-l-16"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GetProperPathMixin from 'mobile/shared/mixins/root_path_mixin';

import LoginIcon from '../../components/shared/login_icon';

export default {
  components: {
    'login-icon': LoginIcon
  },

  mixins: [GetProperPathMixin],

  computed: {
    ...mapGetters('ServiceTermsStore', ['getByKind']),

    ageTerm(){
      return this.getByKind('age');
    }
  },

  methods: {
    async accept(){
      try {
        await this.$store.dispatch('ServiceTermsStore/accept', this.ageTerm);
        this.getProperPath();
      } catch(e){
        console.log(e);
        this.$toasted.global.minim_error(this.$I18n.t('terms_of_service.network_error'));
        return;
      }
    },

    logOut() {
      return this.$store.dispatch('UserStore/logout').then(() => {
        window.app.reload();
      });
    }
  }
};
</script>
