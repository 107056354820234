import { render, staticRenderFns } from "./tos.vue?vue&type=template&id=50027844&"
import script from "./tos.vue?vue&type=script&lang=js&"
export * from "./tos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports