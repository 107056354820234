export default {
  methods: {
    unumStatus(unum) {
      if (unum.is_unmanaged) {
        return 'unmanaged';
      } else if (unum.is_rebooting) {
        return 'rebooting';
      } else if (unum.is_updating) {
        return 'updating';
      } else if (unum.is_online) {
        return 'online';
      } else {
        return 'offline';
      }
    },

    generateUnumStatusText(collection, status, deviceTerm = null) {
      if (!deviceTerm) deviceTerm = this.$I18n.t('mobile.components.unum_card.access_points');

      if (collection.length > 1) {
        return this.$I18n.t('mobile.components.unum_card.plural_status_text', {
          device_count: collection.length,
          device_term: deviceTerm,
          status
        });
      } else {
        return this.$I18n.t('mobile.components.unum_card.singular_status_text', {
          device_name: collection[0].name,
          status
        });
      }
    }
  }
};
