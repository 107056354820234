/**
 * Returns a function that will not be triggered as it continues to be called.
 * The function will only be called after it has stopped being called for a given wait period in milliseconds.
 *
 * @param {*} func - The function to be called
 * @param {*} delayMs - The delay in milleseconds to wait before finally triggering the function
 */
export default function debounce(func, delayMs = 100) {
  let timeout;

  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => { func.apply(this, args); }, delayMs);
  };
}