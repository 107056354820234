<template>
  <div v-if="isIdentifying" class="banner-container flex-container justify-between align-center">
    <div class="flex-container column">
      <p class="minim-mobile-text primary-font small text-neutral-lightest-color">
        {{ $I18n.t('mobile.components.identifying_devices_banner.title') }}
      </p>
      <p class="minim-mobile-text secondary-font small text-neutral-lightest-color">
        {{ $I18n.t('mobile.components.identifying_devices_banner.body') }}
      </p>
    </div>
    <minim-mobile-icon
      iconName="search-64"
      size="medium"
      color="neutral-lightest"
      class="fade-icon"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

const EIGHTEEN_MINUTES = 1080000;

export default {
  props: {
    lan: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      timeout: null,
      isIdentifying: false
    };
  },

  async mounted() {
    // Calculate if the LAN was created in the last eighteen minutes.
    // If it was show the banner until the LAN is at least eighteen minutes old.
    const createdAt = new Date(this.lan.created_at).getTime();
    const timeRemaining = EIGHTEEN_MINUTES - (new Date().getTime() - createdAt);

    if (timeRemaining > 0) {
      this.isIdentifying = true;

      // Add two seconds onto the time remaining so the banner never
      // shows for a fraction of a second and dissapears
      const bufferedTimeRemaining = timeRemaining + 2000;

      setTimeout(() => {
        this.isIdentifying = false;
      }, bufferedTimeRemaining);
    }
  },

  computed: {
    ...mapState('BrandingStore', ['brand'])
  }
};
</script>
