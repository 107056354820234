import I18n from 'shared/lib/i18n';
import store from 'mobile/vuex/index.js';
import moment from 'moment';
import analytics from 'mobile/shared/utils/analytics';
import $ from 'jquery';

import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';

function generateTags() {
  let tags = [
    store.getters['UnumStore/primaryUnum'].mac_address,
    store.state.UserStore.user.email,
    store.getters['LanStore/currentLan'].isp_id,
    store.getters['LanStore/currentLan'].timezone,
    store.getters['UnumStore/primaryUnum'].firmware_version,
    store.getters['BrandingStore/brandNiceName'],
    I18n.locale
  ];

  return tags.filter(tag => tag !== null && tag !== undefined);
}

const brandEmailOverrides = {
  motosync: ['support@motorolamentor.zendesk.com']
};

export default {

  data() {
    return {
      mutationObserver: null
    };
  },

  methods: {
    updatePhoneNumberInput(newNumber){
      try {
        let number = new AsYouType('US').input(newNumber);

        number = parsePhoneNumber(number, {
          defaultCountry: 'US',
          extract: false
        });

        return '+1 ' + number.format('NATIONAL');

      } catch (e){
        return;
      }
    },

    isValidPhoneNumberForCountry(phoneNumberString, country) {
      const regex = /[^0-9-+()\s]+/;

      if(phoneNumberString.match(regex)){
        return false;
      }

      const phoneNumber = parsePhoneNumber(phoneNumberString, {
        defaultCountry: country,
        // Demand that the entire input string must be a phone number.
        // Otherwise, it would "extract" a phone number from an input string.
        extract: false
      });
      if (!phoneNumber) {
        return false;
      }
      if (phoneNumber.country !== country) {
        return false;
      }
      return phoneNumber.isValid();
    },

    openSupportEmail(options = {}) {
      const brand = store.state.BrandingStore.brand;

      // Set the email address to prefill into the email client.
      // Must be an array because the native plugin we use requires it to be one.
      let to = options.to;
      to = to || brandEmailOverrides[brand];
      to = to || ['support@minim.co'];

      const subject = options.subject || '';
      const message = options.message || '';

      this.$native.exec('email_share', { to, subject, message });
    },

    openZendesk(location) {
      if(location) analytics.logEvent('contact_support', { location });
      this.$zendesk.zE('webWidget', 'prefill', {
        email: {
          value: store.state.UserStore.user.email
        }
      });
      this.$zendesk.zE('webWidget', 'chat:addTags', generateTags());
      this.$zendesk.zE.activate({ hideOnClose: true });

      // This timout is intentional here
      // It waits for the Zendesk iframe to fully rendered into the DOM
      setTimeout(() => {
        // check if there is any previously created MutationObserver
        // If yes than, first disconnect it before observing new
        if(this.mutationObserver) {
          this.mutationObserver.disconnect();
          this.mutationObserver = null;
        }
        this.setupObserver();
      }, 1000);
    },

    async callbackRequestStatus(){
      if(await this.$native.exec('fetch_preference', 'callbackRequest')){
        let requestSubmittedAt = (moment(JSON.parse(await this.$native.exec('fetch_preference', 'callbackRequest')).timeStamp));
        if( moment().diff(requestSubmittedAt,'hours') >= 1 ){ await this.$native.exec('remove_preference', 'callbackRequest'); }
        else return true;
      }
      return false;
    },

    setupObserver() {

      // The Zendesk  renders its view inside the iframe having attribute 'id' #webWidget
      // We use contentWindow.document.body in order to grab the elements of the iframe body
      const targetNode = document.querySelector('#webWidget').contentWindow.document.body;

      // then we search for any of the <a> tag with parent elemnt having .Linkify class
      let findAnchorNode = $(targetNode).find('.Linkify').find('a');

      // Adding this code intentionally to detect <a> again if we close and reopen the chat from the previous point
      if(findAnchorNode.length) {
        findAnchorNode.on('click', this.linkHandler);
      }

      // Initialize the MutationObserver object to listen for the #webWidget iframe changes
      var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

      // Adding a callback to the MutationObserver object
      this.mutationObserver = new MutationObserver(() => {
        let find = $(targetNode).find('.Linkify').find('a');

        if(find.length) {
          find.on('click', this.linkHandler);
        }
      });

      // MutationObserver configs
      var obsConfig = {
        childList: true,
        characterData: true,
        attributes: true,
        subtree: true
      };

      //Add a target node to the observer. Can only add one node at a time.
      this.mutationObserver.observe(targetNode, obsConfig);
    },

    linkHandler(e) {
      e.preventDefault();
      this.$native.exec('open_external_url', e.target.href);
    }
  }
};
