<template>
  <li class="flex-container align-center justify-between">
    <div @click="routeToAccessPointPage" class="flex-container align-start w-80">
      <div
        class="online-status-circle m-t-8 m-r-8"
        :class="useOnlineState ? 'is-online' : 'is-offline'"
      />
      <div class="w-80">
        <h6 class="minim-mobile-text secondary-font text-neutral-darkest-color large truncate-two-lines">
          {{ ssid.length > 0 ? ssid : $I18n.t('mobile.access_points.card.missing_ssid') }}
        </h6>
        <p class="minim-mobile-text secondary-font small m-t-4" :class="textClass" style="white-space: nowrap;">
          <span v-if="isPrivate" class="private-tag minim-mobile-text secondary-font small text-neutral-darker-color">
            {{ $I18n.t('private') }}
          </span>
          <span v-if="isIsolated" class="isolate-tag minim-mobile-text secondary-font small text-neutral-darker-color">
            {{ $I18n.t('isolate') }}
          </span>
          {{ niceLabel }} &nbsp;|&nbsp; {{ statusText }}
        </p>
      </div>
    </div>

    <div class="flex-container align-center">
      <minim-mobile-icon
        v-if="showShareButton"
        @click="shareSsidAndPassword"
        class="m-r-16"
        :iconName="shareIconName"
        color="neutral-dark"
      />
      <minim-mobile-icon
        @click="routeToAccessPointPage"
        iconName="actionable"
        color="neutral-darker"
        size="x-small"
        :data-test-id="`ap-${ssid}-settings-button`"
      />
    </div>
  </li>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ShareSsidAndPasswordMixin from 'mobile/shared/mixins/share_ssid_and_password';

export default {
  mixins: [ShareSsidAndPasswordMixin],

  props: {
    enabled: {
      type: Boolean,
      required: true
    },

    accessPointId: {
      type: String,
      required: true
    },

    ssid: {
      type: String,
      required: true
    },

    password: {
      type: String,
      required: true
    },

    radioText: {
      type: String,
      required: true
    },

    connectedDevicesCount: {
      type: Number,
      required: true
    },

    isPrivate: {
      type: Boolean,
      default: false
    },

    isIsolated: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapGetters('UnumStore', ['primaryUnum']),
    ...mapGetters('AccessPointStore', ['getAccessPoint']),

    ssidText() {
      return (this.ssid && this.ssid.length) ? this.ssid : this.$I18n.t('mobile.access_points.card.missing_ssid');
    },

    online() {
      return this.primaryUnum.active && !this.primaryUnum.is_rebooting;
    },

    useOnlineState() {
      return this.online && this.enabled;
    },

    accessPoint() {
      return this.getAccessPoint(this.accessPointId);
    },

    statusText() {
      if (!this.enabled) return this.$I18n.t('disabled');
      if (!this.online) return this.$I18n.t('offline');

      return this.connectedDevicesCount === 1
        ? this.$I18n.t('mobile.access_points.card.connected_devices.singular', { count: this.connectedDevicesCount })
        : this.$I18n.t('mobile.access_points.card.connected_devices.plural', { count: this.connectedDevicesCount });
    },

    label() {
      return this.accessPoint.label || 'home';
    },

    textClass() {
      if (!this.enabled || !this.online) {
        return 'text-neutral-darker-color';
      }

      switch(this.label) {
      case 'home':
        return ['vgi', 'inq'].includes(this.brand) ? 'mobile-home-network' : 'text-primary-color';
      case 'work':
        return 'text-darker-orange-color';
      case 'guest':
        return 'text-dark-green-color';
      default:
        return '';
      }
    },

    niceLabel() {
      switch(this.label) {
      case 'work':
        return this.$I18n.t('mobile.access_points.labels.work');
      case 'guest':
        return this.$I18n.t('mobile.access_points.labels.guest');
      case 'home':
      default:
        return this.$I18n.t('mobile.access_points.labels.home');
      }
    }
  },

  methods: {
    routeToAccessPointPage() {
      let tmpSsid = this.ssid || 'NO SSID';
      this.$router.push({ name: 'access_points_show_path', params: { lanId: this.$route.params.lanId, uriSsid: tmpSsid }});
    }
  }
};
</script>

<style scoped>
  .private-tag {
    margin-right: 4px;
    padding: 0px 4px;
    background: #F7F7F7;
    border-radius: 3px;
  }
  .isolate-tag {
    margin-right: 8px;
    padding: 0px 4px;
    background: #F7F7F7;
    border-radius: 3px;
  }
</style>
