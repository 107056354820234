<template>
  <div class="minim-mobile-card content-filtering-v3-card">
    <div class="flex-container justify-between align-center">
      <div class="flex-container align-center grow-1">
        <h2 class="minim-mobile-text primary-font small neutral-darker-color">
          {{ $I18n.t('mobile.content_filters_v3.full_home_filter.card.title') }}
        </h2>
        <minim-mobile-icon
          class="informational-button"
          style="top: 3px;"
          iconName="info"
          size="x-small"
          name="content-filter-info-tip"
        />
        <info-tippy
          to="content-filter-info-tip"
          ref="content-filter-info-tip"
          trigger="click"
          :textAlignLeft="true"
        >
          <span class="minim-mobile-text secondary-font medium text-neutral-lightest-color">
            {{ $I18n.t('mobile.content_filters_v3.full_home_filter.card.info') }}
          </span>
        </info-tippy>
      </div>
      <minim-mobile-card-link
        :text="linkText"
        :to="{
          name: 'lan_content_filters_v3_edit_path',
          params: {
            lanId: $route.params.lanId
          }
        }"
      />
    </div>

    <div class="m-t-24 m-b-24 flex-container justify-center">
      <div class="full-home-filter-icons">
        <filter-card-icon
          v-for="(filter, index) in filters"
          :iconName="filter.iconName"
          :enabled="filterEnabled(filter.tags)"
          :locked="false"
          :key="`full-home-filter-icon-${index}`"
        />
      </div>
    </div>

    <p class="minim-mobile-text secondary-font large text-center">
      {{ filterStatusText }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FilterCardIcon from 'mobile/shared/components/content_filters_v3/filter_card_icon';
import InfoTippy from 'mobile/shared/components/buttons/info_tippy.vue';

export default {
  components: {
    'filter-card-icon': FilterCardIcon,
    'info-tippy': InfoTippy
  },

  data() {
    return {
      filters: [
        {
          tags: ['malicious'],
          iconName: 'security-plus'
        },
        {
          tags: ['ads'],
          iconName: 'ad-privacy'
        },
        {
          tags: ['adult'],
          iconName: 'adult-block'
        },
      ]
    };
  },

  computed: {
    ...mapGetters('LanStore', ['getFilterSettingsForLan', 'checkIfLanHasFilterEnabled']),

    filterSettings() {
      return this.getFilterSettingsForLan;
    },

    hasFilters() {
      return !!this.filterSettings.length;
    },

    filterStatusText() {
      return this.hasFilters
        ? this.$I18n.t('mobile.content_filters_v3.full_home_filter.card.filter_is_on')
        : this.$I18n.t('mobile.content_filters_v3.full_home_filter.card.filter_is_off');
    },

    linkText() {
      return this.hasFilters
        ? this.$I18n.t('mobile.content_filters_v3.full_home_filter.card.manage')
        : this.$I18n.t('mobile.content_filters_v3.full_home_filter.card.turn_on') ;
    }
  },

  methods: {
    filterEnabled(filterTags) {
      return this.checkIfLanHasFilterEnabled(filterTags);
    }
  }
};
</script>
