<template>
  <button @click="logOut" :class="btnPosition" class="logout-btn minim-mobile-text secondary-font small">
    {{ $I18n.t('log_out') }}
  </button>
</template>

<script>
export default {
  props: {
    btnPosition: {
      type: String,
      default: 'right'
    }
  },

  methods: {
    logOut() {
      return this.$store.dispatch('UserStore/logout').then(() => {
        window.app.reload();
      });
    }
  }
};
</script>

<style scoped>
.logout-btn {
  position: absolute;
  top: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  -webkit-appearance: none;
  border: none;
  background: transparent;
  z-index: 99999;
}
.left {
  left: 15px;
}

.right {
  right: 15px;
}
</style>
