<template>
  <div class="mobile-login-container bg-white">
    <revoke-data-consent-modal :open="revokeDataConsentModalIsOpen" @close="revokeDataConsentModalIsOpen = false" :isRetailCustomer="isRetailCustomer"/>
    <div>
      <div class="center p-b-40">
        <login-icon />
        <h2 class="header">
          {{ header }}
        </h2>
        <p class="minim-mobile-text secondary-font large m-t-16 m-b-24">
          {{ subheader }}
        </p>
        <p v-if="isRetailCustomer" v-html="warningText"
           :class="`data-revocation-banner m-b-32 ${this.brand}`"
        >

        </p>
      </div>
    </div>

    <div class="center bottom submit-buttons">
      <button
        @click="revokeDataConsentModalIsOpen = true"
        class="minim-mobile-primary-button negative-color large m-b-24"
      >
        {{ buttonText }}
      </button>

      <button
        @click="$router.go(-1)"
        class="minim-mobile-primary-button ghost large"
      >
        {{ $I18n.t('go_back') }}
      </button>

      <button
          class="minim-mobile-primary-button shadow-none primary-font small light text-neutral-darker-color m-t-32 flex-container align-center"
          style="width: 279px; background: none"
          @click="openZendesk('data_consent')"
      >
        {{ $I18n.t('contact_support') }}
        <minim-mobile-icon
            iconName="actionable"
            color="neutral-dark"
            size="x-small"
            class="m-l-16"
        />
      </button>
    </div>
  </div>
</template>
<script>
import LoginIcon from '../../components/shared/login_icon';
import RevokeDataConsentModal from '../../../accounts/components/data_consent/revoke_data_consent_modal.vue';
import ContactSupportMixin from 'mobile/shared/mixins/contact_support_mixin';

import {mapGetters, mapState} from 'vuex';

export default {
  mixins: [ContactSupportMixin],
  
  components: {
    'login-icon': LoginIcon,
    'revoke-data-consent-modal': RevokeDataConsentModal
  },

  async mounted(){
    await this.$store.dispatch('LanStore/index');
  },

  data(){
    return {
      revokeDataConsentModalIsOpen: false
    };
  },

  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapState('LanStore', ['lans']),
    ...mapGetters({
      allUnumsAreUnmanaged: 'UnumStore/allUnumsAreUnmanaged'
    }),

    isRetailCustomer(){
      return (!this.lans || this.lans.every((lan) => lan.features.includes('retail-isp')));
    },

    header(){
      return this.isRetailCustomer ? this.$i18n.t('terms_of_service.data_consent.revoke_data') : this.$i18n.t('terms_of_service.data_consent.request_revoke_data');
    },

    subheader(){
      return this.isRetailCustomer ? this.$i18n.t('terms_of_service.data_consent.must_deactivate') : this.$i18n.t('terms_of_service.data_consent.request_to_deactivate');
    },

    buttonText(){
      return this.isRetailCustomer ? this.$i18n.t('terms_of_service.privacy_compliance.deactivate_account') : this.$i18n.t('terms_of_service.data_consent.request_revocation');
    },

    warningText() {
      if (this.allUnumsAreUnmanaged) {
        return this.$i18n.t('terms_of_service.data_consent.data_revoke_text');
      }
      return this.$i18n.t('terms_of_service.data_consent.data_revoke_text') + '<br/><br/>' + this.$i18n.t('terms_of_service.data_consent.data_revoke_warning_text');
    }
  }
};
</script>
