<template>
  <div id="app-body" :class="`${brand}-body h-full w-full`">
    <router-view />
  </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: 'video-walkthrough-layout',

  data() {
    return {
      appBody: null
    };
  },
  computed:{
    ...mapGetters({
      brand: 'BrandingStore/getBrand',
    })
  },

  created() {
    this.appBody = document.getElementsByTagName('body')[0];
    this.appBody.classList.add(`${this.brand}-body`);
  },

  beforeDestroy() {
    this.appBody.classList.remove(`${this.brand}-body`);
  }
};
</script>
