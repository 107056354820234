import app from 'mobile';
import graph_formatters from 'graph_formatters';

// Polyfills
import 'shared/polyfills/object_assign';

window.graph_formatters = graph_formatters;

// Give the run loop some time to breathe
setTimeout(() => {
  console.log('STARTING APP');
  app.init();
});
