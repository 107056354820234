<template>
  <div class="data-privacy-container w-full text-center bg-white">
    <div class="m-b-16">
      <div v-if="brand !== 'motosync'" class="logo-header minim"/>
      <h2 class="header m-b-16">
        {{ $i18n.t('data_privacy.header') }}
      </h2>
      <p class="minim-mobile-text secondary-font large m-b-24">
        {{ $i18n.t('data_privacy.subheader') }}
      </p>

      <div class="flex-container column p-l-20 p-r-20">
        <p
          v-html="$i18n.t('data_privacy.banner', {brand: brandNiceName })"
          style="margin-left: -44px; margin-right: -44px;"
          class="data-privacy-banner m-b-32"
        >
        </p>

        <minim-mobile-text-field
          :placeholder="$I18n.t('devise.sessions.first_name')"
          v-model="firstName"
          data-test-id="first_name_field"
        >
          <span slot="leftMostIcon">
            <minim-mobile-icon
              iconName="profile-16"
              color="neutral-dark"
              size="x-small"
            />
          </span>
        </minim-mobile-text-field>

        <div>
          <div
            @click="toggleTOSAcceptance"
            class="flex-container align-start p-t-24 p-b-16 text-left"
          >
            <div
              class="minim-mobile-checkbox"
              :class="isDataTOSChecked ? 'selected' : ''"
              data-test-id="accept_data_privacy"
            >
              <div class="check">
              </div>
            </div>
            <p
              class="minim-mobile-text secondary-font medium p-l-16"
              v-html="$i18n.t('data_privacy.data_permission', {brand: brandNiceName})"
            >
            </p>
          </div>

          <div
            v-if="brand === 'motosync'"
            @click="toggleMarketingOptIn"
            class="flex-container align-start text-left"
          >
            <div
              class="minim-mobile-checkbox"
              :class="isMarketingOptInChecked ? 'selected' : ''"
              data-test-id="marketing_opt_in"
            >
              <div class="check">
              </div>
            </div>
            <p
              class="minim-mobile-text secondary-font medium p-l-16"
              v-html="$i18n.t('data_privacy.marketing_opt_in', {brand: brandNiceName})"
            >
            </p>
          </div>
        </div>
      </div>
    </div>


    <div>
      <button
        @click="submit"
        class="minim-mobile-primary-button m-b-24"
        style="width: 279px"
        data-test-id="accept_data_privacy_button"
      >
        {{ $I18n.t('continue') }}
      </button>

      <button v-if="lans.length >0" @click="$router.push({
        name: 'data_revocation_path'})"
              class="minim-mobile-primary-button negative-color large ghost m-b-24"
      >
        {{ $I18n.t('terms_of_service.data_consent.revoke_data') }}
      </button>

      <button
        class="minim-mobile-primary-button shadow-none primary-font small light text-neutral-darker-color m-t-2 flex-container align-center"
        style="width: 279px; background: none"
        @click="logOut"
      >
        {{ $I18n.t('log_out') }}
        <minim-mobile-icon
            iconName="actionable"
            color="neutral-dark"
            size="x-small"
            class="m-l-16"
        />
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import GetProperPathMixin from 'mobile/shared/mixins/root_path_mixin';
import minim_api from 'mobile/shared/utils/minim_api';


export default {

  mixins: [GetProperPathMixin],

  data(){
    return {
      firstName: '',
      isMarketingOptInChecked: false,
      isDataTOSChecked: false,
      errors: {}
    };
  },

  async mounted(){
    await this.$store.dispatch('LanStore/index');
    this.firstName = this.currentUser.first_name;
  },

  computed: {
    ...mapState('BrandingStore', ['brand','brandNiceName']),
    ...mapState('LanStore', ['lans']),
    ...mapGetters('UserStore', ['currentUser']),
    ...mapGetters('ServiceTermsStore', ['getByKind']),

    dataConsentTerm(){
      return this.getByKind('data_consent');
    }
  },

  methods:{
    toggleTOSAcceptance(){
      this.isDataTOSChecked = !this.isDataTOSChecked;
    },

    toggleMarketingOptIn(){
      this.isMarketingOptInChecked = !this.isMarketingOptInChecked;
    },

    async acceptTerm(){
      await this.$store.dispatch('ServiceTermsStore/accept', this.dataConsentTerm);
    },

    async optInMarketing(){
      await minim_api.post('api/v1/account/marketing/opt_in');
    },

    async optOutMarketing(){
      if(this.brand === 'motosync'){
        await minim_api.post('api/v1/account/marketing/opt_out');
      }
      return;
    },

    async submit(){
      if(!this.isDataTOSChecked){
        this.$toasted.global.minim_error(this.$I18n.t('data_privacy.error', {brand: this.brandNiceName}));
        return;
      }

      try {
        await this.acceptTerm();
        if(this.isMarketingOptInChecked){
          await this.optInMarketing();
        } else {
          await this.optOutMarketing();
        }
        if(this.firstName.length){
          await this.$store.dispatch('UserStore/update', {...this.currentUser, first_name: this.firstName });
        }
      }catch(e){
        console.log(e);
        this.$toasted.global.minim_error(this.$I18n.t('terms_of_service.network_error'));
        return;
      }

      this.getProperPath();
    }, 

    logOut() {
      return this.$store.dispatch('UserStore/logout').then(() => {
        window.app.reload();
      });
    }
  }
};
</script>
