<script>
/**
 * This component is used solely to render minim toasts on the HAML pages of the app such as the login & registration pages,
 * where the vue instance is not readily available yet. However, it could be used anywhere it makes sense, but using the
 * $toasted object via the vue instance on any typical vue page is probably easier.
 */
export default {
  props: {
    message: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    }
  },

  mounted() {
    if (['error', 'alert'].includes(this.type)) {
      this.$toasted.global.minim_error(this.message);
    } else {
      this.$toasted.global.minim_success(this.message);
    }
  }
};
</script>
