<template>
  <div class="filter-card-icon-container">
    <minim-mobile-icon
      class="filter-icon m-4"
      :class="statusClass"
      :iconName="iconName"
      color="neutral-darker"
      size="medium-large"
    />
    <minim-mobile-icon
      v-if="locked"
      class="locked-icon"
      iconName="lock-12"
      color="neutral-darker"
      size="x-small"
    />
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      required: true
    },

    enabled: {
      type: Boolean,
      required: true
    },

    locked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    statusClass() {
      return (this.enabled || this.locked) ? 'enabled' : null;
    }
  }
};
</script>
