<template>
  <portal v-if="shouldRenderPortal" to="minim-mobile-modal-portal">
    <transition @after-leave="handleAfterLeave" name="minim-mobile-modal">
      <div v-if="shouldRenderContent" :class="['minim-mobile-modal', zIndexLg ? 'zIndexLg': '']">
        <div @click="close" class="mobile-modal-content-wrapper">
          <div
            @click="(e) => nullifyParentEvents(e)"
            :class="{
              'modal-content-card': true,
              'unpadded': removeDefaultPadding
            }"
          >
            <div v-if="showHeader" class="modal-header">
              <slot name="header" />
            </div>
            <div class="modal-slot-container">
              <minim-mobile-icon
                v-if="showCloseButton"
                @click="close"
                class="close-button"
                iconName="close"
                color="neutral-darker"
                size="small"
              />

              <div class="modal-slot">
                <slot />
              </div>
            </div>
            <div v-if="showFooter" class="modal-footer" :style="!footerHasContent ? 'min-height: 32px' : ''">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    forceShowFooter: {
      type: Boolean,
      default: false
    },

    open: {
      type: Boolean,
      default: false
    },

    removeDefaultPadding: {
      type: Boolean,
      default: false
    },

    showCloseButton: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      default: true
    },
    
    zIndexLg: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      shouldRenderPortal: null,
      shouldRenderContent: null,
      footerHasContent: false
    };
  },

  mounted() {
    this.footerHasContent = !!this.$slots.footer;
  },

  methods: {
    handleAfterLeave() {
      this.shouldRenderPortal = false;
    },

    nullifyParentEvents(e) {
      e.stopPropagation();
    },

    close() {
      // Emmit a close event for the parent to handle
      this.$emit('close');
    }
  },

  watch: {
    open(value) {
      if (value) {
        this.shouldRenderPortal = true;

        this.$nextTick(() => { this.shouldRenderContent = true; });
      } else {
        this.shouldRenderContent = false;
      }
    }
  },

  computed: {
    ...mapGetters({
      lan: 'LanStore/currentLan',
    }),
    showHeader() {
      return !!this.$slots.header;
    },

    showFooter() {
      return !!this.$slots.footer || this.forceShowFooter;
    }
  }
};
</script>

<style scoped>
.icon-color {
  color: rgb(0, 0, 0);
  top: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.icon-position{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
}
.timeline-btn {
  width: 48px;
  height: 48px;
  left: 315px;
  top: 10px;
  background: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 4px;
  color: white;
  z-index: 1000;
}
</style>
