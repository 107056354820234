import Bugsnag from '@bugsnag/js';
import store from 'mobile/vuex/index';

export default {
  debug(...messages) {
    const user = store.getters['UserStore/currentUser'];
    const isEmployee = user && user.is_employee;
    const debugEnv = window.environment === 'development' || window.environment === 'staging';

    if (isEmployee || debugEnv) {
      messages.forEach(m => {
        console.log(m);
      });
    }
  },

  warn(warning) {
    console.warn(warning);
  },

  error(error) {
    console.error(error);
    Bugsnag.notify(error);
  }
};
