<template>
  <div class="mobile-login-container bg-white">
    <div>
      <div class="center p-b-40">
        <login-icon />
        <h2 class="header">
          {{ header }}
        </h2>
        <p class="minim-mobile-text secondary-font large">
          {{ subheader }}
        </p>
      </div>

      <accept-tos :value="accepted" @input="accepted = !accepted" />
    </div>

    <div class="center bottom submit-buttons">
      <button @click="acceptAll" class="minim-mobile-primary-button large m-b-24">{{ $I18n.t('continue') }}</button>
      <button @click="$router.push({
        name: 'data_revocation_path'})"
              class="minim-mobile-primary-button ghost negative-color large m-b-24"
      >
        {{ $I18n.t('terms_of_service.data_consent.revoke_data') }}
      </button>
      <button
          class="minim-mobile-primary-button shadow-none primary-font small light text-neutral-darker-color m-t-2 flex-container align-center"
          style="width: 279px; background: none"
          @click="logOut"
      >
        {{ $I18n.t('log_out') }}
        <minim-mobile-icon
            iconName="actionable"
            color="neutral-dark"
            size="x-small"
            class="m-l-16"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import GetProperPathMixin from 'mobile/shared/mixins/root_path_mixin';
import AcceptTOS from '../components/shared/accept_tos';
import LoginIcon from '../components/shared/login_icon';

export default {
  components: {
    'login-icon': LoginIcon,
    'accept-tos': AcceptTOS,
  },

  mixins: [GetProperPathMixin],

  data() {
    return {
      accepted: false
    };
  },

  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapGetters({
      terms: 'ServiceTermsStore/getNonDataConsentTerms',
      acceptedTerms: 'UserServiceTermsStore/checkAcceptance',
      lan: 'LanStore/currentLan'
    }),

    header() {
      if(this.brand === 'mca'){
        return this.$I18n.t('terms_of_service.mca_header');
      }
      else if(!this.acceptedTerms('t_and_c')){
        return this.$I18n.t('terms_of_service.terms_header');
      }
      else{
        return this.$I18n.t('terms_of_service.header');
      }
    },

    subheader() {
      if(this.brand === 'mca'){
        return this.$I18n.t('terms_of_service.mca_subheader');
      }
      else if(!this.acceptedTerms('t_and_c')){
        return this.$I18n.t('terms_of_service.subheader');
      }
      else{
        return this.$I18n.t('terms_of_service.mca_subheader');
      }
    },
  },

  methods: {
    async acceptAll() {
      if(!this.accepted) {
        this.$toasted.global.minim_error(this.$I18n.t('terms_of_service.error'));
        return;
      }

      try {
        await Promise.all(this.terms.map(async term => {
          if(term.kind === 'age') {
            // we have a dedicated page for the age ServiceTerm
            // existing users should be redirected to that screen (while newly registered users should not)
            return;
          }
          await this.$store.dispatch('ServiceTermsStore/accept', term);
        }));
        this.getProperPath();
      } catch(e){
        console.log(e);
        this.$toasted.global.minim_error(this.$I18n.t('terms_of_service.network_error'));
        return;
      }
    },

    logOut() {
      return this.$store.dispatch('UserStore/logout').then(() => {
        window.app.reload();
      });
    }
  }
};
</script>
