<template>
  <li
    @click="changeTab"
    :class="['tab', this.orientation, { 'selected': selected }]"
    :style="`width: ${widthPercentage}%;`"
  >
    <img v-if="showTabRightSideImg" :src="tabSideImg" class="tab-side right-tab-side">
    <a class="tab-title">
      <span class="flex-container align-center">
        <minim-mobile-icon
          v-if="selected && tabIconName"
          :iconName="tabIconName"
          class="m-r-6 m-b-4"
          color="neutral-darkest"
          size="x-small"
        />
        <span class="minim-mobile-text primary-font small">
          {{ tabName }}
        </span>
      </span>
    </a>
    <img v-if="showTabLefttSideImg" :src="tabSideImg" class="tab-side left-tab-side">
  </li>
</template>

<script>
// Images for the side of the tab, sadly we have to use images for this because CSS
// does not support anyway to create the inward rounded borders that the design mock ups require
import SelectedTabSide from 'mobile/shared/images/tabbed-card-side-selected.png';
import UnselectedTabSide from 'mobile/shared/images/tabbed-card-side-unselected.png';

export default {
  props: {
    tabName: {
      type: String
    },

    widthPercentage: {
      type: Number
    },

    selected: {
      type: Boolean,
      default: false
    },

    tabIndex: {
      type: Number
    },

    orientation: {
      type: String,
      required: true,
      validator(value) {
        return ['underneath', 'left', 'right', 'center'].includes(value);
      }
    },

    tabIconName: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      selectedTabSide: SelectedTabSide,
      unselectedTabSide: UnselectedTabSide,
    };
  },

  computed: {
    tabSideImg() {
      return this.selected
        ? this.selectedTabSide
        : this.unselectedTabSide;
    },

    showTabRightSideImg() {
      return this.orientation === 'right' || this.orientation === 'center';
    },

    showTabLefttSideImg() {
      return this.orientation === 'left' || this.orientation === 'center';
    },
  },

  methods: {
    changeTab() {
      this.$emit('changeTab', this.tabIndex);
    }
  }
};
</script>