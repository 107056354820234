<template>
  <nav v-if="showNav" :class="classes">
    <div style="padding: 25px">
      <div class="content-container left-side">
        <back-button v-if="showNav && !hideBackButton" />
        <portal-target name="left_header" />
      </div>
      <h1 class="mobile-page-title name-truncate" :style="{ 'max-width': '80%' }">
        <portal-target name="page_title" />
      </h1>
      <div class="content-container right-side">
        <portal-target name="right_header" />
      </div>
    </div>
    <div>
      <portal-target name="bottom_header" />
    </div>
  </nav>
</template>

<script>
import BackButton from './back_button.vue';

export default {
  components: {
    'back-button': BackButton
  },

  props: {
    showShadow: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    showNav() {
      return !this.$route.meta.hideTopNavbar;
    },
    
    hideBackButton() {
      return this.$route.query.hideBackBtn;
    },

    classes() {
      let classes = ['mobile-top-navbar'];

      if (this.showShadow) {
        classes.push('with-shadow');
      }

      if (this.$route.meta.topNavbarClass) {
        classes.push(this.$route.meta.topNavbarClass);
      }

      return classes;
    }
  }
};
</script>
