<template>
  <cancel-button-container
    mode="routerPush"
    :routeToGoBackTo="{name: 'network_switcher_path'}"
  >
    <div class="add-device-network-container">
      <div>
        <h2 class="m-b-32">
          {{ $I18n.t('mobile.settings.index.network_switcher.add_device_&_network') }}
        </h2>
        <div
          class="add-device-radio flex-container align-center justify-between m-b-24"
          @click="setNetworkValue('new')"
        >
          <div class="flex-container align-center justify-between m-r-24">
            <minim-mobile-icon
              iconName="lan-add-large"
              color="primary"
              size="medium-large"
              class="m-r-12"
            />
            <p class="m-0 minim-mobile-text secondary-font large text-left">
              {{$I18n.t('mobile.settings.index.network_switcher.set_up_or_join') }}
            </p>
          </div>

          <minim-mobile-radio-button :selected="isSelected('new')"/>
        </div>

        <p class="minim-mobile-text primary-font small m-b-16 m-l-40" style="text-align: left">
          {{ $I18n.t('mobile.settings.index.network_switcher.add_to_existing_network') }}
        </p>
        <div class="minim-mobile-list add-device-list-container">
          <li
            v-for="unum in uniqueLanPrimaryUnums"
            :key="unum.id"
            @click="setNetworkValue(unum)"
            class="flex-container align-center justify-between minim-mobile-list-item mix-blend"
          >
            <div class="flex-container align-center m-r-24">
              <div class="m-l-16 m-r-12"><img :src="unum.hardware_kind_image" class="center-image" style="height: 40px; width: 40px"></div>
              <p class="m-0 minim-mobile-text secondary-font large text-left">
                {{ unum.lan_name }}
              </p> 
            </div>
            <minim-mobile-radio-button :selected="isSelected(unum.id)" class="m-r-16"/>
          </li>
        </div>
      </div>
      <button
        class="minim-mobile-primary-button m-t-32"
        style="font-weight: 200"
        @click="goToPath"
        v-if="value"
      >
        {{ buttonText }}
      </button>
    </div>
  </cancel-button-container>
</template>
<script>

import CancelButtonContainer from '../components/shared/cancel_button_container.vue';
import MinimMobileRadioButton from 'mobile/shared/components/global/minim_mobile_radio_button';

import minim_api from 'mobile/shared/utils/minim_api';

import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    'cancel-button-container': CancelButtonContainer,
    'minim-mobile-radio-button': MinimMobileRadioButton
  },

  data(){
    return {
      path: null,
      value: null,
      lanId: null,
      lanPrimaryUnums: [],
      buttonText: ''
    };
  },

  async beforeMount() {
    await this.lans.forEach((lan) => {
      this.setLanPrimaryUnumsData(lan);
    });
  },

  mounted(){
    this.buttonText = this.$I18n.t('mobile.settings.index.network_switcher.set_up_or_join');
  },
  computed: {
    ...mapState('LanStore', ['lans', 'lan']),
    ...mapGetters('OnboardingImageStore', ['getHardwareImage']),
    ...mapGetters({
      getHardwareKind: 'HardwareKindStore/getHardwareKind',
      primaryUnum: 'UnumStore/primaryUnum'
    }),

    uniqueLanPrimaryUnums() {
      return this.lanPrimaryUnums.filter((value, index, self) =>
        index === self.findIndex((t) => (t.place === value.place && t.hardware_kind_id === value.hardware_kind_id))
      );
    }
  },
  methods: {
    isSelected(val){
      return this.value === val;
    },
    async setNetworkValue(unum){
      unum !== 'new' ? this.lanId = unum.lan_id : '';

      this.buttonText = (unum === 'new' ? this.$I18n.t('mobile.settings.index.network_switcher.set_up_or_join') : this.$I18n.t('mobile.settings.index.network_switcher.add_to_network', {network : unum.name }));

      const unumId = unum === 'new' ? unum : unum.id;
      this.value === unumId ? this.value = null : this.value = unumId;
    },
    async setLanPrimaryUnumsData(lan) {
      let unum = (await minim_api.get(`api/v1/lans/${lan.id}/unums/${lan.primary_unum_id}`)).data;
      unum.lan_name = lan.name ? lan.name : unum.name;
      this.lanPrimaryUnums.push(unum);
    },

    getHardwareKindImage(unum) {
      let hardware_kind = this.getHardwareKind(unum.hardware_kind_id) || {};
      return hardware_kind.hardware_image;
    },

    async goToPath(){
      if (this.value === 'new'){
        this.$store.commit('OnboardingStore/setIsFromNetworkSwitcher', true);
        this.$router.push({
          name: 'onboarding_begin_path'
        });
      } else {
        await this.initAddMeshExtenderFlow(this.lanId);
      }
    },

    async initAddMeshExtenderFlow(lanId) {
      await this.$store.dispatch('switchLan', {lanId});
      let lan = await minim_api.get(`api/v1/lans/${lanId}`);
      this.$store.commit('LanStore/set', { ...lan.data});
      await this.$store.dispatch('UnumStore/index');
      this.$store.commit('OnboardingImageStore/preloadHardwareImages', this.primaryUnum.hardware_kind_id);
      this.$store.commit('OnboardingImageStore/preloadGenericImages');
      this.$store.commit('OnboardingStore/clearOnboarding');
      this.$store.commit('OnboardingStore/setIsFromNetworkSwitcher', true);

      let unums = this.$store.state['UnumStore'].unums;

      let unumsThatNeedUpdate = unums.filter(unum => unum.is_active && unum.is_firmware_update_available);
      unumsThatNeedUpdate = unumsThatNeedUpdate.sort(unum => (unum.id === this.primaryUnum.id ? -1 : 1));

      const updateRequired = unumsThatNeedUpdate.some(unum => unum.is_active && unum.is_firmware_update_required);

      if (unumsThatNeedUpdate.length && updateRequired) {
        this.$router.push({
          name: 'onboarding_firmware_update_path',
          query: {
            is_add_extender_flow: true,
            mesh_node: false,
            unums_to_update_ids: unumsThatNeedUpdate.map(({ id }) => id),
            update_is_required: updateRequired,
            tab_name: 'onboarding'
          }
        });
      } else {
        this.$router.push({
          name: 'qr_code_fork_path',
          params: {
            mesh_node: true
          },
          query: {
            tab_name: 'onboarding'
          }
        });
      }
    },
  }
};
</script>
