<template>
  <router-link v-if="backButtonLink" :to="backButtonLink">
    <minim-mobile-icon iconName="back" :color="buttonColor" size="small" />
  </router-link>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters('HistoryStore', ['getPreviousTabPath']),
    ...mapState('HistoryStore', ['inAppBackButtonOverridePath']),

    backButtonLink() {
      // If we've set an override path use that
      if (this.inAppBackButtonOverridePath) {
        return this.inAppBackButtonOverridePath;
      }

      // Else use the last route on the back stack for the current tab
      const route = this.getPreviousTabPath;

      if (route) {
        return {
          name: route.name,
          params: route.params,
          query: route.query,
          meta: route.meta
        };
      }
      return null;
    },

    buttonColor() {
      return this.$route.name === 'subscriptions_landing_path' ? 'neutral-lightest' : 'neutral-darker';
    }
  }
};
</script>
