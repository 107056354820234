/**
 * Utility function for applying metadata to a list of Vue router objects
 *
 * @param {Array} routes - the routes to apply the metadata to
 * @param {Object} metadata - the metadata to apply to the route
 * @param {Boolean} forceOverride - whether or not to force overriding the value of keys if they already exist as metadata on the route object
 */
export default function(routes = [], metadata = {}, forceOverride = false) {
  return routes.map(route => {
    if (!route.meta) {
      route.meta = metadata;
    } else {
      Object.keys(metadata).forEach(key => {
        if (!Object.prototype.hasOwnProperty.call(route.meta, key) || forceOverride) {
          route.meta[key] = metadata[key];
        }
      });
    }

    return route;
  });
}
