import command_statuses from './command_statuses';
import { serializePacket, deserializeAndValidatePacket } from './packet';
import { s4_guid } from 'shared/lib/random';

export default class Commander {
  /**
   * This class is used to post commands to the remote window and handle the responses from running those commands.
   *
   * @param {String} namespace - the namespace to sends packets/commands within
   * @param {Boolean} running_on_device - whether or not the app is running on a mobile device - this is used to determine if we should use fallback functions or not
   * @param {Window} remote_window - the remote window we want the class to interact with
   * @param {Object} fallback_functions - fallback functions for if we're not running on a mobile device
   */
  constructor(namespace, local_name, remote_name, running_on_device, remote_window, fallback_functions = { exec() {} }) {
    this._running_commands = {};

    this.namespace = namespace;
    this.local_name = local_name;
    this.remote_name = remote_name;
    this.running_on_device = running_on_device;
    this.remote_window = remote_window;
    this.fallback_functions = fallback_functions;

    window.addEventListener('message', this._onCommandResponse.bind(this), { capture: false, passive: true });
  }

  /**
   * Posts to the remote window to trigger a remote command. Uses a fallback if we're not running on a mobile device
   *
   * @param {String} command_name - the name of the remote command we want to call
   * @param {Any} param - a parameter to pass to the remote function
   */
  exec(command_name, param) {
    return new Promise((resolve, reject) => {
      if (this.running_on_device) {
        console.log(`${this.local_name} making request to ${this.remote_name} to execute command: "${command_name}"`);
        const packet = this._preProcessCommand(command_name, param, resolve, reject);
        this.remote_window.postMessage(packet, '*');
      } else {
        this.fallback_functions.exec(command_name, param, resolve, reject);
      }
    });
  }

  /**
   * Pre-processes a command before posting to the remote window to execute it.
   *
   * @param {String} command_name - the name of the remote command we want to call
   * @param {Any} param - a parameter to pass to the remote function
   * @param {Function} resolve - resolver function for the promise kicked off in this.exec
   * @param {Function} reject - rejection function for the promise kicked off in this.exec
   */
  _preProcessCommand(command_name, param, resolve, reject) {
    const id = s4_guid();

    // Store the command under a unique ID so we can resolve it or reject it later
    this._running_commands[id] = {
      command_name,
      param,
      resolve,
      reject
    };

    const packet = serializePacket({
      namespace: this.namespace,
      command_name,
      param,
      id
    });

    return packet;
  }

  /**
   * This function is meant to be used as an event listener, to listen for when the remote window responds with
   * the result of running the remote command and properly handle that response.
   *
   * @param {MessageEvent} event - the event object from the remote window posting to the local window
   */
  _onCommandResponse(event) {
    const { packet, is_valid } = deserializeAndValidatePacket(event.data, this.namespace);

    if (!is_valid) return;

    const { id, data, status } = packet;

    let running_command;

    switch(status) {
    case command_statuses.RESOLVE:
    case command_statuses.REJECT:
      running_command = this._running_commands[id];

      if (!running_command) {
        console.error(`Invalid comand ID recieved in ${this.local_name} from ${this.remote_name}`);
        return;
      }

      // Resolve or reject the command based on the status,
      // then remove the command from the store of running commands
      running_command[status](data);
      delete this._running_commands[id];
      break;
    default:
      console.error(`Invalid comand response status recieved in ${this.local_name} from ${this.remote_name}`);
      break;
    }
  }
}
