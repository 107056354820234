import Vue from 'vue/dist/vue.esm';
import $ from 'jquery';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import PortalVue from 'portal-vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import initializeVueToasted from 'shared/setup/vue_toasted';

import store from 'mobile/vuex/index.js';
import router from 'mobile/router.js';
import i18n from 'shared/lib/i18n.js';

import RemoteBridge from 'shared/lib/remote_bridge/index';
import bridgeFunctions from 'mobile/bridge/functions';
import bridgeFallbacks from 'mobile/bridge/fallbacks';

import persistence from 'mobile/shared/utils/persistence';
import analytics from 'mobile/shared/utils/analytics';

import minim_api from 'mobile/shared/utils/minim_api';

import ComponentLoader from 'shared/vue_component_loader.js';
import { initializeDirectives } from 'mobile/directives/index.js';
import mobile_components from 'mobile/shared/components/global/index';

import { initializeZendesk } from 'mobile/shared/utils/zendesk';
import { listenForNativeEvents } from 'mobile/shared/utils/native_events';
import { setI18nLanguage } from 'shared/lib/i18n.js';

let native;

// Function to tell us whether or not we're running on an actual phone
function onDevice() {
  try {
    // This works because this app gets embedded into an iframe when run
    // on a phone so the window will not be the top level window in that case
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

// Tries to restore a user's existing session.
// If successful it will return true else it will return false.
async function restoreSession() {
  let hasAPIKey = await minim_api.restoreAPIKey();
  const appID = await native.exec('get_app_id');

  // TODO: Remove this block of code in SW-3109
  if (!hasAPIKey && appID) {
    try {
      await minim_api.createAPIKey({ app_install_id: appID });
      hasAPIKey = true;
    } catch(error) {
      console.error(error);
      console.log('Failed to generate an API key from the app install ID');
    }
  }

  if (hasAPIKey) {
    try {
      await minim_api.createAccessToken();
      await store.dispatch('UserStore/show', minim_api.apiKey.user_id);
      return true;
    } catch (error) {
      console.error(error);
      console.log('Restoring session failed. Proceeding as if the user is logged out.');
    }
  } else {
    console.log('No API key found. Proceeding as if the user is logged out.');
  }

  return false;
}

function configureUIForPhoneModel(platform, model) {
  // Apply the name of the platform as a CSS class to the HTML element
  // so that we can apply platform specific styling
  $('html').addClass(platform);

  if (platform === 'ios') {
    // Ensure that the accessory bar above the keyboard always shows up
    // The accessory bar only exists on iOS, which is why we call for iOS only
    native.exec('hide_keyboard_accessory_bar', false);
  }

  // Adds a way to add styles specifically for iPhones that have the top gap
  if (model && model.match(/iPhone10|iPhone11|x86_64/)) {
    $('html').addClass('iPhone10');
  }
}

// Temporary change - we need to remove any custom domains from a user's LAN on LANs
// that support EDNS0 since we no longer support LAN based domain settings for EDNS0 LANs
async function cleanUpCustomLanDomains(lan) {
  if (!lan.features.includes('edns0_content_filter')) return;

  try {
    await minim_api.post(`api/v1/lans/${lan.id}/reset_domains`);
  } catch (err) {
    console.log(err);
  }
}

async function setCurrentLan() {
  const lans = await store.dispatch('LanStore/index');

  if (lans && lans.length) {
    await window.native.exec('set_config_item', { key: 'has_one_lan', value: true });

    Promise.all(lans.map(l => {
      cleanUpCustomLanDomains(l);
    }));
  } else {
    await window.native.exec('set_config_item', { key: 'has_one_lan', value: false });
  }
}

async function updateAppInstall() {
  const app_id = await window.native.exec('get_app_id');
  const details = await window.native.exec('device_data');
  const build_info = await window.native.exec('get_build_info');

  try {
    await minim_api.patch(`/mobile/app_installs/${app_id}.json`, {
      app_install: {
        details,
        build_info
      }
    });
  } catch (err) {
    console.error(err);
    Bugsnag.notify(err);
    return err;
  }
}

const app = {
  reload() {
    const brand = store.state.BrandingStore.brand;
    window.location = `/mobile?brand=${brand}`;
  },

  async init() {
    // Turn on performance dev tools in development
    if (window.environment === 'development') {
      Vue.config.performance = true;
    }

    // Start up Bugsnag first thing to alert us of any unexpected errrors
    Bugsnag.start({
      apiKey: 'fd3304f616823e71952eff9f1327cb1b',
      releaseStage: window.environment,
      enabledReleaseStages: ['staging', 'production'],
      plugins: [new BugsnagPluginVue()]
    });

    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

    // Setup a bridge to communicate with the native app
    native = new RemoteBridge(
      'iframe',
      'native',
      onDevice(),
      parent,
      bridgeFunctions,
      [],
      bridgeFallbacks
    );

    window.native = native;

    // Clear out any state left over from the previous launch of the app
    await persistence.clearSavedState();
    await native.exec('set_preference', { key: 'lastBackgroundedAt', value: null });
    await native.exec('clear_webview_history');

    // Set initial data and other configurations needed for proper functioning of the app
    const build_info = await store.dispatch('NativeInfoStore/fetchBuildInfo');
    const device_data = await store.dispatch('NativeInfoStore/fetchDeviceData');
    const platform = device_data.platform.replace(' ', '_').toLowerCase();
    window.on_device = native.running_on_device;

    Bugsnag.addMetadata('app', {
      frame: 'Remote frame',
      version: build_info.version,
      package: build_info.packageName,
      model: device_data.model,
      platform
    });

    // Perform any setup that needs to be done after the DOM has become ready here
    $(document).ready(async () => {
      listenForNativeEvents(platform);
      configureUIForPhoneModel(platform, device_data.model);

      analytics.init(platform);
      window.analytics = analytics;

      // Set the current brand in the store
      store.dispatch('BrandingStore/fetchBrand');

      // Initialize network utilities
      minim_api.init();

      await setI18nLanguage();

      initializeVueToasted();

      // Initialize components and directives
      await ComponentLoader.init(mobile_components);
      ComponentLoader.process();
      initializeDirectives();

      // Try to restore the user's previous log in session
      // and if successful perform additional set up for logged in users
      const isLoggedIn = await restoreSession();
      if (isLoggedIn) {
        await this.afterLoginSetup();
      }

      if (onDevice()) {
        native.exec('configure_push_plugin');
        updateAppInstall();
      }

      // Add useful objects to the protoype
      Vue.prototype.$native = native;
      Vue.prototype.$analytics = analytics;
      Vue.prototype.$minim_api = minim_api;

      Vue.use(PortalVue);
      Vue.use(VueTippy, { arrow: true, theme: 'minim' });
      Vue.component('tippy', TippyComponent);
      Vue.use(VueHighcharts, { highcharts: Highcharts });

      if (platform !== 'browser') {
        native.exec('hide_splash_screen');
        native.exec('display_statusbar_overlay');
        native.exec('set_statusbar_color', '#ededed');
        native.exec('set_statusbar_text_mode', 'dark');
      }

      new Vue({
        i18n,
        router,
        store
      }).$mount('#app');
    });
  },

  async afterLoginSetup() {
    const user = store.getters['UserStore/currentUser'];

    // Set metadata about the current user in Bugsnag and Amplitude
    Bugsnag.setUser(user.param, user.email);
    analytics.setUserData(user);

    // Get the current LAN for the userr
    await setCurrentLan();

    // Fetch the service terms for the current user
    try {
      await Promise.all([
        store.dispatch('ServiceTermsStore/index'),
        store.dispatch('UserServiceTermsStore/index')
      ]);
    } catch(err) {
      console.log(err);
    }

    // Retrieve all of the user's active subscriptions and initialize the cordova purchase plugin
    if (user.is_employee) {
      try {
        await store.dispatch('CpfProductOptionStore/index');
        await store.dispatch('UserProductSubscriptionsStore/index');
        await native.exec('configure_purchase_plugin', {
          products: store.state.CpfProductStore.products,
          productOptions: store.state.CpfProductOptionStore.productOptions,
        });
      } catch (error) {
        console.error(error);
      }
    }

    initializeZendesk();
  },
};

window.app = app;
export default app;
