<template>
  <div>
    <h3 class="minim-mobile-text primary-font medium centered" v-html="header"></h3>
    <p class="minim-mobile-text secondary-font large less-bold centered m-t-32 m-b-16" v-html="bodyText">
    </p>
    <minim-mobile-text-field :placeholder="$I18n.t('remove').toUpperCase()" v-model="value" :errors="errors.value"
      type="text" class="m-t-32" inputClass="text-negative-color"
      @input="() => (value = value.toUpperCase())"></minim-mobile-text-field>
    <div class="flex-container justify-center m-t-32">
      <button @click="onClose" class="minim-mobile-primary-button negative-color ghost small m-6">
        {{ $I18n.t('cancel') }}
      </button>
      <button @click="onSubmit" class="minim-mobile-primary-button negative-color small m-6 shadow-large">
        {{ $I18n.t('remove') }}
      </button>
    </div>
  </div>
</template>
  
<script>
const REMOVE = 'REMOVE';

export default {
  props: {
    header: {
      type: String,
      default: 'Confirmation'
    },

    bodyText: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      value: '',
      errors: {},
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    onSubmit() {
      this.errors = {};
      if (this.value !== REMOVE) {
        this.errors = { value: [this.$I18n.t('mobile.unums.forget_single_unum.remove_validation_confirmation_modal.inputError')] };
        this.$emit('success', false);
      } else {
        this.$emit('success', true);
      }
    }
  }
};
</script>
  