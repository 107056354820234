<template>

  <div class="mobile-login-container bg-white">
    <deactivate-account-modal :open="deactivateAccountModalIsOpen" @close="deactivateAccountModalIsOpen = false" :isRetailCustomer="isRetailCustomer"/>
    <div>
      <div class="center p-b-40">
        <login-icon />
        <h2 class="header">
          {{ $I18n.t('terms_of_service.privacy_compliance.header') }}
        </h2>
        <p class="minim-mobile-text secondary-font large">
          {{  $I18n.t('terms_of_service.privacy_compliance.does_not_meet_requirements.header') }}
        </p>
        <p class="minim-mobile-text secondary-font large m-t-16 m-b-24">
          {{  subheader }}
        </p>
        <p v-if="!allUnumsAreUnmanaged" v-html="$I18n.t('terms_of_service.data_consent.data_revoke_warning_text')"
           :class="`data-revocation-banner m-b-32 ${this.brand}`"
        >
        </p>
      </div>
    </div>

    <div class="center bottom submit-buttons">
      <button
        @click="deactivateAccountModalIsOpen = true"
        class="minim-mobile-primary-button negative-color large m-b-24"
      >
        {{ buttonText }}
      </button>

      <button
        @click="$router.go(-1)"
        class="minim-mobile-primary-button ghost large"
      >
        {{ $I18n.t('go_back') }}
      </button>

      <button
          class="minim-mobile-primary-button shadow-none primary-font small light text-neutral-darker-color m-t-32 flex-container align-center"
          style="width: 279px; background: none"
          @click="openZendesk('privacy_compliance')"
      >
        {{ $I18n.t('contact_support') }}
        <minim-mobile-icon
            iconName="actionable"
            color="neutral-dark"
            size="x-small"
            class="m-l-16"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import LoginIcon from '../../components/shared/login_icon';
import DeactivateAccountModal from '../../components/privacy_compliance/deactivate_account_modal.vue';
import ContactSupportMixin from 'mobile/shared/mixins/contact_support_mixin';

export default {
  mixins: [ContactSupportMixin],
  
  components: {
    'login-icon': LoginIcon,
    'deactivate-account-modal': DeactivateAccountModal
  },

  data() {
    return {
      deactivateAccountModalIsOpen: false
    };
  },

  async mounted(){
    await this.$store.dispatch('LanStore/index');
  },

  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapState('LanStore', ['lans']),
    ...mapGetters({
      allUnumsAreUnmanaged: 'UnumStore/allUnumsAreUnmanaged'
    }),

    isRetailCustomer(){
      return (!this.lans || this.lans.every((lan) => lan.features.includes('retail-isp')));
    },

    subheader(){
      return this.isRetailCustomer ? this.$i18n.t('terms_of_service.privacy_compliance.does_not_meet_requirements.deactivate_account') : this.$i18n.t('terms_of_service.privacy_compliance.does_not_meet_requirements.submit_deactivation_request');
    },

    buttonText(){
      return this.isRetailCustomer ? this.$I18n.t('terms_of_service.privacy_compliance.deactivate_account') : this.$I18n.t('terms_of_service.privacy_compliance.request_deactivation');
    }
  },

  methods: {
    logOut() {
      return this.$store.dispatch('UserStore/logout').then(() => {
        window.app.reload();
      });
    }
  }
};
</script>
