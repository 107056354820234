<template>
  <div class="text-center" style="padding-bottom: 130px;">
    <h2
      class="unum-card-header-text"
      v-html="headerText"
    />
    <p class="minim-mobile-text secondary-font medium text-neutral-lightest-color m-t-12">
      {{ $I18n.t('mobile.components.unum_card.please_power_cycle', { hardware_type: hardwareType(primaryUnum.hardware_kind_id, true).toLowerCase() }) }}
    </p>
    <router-link
      tag="button"
      class="minim-mobile-primary-button small negative-text neutral-lightest-color m-t-24"
      :to="{
        name: 'lan_unum_power_cycle_instructions',
        query: { skip_history: true },
        params: { lanId: $route.params.lanId }
      }"
    >
      {{ $I18n.t('mobile.components.unum_card.learn_how') }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HardwareTypeMixin from 'mobile/shared/mixins/hardware_type_mixin';

export default {
  mixins: [HardwareTypeMixin],

  computed: {
    ...mapGetters('UnumStore', ['primaryUnum']),

    headerText() {
      return this.$I18n.t('mobile.components.unum_card.network_offline');
    }
  }
};
</script>
