<template>
  <div v-if="disabled">
    <slot />
  </div>
  <div v-else>
    <div class="onboarding-cancel-button-container">
      <div class="cancel-btn-content-wrapper">
        <slot />

        <div class="cancel-btn">
          <div class="relative">
            <div :class="['upper-section', this.currentPlatform.toLowerCase() === 'ios' ?  'upper-section-top' : '']" />
            <div @click="promptConfirmation" :class="['bottom-section', this.currentPlatform.toLowerCase() === 'ios' ?  'bottom-section-padding' : '']">
              <p class="minim-mobile-text primary-font medium text-neutral-darker-color">
                {{ btnText }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <minim-mobile-modal @close="processConfirmation(false)" :open="modalIsOpen">
      <div class="text-center m-t-12">
        <h4 class="minim-mobile-text primary-font medium m-b-16">
          {{ $I18n.t('mobile.components.confirm_modal.please_confirm') }}
        </h4>
        <div class="minim-mobile-text secondary-font large light m-b-32">
          <p>{{ $I18n.t('mobile.components.cancel_button.are_you_sure') }}</p>
        </div>
        <div class="flex-container align-center justify-center m-b-32">
          <button @click="processConfirmation(true)" class="minim-mobile-primary-button small negative-color m-r-8">
            {{ $I18n.t('yes') }}
          </button>
          <button @click="processConfirmation(false)" class="minim-mobile-primary-button small negative-color ghost m-l-8">
            {{ $I18n.t('no') }}
          </button>
        </div>
      </div>
    </minim-mobile-modal>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState({
      isOnboarding: state => state.OnboardingStore.isOnboarding,
      creatingNewNetwork: state => state.OnboardingStore.creatingNewNetwork,
      fromNewtorkSwitcher: state => state.OnboardingStore.isFromNetworkSwitcher
    }),
    ...mapGetters('NativeInfoStore', ['currentPlatform']),
  },

  props: {
    routeToGoBackTo: {
      type: Object
    },

    tabToGoBackTo: {
      type: String
    },

    btnText: {
      type: String,
      default() {
        return this.$I18n.t('cancel');
      }
    },

    btnClass: {
      type: String,
      default: 'bottom-btn'
    },

    mode: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      modalIsOpen: false,
      disabled: false
    };
  },

  async mounted() {
    // If the user has no existing LANs, we should not allow them to cancel out of onboarding.
    const hasOneLan = await this.$native.exec('get_config_item', 'has_one_lan');
    this.disabled = hasOneLan === false || hasOneLan === 'false';
    if (!this.disabled) {
      this.forceClassOnSlot();
      this.syncCancelButtonAndNativeBackButtonBehavior();
    }
  },

  methods: {
    promptConfirmation() {
      this.modalIsOpen = true;
    },

    processConfirmation(user_confirmed) {
      this.modalIsOpen = false;
      if(user_confirmed) { this.navigateAway(); }
    },

    navigateAway() {
      const lastPath = this.$store.getters['HistoryStore/getPreviousTabPath'];

      if(this.mode === 'backToTab' && this.tabToGoBackTo) {
        const prevRoute = this.$store.getters['HistoryStore/getLastTabPath'](this.tabToGoBackTo);
        if (prevRoute) {
          prevRoute.query.tab_name = this.tabToGoBackTo;
        }
        // If the user is brought to onboarding through the network switcher go back to network switcher
        if(this.fromNewtorkSwitcher){
          this.$router.push({ name : 'add_new_device_path'});
          // If we're still onboarding the gateway go back to the beginning
        } else if (this.creatingNewNetwork) {
          this.$router.push({ name: 'onboarding_begin_path', query: { backRoute: prevRoute } });
        } else if (this.isOnboarding) {
          // If were trying to add a mesh node but still in the initial onboarding
          // flow, go back to add device fork
          this.$router.push({ name: 'add_device_fork_path' });
        } else {
          // If none of the above conditions are true it means we're in the "add mesh extender flow"
          // we should go back to the last page on the given tab.
          this.$router.push(prevRoute.path + `?tab_name=${this.tabToGoBackTo}`);
        }

      } else if(this.mode === 'routerPush' && this.routeToGoBackTo) {
        this.$router.push(this.routeToGoBackTo);
      } else if(this.mode === 'backButton' && lastPath) {
        this.$router.push({ ...lastPath });
      } else {
        console.warn('No condition met for supported cancel button behavior');
      }
    },

    syncCancelButtonAndNativeBackButtonBehavior() {
      if(this.mode === 'routerPush' && this.pathNameToGoBackTo) {
        this.$store.dispatch('HistoryStore/overrideNativeBackButtonPath', { name: this.pathNameToGoBackTo });
      }
    },

    forceClassOnSlot() {
      // Appends a class onto the root element passed into the slot
      $('.cancel-btn-content-wrapper :first').addClass('cancel-btn-main-content');
    }
  }
};
</script>

<style scoped>
.upper-section-top {
  top: -10px !important;
}
.bottom-section-padding {
  padding-top: 0 !important;
}

</style>