import { serializePacket } from './packet';

export default class Emitter {
  /**
   * This class takes in a array of events that will occur in the local window
   * and listens for them and emits to the remote window upon their occurence
   * to fire any remote event listeners for the events.
   *
   * @param {String} namespace - the namespace to emit events in
   * @param {Window} remote_window - the remote window to emit events to
   * @param {Array} events - an array of event names to listen for and emit upon
   */
  constructor(namespace, remote_window, events = []) {
    this.namespace = namespace;
    this.remote_window = remote_window;
    this.events = events;

    this._setupEvents();
  }

  _setupEvents() {
    this.events.forEach(event_name => {
      window.document.addEventListener(event_name, () => {
        this._emitEvent(event_name);
      }, false);
    });
  }

  _emitEvent(event_name) {
    const packet = serializePacket({
      namespace: this.namespace,
      event_name
    });

    this.remote_window.postMessage(packet, '*');
  }
}
