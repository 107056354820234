export default {
  methods: {
    severityClass(severity){
      switch(severity){
      case(5):
        return 'negative';
      case(4):
        return 'negative';
      case(3):
        return 'darker-orange';
      default:
        return 'positive';
      }
    },
  }
};
