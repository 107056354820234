<template>
  <minim-mobile-secondary-button
    class="p-r-0 p-l-0"
    v-if="supportedBrands.includes(brand)"
    @click="open_link_to_store"
    :htmlText="$I18n.t(`mobile.onboarding.purchase_device`, { brand_name: brandNiceName })"
  />
</template>

<script>
import { mapState } from 'vuex';

const SHOP_LINKS_BY_BRAND = {
  minim: 'https://shop.minim.co/',
  motosync: 'https://www.motorolanetwork.com' // TODO: needs to be updated with the Moto Sync shop once it's live
};

export default {
  computed: {
    ...mapState('BrandingStore', ['brand', 'brandNiceName']),
    ...mapState('UserStore', ['user']),

    supportedBrands() {
      return Object.keys(SHOP_LINKS_BY_BRAND);
    }
  },

  methods: {
    open_link_to_store() {
      const link = SHOP_LINKS_BY_BRAND[this.brand];

      if (this.$native.running_on_device) {
        this.$native.exec('open_webview', {
          startUrl: link,
          query: {
            'utm_source': `mobile_${this.user.persona}`
          }
        });
      } else {
        window.open(link);
      }
    },
  }
};
</script>
