<template>
  <form @submit.prevent="sendResetEmail" class="mobile-login-container bg-white" novalidate>
    <div class="w-full">
      <login-icon class="p-b-24" />
      <h2 class="header p-b-16">{{ $I18n.t('devise.reset_password_header') }}</h2>
      <div class="flex-container column justify-between h-full">
        <minim-mobile-text-field
          class="p-b-12"
          :placeholder="$I18n.t('devise.sessions.enter_email')"
          v-model="email"
          :errors="errors.email"
          type="email"
        >
          <span slot="leftMostIcon">
            <minim-mobile-icon
              iconName="envelope-16"
              color="neutral-dark"
              size="x-small"
            />
          </span>
        </minim-mobile-text-field>
      </div>
    </div>

    <div class="flex-container column align-center">
      <button type="submit" class="minim-mobile-primary-button m-b-48">
        {{ $I18n.t('devise.passwords.reset_password') }}
      </button>
      <router-link
        class="p-b-12"
        :to="{ name: 'login_path' }"
      >
        <p class="minim-mobile-text primary-font small light text-neutral-darker-color m-t-2 flex-container align-center">
          {{ $I18n.t('devise.passwords.return_to_sign_in') }}
          <minim-mobile-icon
            iconName="actionable"
            color="neutral-dark"
            size="x-small"
            class="p-l-16"
          />
        </p>
      </router-link>
    </div>
  </form>
</template>

<script>
import analytics from 'mobile/shared/utils/analytics';
import LoginIcon from '../components/shared/login_icon';
import Validators from 'mobile/shared/utils/validators';

export default {
  components: {
    'login-icon': LoginIcon,
  },

  data () {
    return {
      email: '',
      errors: { email: [] },
    };
  },

  methods: {
    async sendResetEmail() {
      this.errors.email = Validators.validateEmail(this.email);

      if(this.errors.email.length > 0) {
        return;
      }

      try {
        await this.$store.dispatch('UserStore/requestPasswordReset', { email: this.email });

        analytics.logEvent('mobile_forgot_password');

        this.$router.push({
          name: 'login_path',
          params: {
            flash: this.$I18n.t('devise.passwords.send_paranoid_instructions_mobile', { enableHtml: true })
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>