<template>
  <pull-to-refresh-container :onRefresh="fetchPageData" @pageRefresh="pageRefresh">
    <!-- Mark was here -->
    <div>
      <portal to="left_header">
        <minim-mobile-icon
          @click="onNetworkSwitcherClick"
          iconName="network-switcher-24"
          color="neutral-darker"
          size="small"
        />
      </portal>
      <portal to="page_title">
        <div class="network-page skeleton-overlay-darker" v-skeleton="loadingLan || loadingUnums">
          <div>
            {{ lanName }}
          </div>
        </div>
      </portal>
      <portal to="right_header">
        <div id="timelineIcon" style="width: 24px; height: 24px;">
          <minim-mobile-icon
            @click="onNetworkSettingClick"
            iconName="nav-settings"
            color="neutral-darker"
            size="small"
          />
        </div>
      </portal>

      <deprecated-banner v-if="brand === 'mca'" />
      <network-configurations-banner :hideNetworkConfigurationsBanner="hideNetworkConfigurationsBanner" showCloseBtn />
      <identifying-devices-banner v-if="lanIsPresent && !allUnumsAreUnmanaged" :lan="lan" />
      <paused-device-banner />

      <unum-card :loadingDataInParent="loadingUnums || loadingDevices" />
      <app-feedback-card triggeredScreen="post_onboarding" />

      <template v-if="!allUnumsAreUnmanaged">
        <access-points-card
          v-if="!primaryUnum.is_bridge"
          :loadingDataInParent="loadingDevices"
        />
        <profile-info-card />
        <security-status-card />
        <content-filters-card v-if="showOldContentFiltersCard" />
        <content-filters-v3-card v-else-if="currentLanSupportsContentFiltering" />
        <speed-test-card
          v-if="!this.supportsFeature('isp_disable_mobile_speedtests')"
          showLinkToSpeedTestsIndex
          :loadingDataInParent="loadingLan || loadingPrimaryUnumHardwareKind"
        />
        <data-use-card />
      </template>
      <ad-card
        v-else-if="!loadingUnums && brand === 'motosync'"
        :headerText="$I18n.t('mobile.components.ad_card.upgrade_to_wifi_6')"
        :buttonText="$I18n.t('mobile.components.ad_card.shop_now')"
        :imgSrc="adCardBackground"
        :showSkeleton="loadingUnums"
      />

      <img
        v-if="showPoweredByMinimLogo"
        class="block m-auto p-t-12"
        style="width: 72px;"
        :src="poweredByMinimLogo"
      >

      <app-update-modal
        :open="showAppUpdateModal"
        :headerText="$I18n.t('mobile.app_update_modal.update_available')"
        :bodyText="$I18n.t('mobile.app_update_modal.update_available_msg')"
        @close="showAppUpdateModal = false"
      />
    </div>
  </pull-to-refresh-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import PullToRefreshContainer from 'mobile/shared/components/utilities/pull_to_refresh_container';
import DataUseCard from '../components/index/data_use_card';
import IdentifyingDevicesBanner from '../components/index/identifying_devices_banner.vue';
import PausedDeviceBanner from '../components/index/paused_device_banner.vue';
import UnumCard from '../components/index/unum_card/index.vue';
import AdCard from '../components/index/ad_card.vue';
import AccessPointsCard from '../components/index/access_points_card';
import SecurityStatusCard from '../components/index/security_status_card.vue';
import ContentFiltersV3Card from '../components/index/content_filters_v3_card';
import ContentFiltersCard from 'mobile/shared/components/content_filters/content_filters_card/index';
import SpeedTestCard from '../components/shared/speed_test_card/index.vue';
import NetworkConfigurationsBanner from '../components/index/network_configurations_banner.vue';
import ProfileInfoCard from '../components/index/profile_info_card.vue';
import DeprecatedBanner from '../components/index/deprecated_banner';
import AppFeedbackCard from '../components/index/app_feedback_card.vue';
import AppUpdateModal from 'mobile/shared/components/modals/app_update_modal.vue';

import PoweredByMinimLogo from '../images/index/minim-powered-logo.png';
import MH7600HeroMobile from '../images/index/mh7600-hero-mobile.jpg';

export default {
  components: {
    'pull-to-refresh-container': PullToRefreshContainer,
    'data-use-card': DataUseCard,
    'identifying-devices-banner': IdentifyingDevicesBanner,
    'paused-device-banner': PausedDeviceBanner,
    'unum-card': UnumCard,
    'ad-card': AdCard,
    'access-points-card': AccessPointsCard,
    'security-status-card': SecurityStatusCard,
    'content-filters-v3-card': ContentFiltersV3Card,
    'content-filters-card': ContentFiltersCard,
    'speed-test-card': SpeedTestCard,
    'network-configurations-banner': NetworkConfigurationsBanner,
    'profile-info-card': ProfileInfoCard,
    'deprecated-banner': DeprecatedBanner,
    'app-update-modal': AppUpdateModal,
    'app-feedback-card': AppFeedbackCard
  },

  data() {
    return {
      lanSwitcherOpen: false,
      fetchingLan: true,
      fetchingDevices: true,
      fetchingPrimaryUnumHardwareKind: true,
      fetchingUnums: true,
      poweredByMinimLogo: PoweredByMinimLogo,
      adCardBackground: MH7600HeroMobile,
      isIdentifying: true,
      hideNetworkConfigurationsBanner: null,
      isPageRefresh: false,
      showAppUpdateModal: false
    };
  },

  async created() {
    this.hideNetworkConfigurationsBanner = JSON.parse((await this.$native.exec('get_config_item', 'hide_config_banner')) || false);
  },

  async mounted() {
    await this.fetchPageData();

    this.$nextTick(async () => {
      const updateAvailable = await this.checkForUpdate();

      if (updateAvailable) {
        this.showAppUpdateModal = true;
      } else {
        this.$store.dispatch('HighlightsStore/loadHighlightsData', this.$router.currentRoute.name);
      }
    });

  },

  beforeDestroy() {
    this.$store.commit('HighlightsStore/setHighlightsData', []);
  },

  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapState('LanStore', ['lans']),
    ...mapState('NativeInfoStore', ['build_info']),
    ...mapState('DeviceStore', ['devices']),
    ...mapGetters({
      lan: 'LanStore/currentLan',
      supportsFeature: 'LanStore/currentLanSupportsFeature',
      currentLanSupportsContentFiltering: 'LanStore/currentLanSupportsContentFiltering',
      currentLanSupportsEDNS0Filtering: 'LanStore/currentLanSupportsEDNS0Filtering',
      currentLanSupportsIPBasedFiltering: 'LanStore/currentLanSupportsIPBasedFiltering',
      primaryUnum: 'UnumStore/primaryUnum',
      unums: 'UnumStore/unums',
      allUnumsAreUnmanaged: 'UnumStore/allUnumsAreUnmanaged',
      getHardwareKind: 'HardwareKindStore/getHardwareKind'
    }),

    showOldContentFiltersCard() {
      if (!this.currentLanSupportsContentFiltering) return false;
      if (this.currentLanSupportsEDNS0Filtering) return false;

      return this.currentLanSupportsIPBasedFiltering;
    },

    showPoweredByMinimLogo() {
      // this boolean statement is essentially the same as saying we are using in app ISP based branding
      return this.brand === 'minim' && this.lan.primary_color;
    },

    lanIsPresent() {
      return Object.keys(this.lan || {}).length > 0;
    },

    primaryUnumHardwareKind() {
      return this.getHardwareKind(this.primaryUnum.hardware_kind_id) || {};
    },

    lanName() {
      return (this.lanIsPresent && this.lan.name) ? this.lan.name : this.primaryUnumHardwareKind.name;
    },

    loadingUnums() {
      return !this.unums.length && this.fetchingUnums;
    },

    loadingDevices() {
      return !this.devices.length && this.fetchingDevices;
    },

    loadingLan() {
      return !this.lanIsPresent && this.fetchingLan;
    },

    loadingPrimaryUnumHardwareKind() {
      return !this.primaryUnumHardwareKind || this.fetchingPrimaryUnumHardwareKind;
    }
  },

  methods: {
    async fetchPageData() {
      await Promise.all([
        this.fetchDeviceData(),
        this.fetchLan(),
        this.fetchUnumData()
      ]);
    },

    async fetchLan() {
      await this.$store.dispatch('LanStore/show');
      this.fetchingLan = false;
    },

    async fetchDeviceData() {
      await Promise.all([
        this.$store.dispatch('DeviceConnectionReportStore/fetchDeviceConnectionReport'),
        this.$store.dispatch('DeviceStore/index')
      ]);

      this.fetchingDevices = false;
    },

    async fetchUnumData() {
      await this.$store.dispatch('UnumStore/index');
      await this.$store.dispatch('HardwareKindStore/show', this.primaryUnum.hardware_kind_id);
      this.fetchingUnums = false;
      this.fetchingPrimaryUnumHardwareKind = false;
    },

    pageRefresh(event) {
      this.isPageRefresh = event;
    },

    clickLanSwitcher() {
      if (!this.isPageRefresh) this.lanSwitcherOpen = true;
    },

    onNetworkSettingClick() {
      this.$router.push({ name: 'network_path', params: { lanId: this.lan.id }});
    },

    onNetworkSwitcherClick() {
      this.$router.push({ name: 'network_switcher_path', query: { tab_name: 'router' } });
    },

    async checkForUpdate() {
      let isUpdateAvailable = await this.$native.exec('check_for_app_update');
      const ignored_update  = await this.$native.exec('fetch_preference', `ignored_update_${this.build_info.version}`);
      return (isUpdateAvailable === true && !ignored_update);
    }
  }
};
</script>
