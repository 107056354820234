<template>
  <minim-mobile-modal :open="open" @close="close">
    <h2 class="minim-mobile-text primary-font medium centered">
      {{ header }}
    </h2>
    <p class="minim-mobile-text secondary-font large centered m-t-32 p-b-32" v-html="text">
    </p>

    <div class="flex-container justify-center">
      <button @click="close"
        class="minim-mobile-primary-button negative-color ghost m-r-8 m-l-16"
      >
        {{ $I18n.t('no') }}
      </button>
      <button
        class="minim-mobile-primary-button negative-color m-r-16 m-b-8"
        @click="deactivateAccount"
      >
        {{ $I18n.t('yes') }}
      </button>
    </div>

  </minim-mobile-modal>
</template>
<script>

import minim_api from 'mobile/shared/utils/minim_api';
import analytics from 'mobile/shared/utils/analytics';

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    isRetailCustomer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    header(){
      return this.isRetailCustomer ? this.$I18n.t('terms_of_service.privacy_compliance.deactivate_account') : this.$I18n.t('terms_of_service.privacy_compliance.request_deactivation');
    },
    text(){
      return this.isRetailCustomer ? this.$I18n.t('terms_of_service.privacy_compliance.deactivate_account_modal_text') : this.$I18n.t('terms_of_service.privacy_compliance.deactivation_request_confirmation');
    }
  },

  methods: {
    close() {
      analytics.logEvent('user_chose_not_to_delete_account');
      this.$emit('close');
    },

    async deactivateAccount(){
      const result = (await minim_api.post('api/v1/account/delete_user_data')).data;

      analytics.logEvent('user_deleted_account');
      this.$router.push({
        name: 'account_deactivated_path',
        params: {cancel_deadline_at: result.cancel_deadline_at}
      });
    }
  }
};
</script>
