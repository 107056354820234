const routes = [
  {
    path: 'lans/:lanId/people',
    component: () => import(/* webpackChunkName: "people" */ './views/index.vue'),
    name: 'people_path',
    meta: { tab_root: true }
  },
  {
    path: 'lans/:lanId/people/new',
    component: () => import(/* webpackChunkName: "people" */ './views/new.vue'),
    name: 'new_person_path'
  },
  {
    path: 'lans/:lanId/people/:id',
    component: () => import(/* webpackChunkName: "people" */ './views/show.vue'),
    name: 'person_path'
  },
  {
    path: 'lans/:lanId/people/:id/settings',
    component: () => import(/* webpackChunkName: "people" */ './views/settings/index.vue'),
    name: 'person_settings_path'
  },
  {
    path: 'lans/:lanId/people/:id/settings/select_devices',
    component: () => import(/* webpackChunkName: "people" */ './views/settings/select_devices.vue'),
    name: 'person_select_devices_path'
  },
  {
    path: 'lans/:lanId/people/:id/usage/:personName',
    component: () => import(/* webpackChunkName: "people" */ './views/usage/index.vue'),
    name: 'useage_by_profile_path'
  },
  {
    path: '/lans/:lanId/people/:personId/restrictions/:kind',
    component: () => import(/* webpackChunkName: "people" */ './views/restrictions/index.vue'),
    name: 'person_restrictions_path'
  },
  {
    path: '/lans/:lanId/people/:personId/restrictions/:kind/new',
    component: () => import(/* webpackChunkName: "people" */ './views/restrictions/new.vue'),
    name: 'new_person_restriction_path'
  },
  {
    path: '/lans/:lanId/people/:personId/restrictions/:id/edit',
    component: () => import(/* webpackChunkName: "people" */ './views/restrictions/edit.vue'),
    name: 'edit_person_restriction_path'
  },
  {
    path: '/lans/:lanId/people/:personId/restrictions/:id/settings',
    component: () => import(/* webpackChunkName: "people" */ './views/restrictions/settings.vue'),
    name: 'person_restriction_settings_path',
    query: { skip_history: true }
  },
  {
    path: '/lans/:lanId/people/:personId/content_filters/recently_blocked',
    component: () => import(/* webpackChunkName: "people" */ './views/content_filters/recently_blocked.vue'),
    name: 'person_content_filters_recently_blocked_path'
  },
  {
    path: 'lans/:lanId/people/:personId/content_filters/management',
    component: () => import(/* webpackChunkName: "people" */ './views/content_filters/management.vue'),
    name: 'person_content_filters_management_path'
  },
  {
    path: 'lans/:lanId/people/:personId/content_filters_v3/edit',
    component: () => import(/* webpackChunkName: "people" */ './views/content_filters_v3/edit.vue'),
    name: 'person_content_filters_v3_edit_path'
  },
  {
    path: 'lans/:lanId/people/:personId/content_filters_v3/recently_blocked',
    component: () => import(/* webpackChunkName: "people" */ './views/content_filters_v3/recently_blocked.vue'),
    name: 'person_content_filters_v3_recently_blocked_path'
  },
  {
    path: 'lans/:lanId/people/:personId/content_filters_v3/add_domain_to_list',
    component: () => import(/* webpackChunkName: "people" */ './views/content_filters_v3/add_domain_to_list.vue'),
    name: 'person_content_filters_v3_add_domain_to_list_path'
  }
];

export default routes;
