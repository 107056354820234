<template>
  <div v-if="brand !== 'motosync'" class="logo-header minim" />
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState('BrandingStore', ['brand']),
  }
};
</script>
