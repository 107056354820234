<template>
  <div class="mobile-unum-card-container minim-mobile-card unpadded overflow-hidden">
    <header-section
      :showSkeleton="showSkeleton"
      :siteStatus="siteStatus"
      :estimatedTime="estimatedTime"
    />

    <div class="semi-circle-container">
      <div class="semi-circle" />
    </div>
    <unum-circle
      :unum="primaryUnum"
      isLinkToShowPage
      :class="unumCircleClass"
    />

    <div class="unum-circles-container" v-if="showDeviceDetailsBtn">
      <router-link
        class="minim-mobile-primary-button primary-ghost-button"
        tag="button"
        :to="deviceDetailsUrl"
        data-test-id="onboarding_skip_add_extender_btn"
        v-skeleton="showSkeleton"
      >
        {{ $I18n.t('mobile.components.unum_card.device_details') }}
      </router-link>
    </div>
    <div class="unum-circles-container" v-else>
      <template v-if="showSkeleton">
        <div
          v-for="i in [1, 2, 3]"
          :key="`unum-circle-skeleton-${i}`"
          class="flex-item"
        >
          <div
            class="skeleton"
            style="width: 72px; height: 72px; border-radius: 50%;"
          />
          <div class="skeleton m-t-16" style="height: 20px; width: 72px;" />
        </div>
      </template>

      <template v-else>
        <unum-circle
          v-for="unum in sortingNonPrimaryUnums(nonPrimaryUnums)"
          :key="unum.id"
          :unum="unum"
          isLinkToShowPage
          size="small"
          class="flex-item"
        />

        <router-link
          v-if="!allUnumsAreUnmanaged"
          tag="div"
          class="device-count-circle flex-item"
          :to="{ name: 'lan_devices_path' }"
        >
          <div class="device-count-button bg-primary-color">
            <minim-mobile-icon
              iconName="computers"
              size="medium-large"
              color="neutral-lightest"
            />
          </div>
          <p class="device-count-text">
            {{ deviceCountText }}
          </p>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import HeaderSection from './header_section';
import UnumStatusMixin from 'mobile/shared/mixins/unum_status_mixin';
import UnumCircle from '../../shared/unum_circle';
import Bugsnag from '@bugsnag/js';

export default {
  mixins: [UnumStatusMixin],

  components: {
    'unum-circle': UnumCircle,
    'header-section': HeaderSection
  },

  props: {
    loadingDataInParent: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      siteStatus: 'none',
      estimatedTime: ''
    };
  },

  async mounted() {
    try {
      await this.checkSiteStatus();
    } catch (err) {
      Bugsnag.notify(err);
    }
  },

  methods: {
    sortingNonPrimaryUnums(nonPrimaryUnum) {
      if(nonPrimaryUnum.length > 0) {
        nonPrimaryUnum.sort((a,b) => (a.is_unmanaged) - (b.is_unmanaged) || a.name.localeCompare(b.name));
      }
      return nonPrimaryUnum;
    },

    async checkScheduledMaintenances() {
      let scheduledMaintenanceResponse = (await axios.get('https://status.minim.co/api/v2/scheduled-maintenances/active.json'));
      const { scheduled_maintenances } = scheduledMaintenanceResponse.data;

      if (scheduledMaintenanceResponse.status == 200 && scheduled_maintenances && scheduled_maintenances.length > 0) {
        this.siteStatus = 'scheduled_maintenance';
        const scheduled_until = scheduled_maintenances[0].scheduled_until;
        const istoday = moment(scheduled_until).isSame(moment(), this.$I18n.t('mobile.components.unum_card.day'));
        if(istoday) {
          this.estimatedTime = moment(scheduled_until).format('h:mm A') + ' ' + this.$I18n.t('mobile.components.unum_card.today');
        }else {
          this.estimatedTime = moment(scheduled_until).format('MMMM DD, YYYY [at] h:mm A');
        }
        return true;
      }
      return false;
    },

    async checkSiteStatus() {
      const isScheduledMaintenance = await this.checkScheduledMaintenances();

      if(!isScheduledMaintenance) {
        let summaryResponse = (await axios.get('https://status.minim.co/api/v2/summary.json'));
        if(summaryResponse.status == 200) {
          const { status } = summaryResponse.data;
          this.siteStatus = status.indicator;
        }
      }
    }
  },

  computed: {
    ...mapState('DeviceStore', ['devices']),
    ...mapGetters('UnumStore', [
      'unums',
      'primaryUnum',
      'nonPrimaryUnums',
      'offlineUnums',
      'rebootingUnums',
      'updatingUnums',
      'allUnumsAreUnmanaged'
    ]),
    ...mapGetters({
      lan: 'LanStore/currentLan'
    }),

    showSkeleton() {
      return !this.unums.length;
    },

    deviceCountText() {
      let filterNonUnumDevices = this.devices.filter(device => !device.is_unum);

      let devicesCount = filterNonUnumDevices.length;
      return devicesCount === 1
        ? this.$I18n.t('mobile.components.unum_card.single_device')
        : this.$I18n.t('mobile.components.unum_card.devices_count', { devices_count: devicesCount });
    },

    showDeviceDetailsBtn() {
      return (this.unums.length === 1 && this.unums[0].is_unmanaged);
    },

    deviceDetailsUrl() {
      return { name: 'lan_unum_show_path', params: { lanId: this.lan.id, id: this.unums.length ? this.unums[0].id : null } };
    },

    unumCircleClass() {
      if (['scheduled_maintenance', 'major', 'critical'].includes(this.siteStatus)) {
        return 'circle-position-bottom';
      } else {
        return 'circle-position-default';
      }
    },
  }
};
</script>
