import NetworkIndex from './views/index.vue';
import NetworkSwitcherIndex from './views/network_switcher.vue';

const routes = [
  {
    path: 'lans/:lanId',
    component: NetworkIndex,
    name: 'lan_path',
    meta: {
      tab_root: true,
      showLogoHeader: false,
      hideNetworkOfflineBanner: true
    }
  },
  {
    path: 'lans/:lanId/unums/power_cycle_instructions',
    component: () => import(/* webpackChunkName: "network" */ './views/unums/power_cycle_instructions.vue'),
    name: 'lan_unum_power_cycle_instructions',
    meta: { layout: 'onboarding' }
  },
  {
    path: '/lans/:lanId/unums/:id',
    component: () => import(/* webpackChunkName: "network" */ './views/unums/show.vue'),
    name: 'lan_unum_show_path'
  },
  {
    path: '/lans/:lanId/unums/:id/settings',
    component: () => import(/* webpackChunkName: "network" */ './views/unums/settings.vue'),
    name: 'lan_unum_settings_path'
  },
  // Beware changing this back to accessPointId
  // If we're still allowing users to delete APs,
  // changing to AP id can break everything if the
  // User deletes the ap id used in the uri.
  // Becuase of this we have to use SSIDs.
  // And since there are blank ssids, we have to deal with the ssid be undefined
  {
    path: '/lans/:lanId/network/:uriSsid',
    component: () => import(/* webpackChunkName: "network" */ './views/access_points/show.vue'),
    name: 'access_points_show_path',
    props: true
  },
  {
    path: '/lans/:lanId/network/:ssid/settings',
    component: () => import(/* webpackChunkName: "network" */ './views/access_points/settings.vue'),
    name: 'access_point_settings_path',
    props: true
  },
  {
    path: '/lans/:lanId/access_points/new',
    component: () => import(/* webpackChunkName: "network" */ './views/access_points/add_access_point.vue'),
    name: 'access_point_new_path'
  },
  {
    path: '/lans/:lanId/access_points/:ssid/type',
    component: () => import(/* webpackChunkName: "network" */ './views/access_points/network_type.vue'),
    name: 'access_points_type_path',
    props: true
  },
  {
    path: '/lans/:lanId/bulk_incident/:device_uuid',
    component: () => import(/* webpackChunkName: "network" */ './views/security/bulk_incident.vue'),
    name: 'bulk_incident_path',
    props: true
  },
  {
    path: '/lans/:lanId/bulk_rogue_ap',
    component: () => import(/* webpackChunkName: "network" */ './views/security/bulk_rogue_ap.vue'),
    name: 'bulk_rogue_ap_path',
    props: true
  },
  {
    path: '/lans/:lanId/bulk_unknown_device',
    component: () => import(/* webpackChunkName: "network" */ './views/security/bulk_unknown_device.vue'),
    name: 'bulk_unknown_device_path',
    props: true
  },
  {
    path: '/lans/:lanId/security',
    component: () => import(/* webpackChunkName: "network" */ './views/security/index.vue'),
    name: 'lan_security_path'
  },
  {
    path: '/lans/:lanId/security_incident/:uuid',
    component: () => import(/* webpackChunkName: "network" */ './views/security/incident.vue'),
    name: 'security_incident_path',
    props: true
  },
  {
    path: '/lans/:lanId/security_incident/unknown_device/:deviceUUID',
    component: () => import(/* webpackChunkName: "network" */ './views/security/unknown_device.vue'),
    name: 'unknown_device_path',
    props: true
  },
  {
    path: 'lans/:lanId/unums/:unumId/samba_device',
    component: () => import(/* webpackChunkName: "network" */ './views/samba_device_show.vue'),
    name: 'samba_device_path'
  },
  {
    path: 'lans/:lanId/devices',
    component: () => import(/* webpackChunkName: "network" */ './views/devices/index.vue'),
    name: 'lan_devices_path'
  },
  {
    path: 'lans/:lanId/devices/:id',
    component: () => import(/* webpackChunkName: "network" */ './views/devices/show.vue'),
    name: 'lan_device_path'
  },
  {
    path: 'lans/:lanId/devices/:id/settings',
    component: () => import(/* webpackChunkName: "network" */ './views/devices/settings.vue'),
    name: 'lan_device_settings_path'
  },
  {
    path: 'lans/:lanId/devices/:id/ping_floods',
    component: () => import(/* webpackChunkName: "network" */ './views/devices/ping_floods.vue'),
    name: 'lan_device_ping_floods_path'
  },
  {
    path: 'lans/:lanId/devices/:id/traffic_graphs',
    component: () => import(/* webpackChunkName: "network" */ './views/devices/traffic_graphs.vue'),
    name: 'lan_device_traffic_graphs_path'
  },
  {
    path: 'lans/:lanId/devices/:id/signal_strength_and_bandwidth_graphs',
    component: () => import(/* webpackChunkName: "network" */ './views/devices/signal_strength_and_bandwidth_graphs.vue'),
    name: 'lan_device_signal_strength_and_bandwidth_graphs_path'
  },
  {
    path: 'lans/:lanId/content_filters/recently_blocked',
    component: () => import(/* webpackChunkName: "network" */ './views/content_filters/recently_blocked.vue'),
    name: 'lan_content_filters_recently_blocked_path'
  },
  {
    path: 'lans/:lanId/content_filters/add_domain_to_list/:listKind',
    component: () => import(/* webpackChunkName: "network" */ './views/content_filters/add_domain_to_list.vue'),
    name: 'lan_content_filters_add_domain_to_list_path'
  },
  {
    path: 'lans/:lanId/content_filters/domain_lists/:listKind',
    component: () => import(/* webpackChunkName: "network" */ './views/content_filters/domain_lists.vue'),
    name: 'lan_content_filters_domain_lists_path'
  },
  {
    path: 'lans/:lanId/content_filters_v3/edit',
    component: () => import(/* webpackChunkName: "network" */ './views/content_filters_v3/edit.vue'),
    name: 'lan_content_filters_v3_edit_path'
  },
  {
    path: 'lans/:lanId/content_filters_v3/search',
    component: () => import(/* webpackChunkName: "network" */ './views/content_filters_v3/search.vue'),
    name: 'lan_content_filters_v3_search_path'
  },
  {
    path: 'lans/:lanId/content_filters_v3/settings',
    component: () => import(/* webpackChunkName: "network" */ './views/content_filters_v3/settings.vue'),
    name: 'lan_content_filters_v3_settings_path'
  },
  {
    path: 'lans/:lanId/speed_tests',
    component: () => import(/* webpackChunkName: "network" */ './views/speed_tests/index.vue'),
    name: 'speed_tests_path'
  },
  {
    path: 'lans/:lanId/stacked_bandwidth_graph',
    component: () => import(/* webpackChunkName: "network" */ './views/stacked_bandwidth_graph.vue'),
    name: 'stacked_bandwidth_graph_path'
  },
  {
    path: '/lans/:lanId/lan_events',
    component: () => import(/* webpackChunkName: "network" */ './views/timeline/index.vue'),
    name: 'timeline_path',
    meta: {
      hideNetworkOfflineBanner: true
    }
  },
  {
    path: '/network_switcher',
    component: NetworkSwitcherIndex,
    name: 'network_switcher_path',
    meta: {
      hideNetworkOfflineBanner: true
    }
  }
];

export default routes;