<template>
  <div v-skeleton="{showSkeleton, styles: { borderRadius: '10px', marginRight: '24px', marginLeft: '20px'}, classes: ['skeleton-bg-primary']}" class="minim-mobile-tab-picker">
    <div
      v-for="(tab, tabName) in tabs"
      :class="tabClass(tab, tabName)"
      :key="`mobile-tab-picker-tab-${tabName}`"
      @click="$emit('tabSelected', tabName)"
    >
      <p class="minim-mobile-text secondary-font medium">
        {{ tab.displayName }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Object,
      required: true
    },

    currentTab: {
      type: String,
      required: true
    },

    showSkeleton: {
      type: Boolean
    }
  },

  methods: {
    tabClass(tab, tabName) {
      let classes = ['tab'];

      if (this.isSelectedTab(tabName)) {
        classes.push('selected');

        const color = tab.selectedColor || 'primary';
        classes.push(`${color}-color`);
      }

      return classes;
    },

    isSelectedTab(tabName) {
      return tabName === this.currentTab;
    }
  }
};
</script>
