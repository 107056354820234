<template>
  <div class="minim-mobile-text-field-container">
    <div class="flex-container align-center">
      <label v-if="label" class="minim-mobile-text-field-label">{{ label }}</label>
      <minim-mobile-icon
        v-if="toolTipText"
        size="x-small"
        iconName="info"
        color="neutral-lightest"
        class="info-icon"
        style="outline: none"
        :name="componentId"
      />
      <tippy
        :to="componentId"
        trigger="click"
        theme="minim primary left"
        arrow
      >
        <span class="minim-mobile-text secondary-font medium text-neutral-lightest-color">{{ toolTipText }}</span>
      </tippy>
    </div>
    <div>
      <div v-if="$slots.leftMostIcon">
        <slot name="leftMostIcon" />
      </div>
      <textarea
        :class="{
          'minim-mobile-multiline-text-field': true,
          'disabled': mode === 'disabled',
          'highlighted bg-primary-color': mode === 'highlighted',
          'error-state': !(errors === undefined || errors.length == 0) || hasErrors
        }"
        v-skeleton="showSkeleton"
        @change="handleInput"
        @input="handleInput"
        @click="handleInputClick"
        :ref="name"
        :value="value"
        :placeholder="placeholder"
        :disabled="mode === 'disabled'"
        :autocapitalize="autocapitalize"
        :autocomplete="autocomplete"
        :autocorrect="autocorrect"
        :spellcheck="spellcheck"
        :data-test-id="testId"
        :rows="rows"
      />
      <slot name="rightMostIcon">
        <span>
          <minim-mobile-icon
            v-if="showClearButton"
            @click="$emit('clear')"
            iconName="close"
            color="neutral-darker"
            size="small"
          />
          <minim-mobile-icon
            v-if="iconName"
            @click="handleIconClick"
            :iconName="iconName"
            size="x-small"
            :color="iconColor"
          />
        </span>
      </slot>
    </div>
    <template v-if="errors">
      <p
        v-for="(error, index) in errors"
        class="minim-mobile-text secondary-font medium centered text-negative-color p-t-16"
        :key="`error-${index}`"
      >
        {{ error }}
      </p>
    </template>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters } from 'vuex';

let renderCount = 0;

export default {
  props: {
    label: {
      type: String,
      required: false,
    },

    value: {
      type: String,
      required: true
    },

    iconName: {
      type: String,
      required: false,
    },

    mode: {
      type: String,
      required: false,
      validator(value) {
        return value ? ['disabled', 'highlighted'].includes(value) : true;
      }
    },

    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    hasErrors: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Array,
    },

    showClearButton: {
      type: Boolean,
      default: false
    },

    autocapitalize: {
      type: String,
      default: 'off'
    },

    autocomplete: {
      type: String,
      default: 'off'
    },

    autocorrect: {
      type: String,
      default: 'off'
    },

    spellcheck: {
      type: String,
      default: 'false'
    },

    type: {
      type: String,
      default: 'text'
    },

    testId: {
      type: String,
      default: ''
    },

    toolTipText: {
      type: String,
      default: ''
    },

    showSkeleton: {
      type: Boolean,
      default: false
    },

    rows: {
      type: String,
      default: '5'
    },

    name: {
      type: String,
      default: 'textarea'
    },

    enableScroll: {
      type: Boolean,
      default: false
    },
  },

  data(){
    renderCount += 1;

    return {
      showToolTipText: false,
      componentId: `minim-mobile-text-field-${renderCount}` // This data value is used to create a unique value for vue-tippy, ensuring each tooltip is in the correct position when there are multiple tooltips on the page
    };
  },

  methods: {
    handleInput($event) {
      this.$emit('input', $event.target.value);
    },

    handleIconClick() {
      this.$emit('iconClick');
    },

    // Used via refs in parent components to remove focus from the input programmatically
    blurInput() {
      this.$nextTick(() => { this.$refs[this.name].blur(); });
    },

    toggleToolTipText(){
      this.showToolTipText = !this.showToolTipText;
    },

    handleInputClick() {
      if(this.enableScroll && this.currentPlatform.toLowerCase() === 'android') {
        const ref = this.$refs[this.name];
        if(ref) {
          const pageHeight  = $(window).height();
          const inputOffset = $(ref).offset().top;
          var inputHeight = $(ref).height();
          if((pageHeight / 2) < inputOffset) {
            $('#app-body').animate({ scrollTop: pageHeight - inputHeight }, 600);
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters('NativeInfoStore', ['currentPlatform']),

    iconColor() {
      switch(this.mode) {
      case 'highlighted':
        return 'white';
      case 'disabled':
        return 'neutral-darker';
      default:
        return 'black';
      }
    },

    hasLeftIcon() {
      return !!this.$slots.leftMostIcon;
    }
  }
};
</script>
