<template>
  <div class="flex-container justify-between align-baseline">
    <div class="flex-container align-center grow-1">
      <h2 class="minim-mobile-text secondary-font less-bold text-neutral-darkest-color large">
        {{ $I18n.t('mobile.access_points.card.title') }}
      </h2>
      <minim-mobile-icon
        class="informational-button"
        style="top: 3px;"
        iconName="info"
        size="x-small"
        name="ap-info-tip"
      />
      <info-tippy
        to="ap-info-tip"
        ref="ap-info-tip"
        trigger="click"
        :textAlignLeft="true"
      >
        <span class="minim-mobile-text secondary-font medium text-neutral-lightest-color">
          {{$I18n.t('mobile.access_points.card.info')}}
        </span>
      </info-tippy>
    </div>
    <p
      v-if="hardwareKind.max_aps_per_radio > 1 || currentLanSupportsFeature('guest_network')"
      class="minim-mobile-text text-primary-color"
      @click="addNetwork"
      data-test-id="mobile-add-network-button"
    >
      {{ $I18n.t('mobile.access_points.card.add_network_link') }}
    </p>

    <lan-offline-modal :open="modalOpenStatuses.lanOfflineModal" @close="toggleModal('lanOfflineModal')" />
    <max-aps-modal :open="modalOpenStatuses.maxApsModal" @close="toggleModal('maxApsModal')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LanOfflineModal from '../../access_points/modals/lan_offline_modal';
import MaxApsModal from '../../access_points/modals/max_aps_modal';
import InfoTippy from 'mobile/shared/components/buttons/info_tippy.vue';

export default {
  components: {
    'lan-offline-modal': LanOfflineModal,
    'max-aps-modal': MaxApsModal,
    'info-tippy': InfoTippy
  },

  props: {
    accessPointId: {
      type: Boolean
    },
  },

  data() {
    return {
      modalOpenStatuses: {
        maxApsModal: false,
        lanOfflineModal: false
      }
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.checkNewOnboardedDevice();
    });
  },

  computed: {
    ...mapGetters({
      currentLanSupportsFeature: 'LanStore/currentLanSupportsFeature',
      currentLan: 'LanStore/currentLan',
      getAccessPoints: 'AccessPointStore/getAccessPoints',
      primaryUnum: 'UnumStore/primaryUnum',
      getRadios: 'RadioStore/getRadios',
      canCreateAp: 'RadioStore/canCreateAp',
      getHardwareKind: 'HardwareKindStore/getHardwareKind'
    }),

    canCreate() {
      return this.getRadios.every((r) => {
        return this.canCreateAp(r.id);
      });
    },

    hardwareKind() {
      return this.getHardwareKind(this.primaryUnum.hardware_kind_id) || {};
    },
  },

  methods: {
    addNetwork() {
      if (!this.currentLan.is_online) {
        this.toggleModal('lanOfflineModal');
      } else if (!this.canCreate) {
        this.toggleModal('maxApsModal');
      } else {
        this.$router.push({ name: 'access_point_new_path', params: { lanId: this.$route.params.lanId }});
      }
    },

    toggleModal(key) {
      this.modalOpenStatuses[key] = !this.modalOpenStatuses[key];
    },

    async checkNewOnboardedDevice() {
      let showWifiNetworkInfo = await window.native.exec('fetch_preference', 'showWifiNetworkInfo');
      showWifiNetworkInfo = JSON.parse(showWifiNetworkInfo || false);
      if(showWifiNetworkInfo === true) {
        this.$refs['ap-info-tip'].$refs.tippy.tip.show();
        await window.native.exec('set_preference', { key: 'showWifiNetworkInfo', value: false });
      }
    }
  }
};
</script>

<style scoped>
  .minim-icon-wireless-device {
    top: 5px;
    padding-right: 8px;
  }
</style>
