<template>
  <div @click="handleClick" :class="{ 'minim-mobile-checkbox': true, 'has-error': hasError, selected }">
    <div class="check bg-primary-color" />
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    },

    hasError: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick() {
      // Emit an event that the checkbox has been toggled for the parent component to handle
      this.$emit('toggle');
    }
  }
};
</script>