<template>
  <div class="minim-mobile-card data-use-card">
    <div>
      <div class="flex-container justify-between m-b-32">
        <div class="flex-container align-center grow-1" v-skeleton="showSkeleton" >
          <h3 class="minim-mobile-text primary-font small">
            {{ $I18n.t('mobile.components.data_use_card.title') }}
          </h3>
          <minim-mobile-icon
            class="informational-button"
            style="top: 3px;"
            iconName="info"
            size="x-small"
            name="data-usage-info-tip"
          />
          <info-tippy
            to="data-usage-info-tip"
            ref="data-usage-info-tip"
            trigger="click"
            :textAlignLeft="true"
          >
            <span class="minim-mobile-text secondary-font medium text-neutral-lightest-color">
              {{ $I18n.t('mobile.components.data_use_card.info') }}
            </span>
          </info-tippy>
        </div>
        <minim-mobile-card-link
          :showSkeleton="showSkeleton"
          :text="$I18n.t('history')"
          :to="{
            name: 'stacked_bandwidth_graph_path',
            params: {
              lanId: $route.params.lanId,
            }
          }"
        />
      </div>

      <div class="homeview-buttons">
        <button
          v-for="tab in ['devices', 'profiles']"
          v-skeleton="showSkeleton"
          @click="setActiveTab(tab)"
          :class="[isActiveTab(tab) ? 'bg-primary-color' : 'unselected']"
          class="switch-btn"
          :key="tab"
        >
          {{ $I18n.t(`mobile.components.data_use_card.${tab}_title`) }}
        </button>
      </div>

      <ul v-if="showSkeleton" class="p-0">
        <li v-for="i in 3" :key="`top-sites-skeleton-${i}`" class="flex-container p-0 m-b-24 m-t-24">
          <div class="skeleton rounded" style="width: 40px; height: 40px; " />
          <div class="skeleton m-l-12" style="width: 155px; height: 40px;" />
          <div class="skeleton m-l-auto" style="width: 86px; height: 40px;" />
        </li>
      </ul>

      <div v-else>
        <div v-if="hasDeviceData && isActiveTab('devices')">
          <ul class="p-0">
            <li
              class="m-b-24 m-t-24"
              v-for="device in top_devices"
              :key="device.device_uuid"
            >
              <div class="flex-container align-center justify-between">
                <div class="flex-container align-start">
                  <minim-mobile-icon :iconName="device.category || 'uncategorized'" color="primary" size="medium-large" />

                  <div class="m-l-10">
                    <p class="minim-mobile-text secondary-font large line-truncate" :style="{'max-width': '150px'}">
                      {{ device.name || $I18n.t('unknown') }}
                    </p>
                    <p class="minim-mobile-text secondary-font small text-neutral-dark-color" :style="{'max-width': '100px'}">
                      {{ device.person_name || $I18n.t('unassigned') }}
                    </p>
                  </div>
                </div>

                <p class="minim-mobile-text secondary-font text-neutral-dark-color nowrap">
                  {{ totalBandwidth(device) }}
                </p>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="hasPeopleData && isActiveTab('profiles')">
          <ul class="p-0">
            <li
              class="m-b-24 m-t-24"
              v-for="person in top_people"
              :key="person.person_id"
            >
              <div class="flex-container align-center justify-between">
                <div class="flex-container align-center">
                  <minim-mobile-icon iconName="profile" color="primary" size="medium-large" />
                  <div class="m-l-10">
                    <p class="minim-mobile-text secondary-font large line-truncate">
                      {{ person.name || $I18n.t('unassigned') }}
                    </p>
                  </div>
                </div>

                <p class="minim-mobile-text secondary-font text-neutral-dark-color nowrap">
                  {{ totalBandwidth(person) }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="!showSkeleton && !hasDeviceData && activeTab === 'devices'" class="text-center">
        <p class="minim-mobile-text secondary-font large centered text-neutral-darker-color p-48 m-t-48">
          {{ $I18n.t('mobile.components.data_use_card.no_devices') }}
        </p>
      </div>

      <div v-if="!showSkeleton && !hasPeopleData && activeTab === 'profiles'" class="text-center">
        <p class="minim-mobile-text secondary-font large centered text-neutral-darker-color p-t-48 p-b-32 p-r-24 p-l-24">
          {{ noPeopleDataText }}
        </p>

        <router-link
          v-if="(people || {}).length"
          tag="button"
          class="minim-mobile-primary-button bg-primary-color"
          :to="{
            name: 'people_path',
            params: { lanId: $route.params.lanId },
            query: { initialize_with_from_route: true }
          }"
        >
          {{ $I18n.t('mobile.components.data_use_card.go_to_profiles') }}
        </router-link>
        <router-link
          v-else
          tag="button"
          class="minim-mobile-primary-button bg-primary-color"
          :to="{
            name: 'new_person_path',
            params: { lanId: $route.params.lanId },
            query: { initialize_with_from_route: true }
          }"
        >
          {{ $I18n.t('mobile.components.data_use_card.create_profile') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Minim_mobile_card_link from '../../../../shared/components/global/minim_mobile_card_link.vue';
import conversion_helper from 'mobile/shared/utils/conversion_helper';
import InfoTippy from 'mobile/shared/components/buttons/info_tippy.vue';

export default {
  data() {
    return {
      activeTab: 'devices',
      loading: true,
    };
  },

  components: {
    'minim-mobile-card-link': Minim_mobile_card_link,
    'info-tippy': InfoTippy
  },

  methods: {
    setActiveTab(tab){
      this.activeTab = tab;
    },

    isActiveTab(tab) {
      return this.activeTab === tab;
    },

    totalBandwidth(resource) {
      return conversion_helper.roundBytes(resource.bandwidth_usage);
    }
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch('PersonStore/index'),
      this.$store.dispatch('LanStore/fetchBandwidthLeaderboard'),
    ]);

    this.loading = false;
  },

  computed: {
    ...mapState('LanStore', ['top_people', 'top_devices']),
    ...mapState('PersonStore', ['people']),

    showSkeleton() {
      return (this.loading && (!this.hasDeviceData && !this.hasPeopleData));
    },

    hasDeviceData(){
      return (this.top_devices && this.top_devices.length);
    },

    hasPeopleData() {
      return (this.top_people && this.top_people.length);
    },

    noPeopleDataText() {
      return (this.people || {}).length ? this.$I18n.t('mobile.components.data_use_card.no_profile_data') : this.$I18n.t('mobile.components.data_use_card.no_profiles');
    }
  }
};
</script>
