import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router';
import store from './vuex';

Vue.use(VueRouter);

// Application wrapper
import Application from './application.vue';

// Routes of all the modules
import NetworkRoutes from './modules/network/routes';
import OnboardingRoutes from './modules/onboarding/routes';
import PeopleRoutes from './modules/people/routes';
import SettingsRoutes from './modules/settings/routes';
import SupportRoutes from './modules/support/routes';
import TermsRoutes from './modules/accounts/routes';

const router = new VueRouter({
  mode: 'history',
  base: '/mobile/',

  routes: [
    {
      path: '/',
      component: Application,
      name: 'root_path',
      children: [
        ...NetworkRoutes,
        ...OnboardingRoutes,
        ...PeopleRoutes,
        ...SettingsRoutes,
        ...SupportRoutes,
        ...TermsRoutes,
      ]
    },
  ]
});

async function manageStatusBarColor(to) {
  const statusBarStyle = to.meta.statusBarStyle;

  if (statusBarStyle) {
    await window.native.exec('set_statusbar_color', statusBarStyle.bgColorHex);
    await window.native.exec('set_statusbar_text_mode', statusBarStyle.textMode);
  } else {
    await window.native.exec('set_statusbar_color', '#ededed');
    await window.native.exec('set_statusbar_text_mode', 'dark');
  }
}

router.beforeEach((to, from, next) => {
  store.dispatch('HistoryStore/processRoute', { to, from });
  next();
});

router.afterEach(async to => {
  let $appBody = document.getElementById('app-body');
  if ($appBody) $appBody.scrollTop = 0;

  const toast = store.getters['ToastStore/getToast'];
  if(toast && toast.message) {
    Vue.toasted.global[toast.type](toast.message);
    store.dispatch('ToastStore/update', {});
  }

  await manageStatusBarColor(to);

  store.dispatch('HistoryStore/saveRoute', to );
  store.commit('HistoryStore/updatePathandCleanupHistory', to);
});

export default router;
