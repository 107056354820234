<template>
  <div v-if="showComponent" @click="routeUser" class="network-offline-banner m-b-8">
    <div class="flex-container align-center">
      <minim-mobile-icon
        iconName="lan-offline-large"
        size="medium-large"
        color="neutral-lightest"
      />
      <div class="m-l-12 m-r-32">
        <p class="minim-mobile-text secondary-font large text-neutral-lightest-color light">
          {{ $I18n.t('mobile.components.network_offline_banner.network_offline') }}
        </p>
        <p class="minim-mobile-text secondary-font medium text-neutral-lightest-color light">
          {{ instructionText }}
        </p>
      </div>
    </div>
    <minim-mobile-icon
      iconName="actionable"
      size="x-small"
      color="neutral-lightest"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PowerCycleMixin from 'mobile/shared/mixins/power_cycle_mixin';

export default {
  mixins: [PowerCycleMixin],

  computed: {
    ...mapGetters('UnumStore', ['primaryUnum']),
    ...mapGetters('LocalStorageStore', ['lastPowerCycled']),
    ...mapGetters('UnumStore', ['allUnumsAreUnmanaged']),

    showComponent() {
      if(this.primaryUnum.is_unmanaged || this.allUnumsAreUnmanaged) {
        return false;
      }

      if (this.$route.meta.hideNetworkOfflineBanner) {
        return false;
      }

      if (!this.primaryUnum.is_rebooting && !this.primaryUnum.is_updating && !this.primaryUnum.is_online) {
        return true;
      }

      return false;
    },

    instructionText() {
      return this.hasRecentlyPowerCycledDevice(this.lastPowerCycled, this.primaryUnum)
        ? this.$I18n.t('mobile.components.network_offline_banner.see_if_internet_is_down')
        : this.$I18n.t('mobile.components.network_offline_banner.learn_how_to_power_cycle');
    }
  },

  methods: {
    routeUser() {
      if (this.hasRecentlyPowerCycledDevice(this.lastPowerCycled, this.primaryUnum)) {
        this.$native.exec('open_external_url', 'https://downdetector.com/');
      } else {
        this.$router.push({
          name: 'lan_unum_power_cycle_instructions',
          query: {
            skip_history: true,
            tab_name: 'router'
          }
        });
      }
    }
  }
};
</script>
