<template>
  <pull-to-refresh-container :onRefresh="refreshData" @pageRefresh="pageRefresh">
    <div data-test-id="mobile-home-page">
      <portal to="page_title">{{ $I18n.t('mobile.settings.index.my_networks') }}</portal>
      <portal to="right_header">
        <div id="addDeviceIcon" style="width: 24px; height: 24px;">
          <minim-mobile-icon
            @click="$router.push({name: 'add_new_device_path', query: {tab_name: 'onboarding'}})"
            iconName="add"
            color="neutral-darker"
            size="small"
          />
        </div>
      </portal>
      <div>
          <div v-if="loading" class="p-64 text-center">
              <minim-mobile-spinner size="large" />
          </div>
          <div v-else class="display row">
              <div v-for="val in lanPrimaryUnums" :key="val.lan" @click="navigateToNetworkOverview(val.lan)" class="card-main">
                <div class="card-body minim-mobile-card security-status-card">
                  <span class="arrow-btn"><minim-mobile-icon
                      iconName="actionable"
                      color="neutral-dark"
                      size="x-small"
                  /></span>
                  <div class="bg-circle" :data-test-id="`lan-${val.lan}-button`">
                    <img :src="getHardwareKindImage(val.primaryUnum)" class="center-image" />
                    <div v-if="!val.primaryUnum.is_unmanaged && iconName(val)" class="circle" :style="iconStyle(val)">
                        <minim-mobile-icon
                            :iconName="iconName(val)"
                            color="neutral-lightest"
                            size="x-small"
                        />
                    </div>
                  </div>
                  <div class="card-bottom">
                      <p class="unum-title">{{val.lan_name.length > 15 ? val.lan_name.substr(0, 11)+"..." : val.lan_name}}</p>
                      <button v-if="unumStatus(val.primaryUnum) === 'unmanaged' && !val.showOverlay" @click.stop="navigateToUnumPage(val.lan, val.primaryUnum)" centered="true"
                      class="minim-mobile-primary-button custom-btn ghost">
                          {{ buttonText(val.primaryUnum) }}
                      </button>
                      <button v-else-if="unumStatus(val.primaryUnum) === 'offline' && !val.showOverlay" centered="true"
                      class="minim-mobile-primary-button custom-btn ghost button-disable">
                          {{ buttonText(val.primaryUnum) }}
                      </button>
                      <button v-else-if="!val.showOverlay && !val.has_offline_extender" v-on:click.stop @click="onReboot(val)" centered="true"
                            class="minim-mobile-primary-button custom-btn ghost" :class="isDisabled(val.primaryUnum) ? 'button-disable' : ''">
                          {{ buttonText(val.primaryUnum) }}
                      </button>
                      <button v-else-if="!val.showOverlay" v-on:click.stop @click="onReboot(val)" centered="true"
                      :class="{
                          'minim-mobile-primary-button custom-btn': true,
                          'negative-color': !isDisabled(val.primaryUnum),
                          'shadow-large': !isDisabled(val.primaryUnum),
                          'ghost': !isDisabled(val.primaryUnum) && !val.has_offline_extender,
                          'button-disable': isDisabled(val.primaryUnum)
                      }">
                          {{ buttonText(val.primaryUnum) }}
                      </button>
                  </div>
                </div>
                <box-overlay v-if="val.showOverlay" class="minim-mobile-card" @close="val.showOverlay = false">
                  <template v-slot:btnContainer>
                    <button v-on:click.stop @click="reboot(val.lan, val.primaryUnum)"
                    class="minim-mobile-primary-button custom-overlay-btn bg-primary-color shadow-large m-r-6 m-b-4">
                        {{ buttonText(val.primaryUnum) + "?" }}
                    </button>
                  </template>
                </box-overlay>
              </div>

              <div class="card-main">
                <div class="minim-mobile-card security-status-card card-body" style="display: block">
                    <p class="title-style">
                      {{ $I18n.t('mobile.settings.index.network_switcher.add_device_&_network') }}
                    </p>
                    <img :src="getImage('default_devices')" class="image-style">
                    <div class="card-bottom m-t-4">
                      <button
                        centered="true"
                        @click="$router.push({name: 'add_new_device_path'})"
                        class="minim-mobile-primary-button bg-primary-color custom-btn my-0"
                      >
                          {{ $I18n.t('mobile.onboarding.begin.get_started') }}
                      </button>
                    </div>
                </div>
              </div>
          </div>
      </div>

    </div>
  </pull-to-refresh-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import minim_api from 'mobile/shared/utils/minim_api';
import UnumStatusMixin from 'mobile/shared/mixins/unum_status_mixin';
import PullToRefreshContainer from 'mobile/shared/components/utilities/pull_to_refresh_container';

export default {
  components: {
    'pull-to-refresh-container': PullToRefreshContainer
  },

  mixins: [UnumStatusMixin],

  computed: {
    ...mapState('LanStore', ['lans']),
    ...mapGetters('OnboardingImageStore', ['getImage','getHardwareImage']),
    ...mapGetters({
      getHardwareKind: 'HardwareKindStore/getHardwareKind'
    }),
  },

  data() {
    return {
      lanPrimaryUnums: [],
      loading: true,
      isPageRefresh: false,
      displayRebootOverlay: null
    };
  },

  async beforeMount() {
    this.init();
  },

  mounted(){
    this.$nextTick(() => {
      this.$store.dispatch('HighlightsStore/loadHighlightsData', this.$router.currentRoute.name);
    });
  },

  methods: {
    async init() {
      let tmpArray = [];
      for(let i = 0; i < this.lans.length; i++){
        tmpArray.push({...await this.setLanPrimaryUnumsData(this.lans[i]), showOverlay: false});
      }
      this.lanPrimaryUnums = tmpArray;
      this.fetchHwKinds();
    },

    async setLanPrimaryUnumsData(element) {
      const unum = (await minim_api.get(`api/v1/lans/${element.id}/unums/${element.primary_unum_id}`)).data;
      return {
        lan: element.id,
        lan_name: element.name ? element.name : unum.name,
        has_offline_extender: element.has_offline_extender,
        primaryUnum: unum
      };
    },

    async reboot(lan, primaryUnum) {
      if(this.buttonText(primaryUnum) === this.$I18n.t('reboot')) {
        await this.$store.dispatch('UnumStore/rebootAll', { lanId: lan });
        this.navigateToNetworkOverview(lan);
      }
    },

    onReboot(val) {
      if(this.buttonText(val.primaryUnum) === this.$I18n.t('reboot')) {
        val.showOverlay = true;
      }
    },

    async navigateToUnumPage(lanId, primaryUnum) {
      await this.$store.dispatch('switchLan', {lanId});

      this.$router.push({
        name: 'lan_unum_show_path',
        params: {
          lanId: lanId,
          id: primaryUnum.id
        },
        query: { showFingerprintingInfoOnLoad: true }
      });
    },

    getHardwareKindImage(unum) {
      let hardware_kind = this.getHardwareKind(unum.hardware_kind_id) || {};
      return hardware_kind.hardware_image;
    },

    async fetchHwKinds() {
      for(let i = 0; i < this.lanPrimaryUnums.length; i++){
        if(this.lanPrimaryUnums[i].primaryUnum.hardware_kind_id) {
          await this.$store.dispatch('HardwareKindStore/show', this.lanPrimaryUnums[i].primaryUnum.hardware_kind_id);
        }
      }
      this.loading = false;
    },

    buttonText(primaryUnum) {
      switch (this.unumStatus(primaryUnum)) {
      case 'rebooting':
        return this.$I18n.t('rebooting');
      case 'updating':
        return this.$I18n.t('updating');
      case 'offline':
        return this.$I18n.t('offline');
      case 'unmanaged':
        return this.$I18n.t('device_info');
      default:
        return this.$I18n.t('reboot');
      }
    },

    iconName(val) {
      const unum = val.primaryUnum;
      if(!unum.is_online || val.has_offline_extender) {
        return 'alert';
      } if (unum.is_rebooting) {
        return 'reboot-badge';
      }
      return false;
    },

    iconStyle(val) {
      // show green icon only if all unums are online and a firmware update is available
      if (val.primaryUnum.is_firmware_update_available && !val.primaryUnum.is_rebooting && val.primaryUnum.is_online && !val.has_offline_extender) {
        return 'background-color: #30C191';
      }
      return 'background-color: #FF517D';
    },

    async navigateToNetworkOverview(lanId) {
      await this.$store.dispatch('switchLan', {lanId});

      this.$router.push({
        name: 'lan_path',
        params: { lanId: lanId },
        query: { tab_name: 'router' }
      });
    },

    isDisabled(unum) {
      return unum.is_rebooting || unum.is_updating || !unum.is_online;
    },

    async refreshData() {
      await this.init();
    },

    pageRefresh(event) {
      this.isPageRefresh = event;
    },
  }
};
</script>

<style scoped>

.circle {
    width: 16px;
    height: 16px;
    left: 40px;
    position: absolute;
    border-radius: 15px;
    position: absolute;
}

.custom-btn {
    width: 100%;
    height: 32px !important;
    min-height: 32px !important;
    font-family: 'Pulp Display';
    font-style: normal;
    font-weight: 100 !important;
    font-size: 14px !important;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    margin: 16px auto;
    padding: 0px 24px;
}
.custom-overlay-btn {
  width: auto !important;
  height: 32px !important;
  min-height: 32px !important;
  font-family: 'Pulp Display';
  font-style: normal;
  font-weight: 100 !important;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 0px 24px;
}

.custom-overlay-btn-rounded {
  width: 32px !important;
  height: 32px !important;
  min-height: 32px !important;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.arrow-btn {
    position: absolute;
    top: 68px;
    right: 10px;
}

.display {
    width: 97%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

.unum-title {
  font-family: 'Pulp Display';
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
}

.title-style {
    margin: 0 24px;
    font-family: 'Pulp Display';
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
}

.center-image {
  width: 66px;
  height: 66px;
  margin: auto;
}

.image-style {
  height: 100px;
  width: 100px;
  margin: auto;
}

.bg-circle {
    width: 96px;
    height: 96px;
    display: block;
    margin: auto;
    background-color: #F7F7F7;
    border-radius: 60px;
    border-radius: 60px;
    text-align: center;
    padding-top: 16px;
    margin-bottom: 16px;
    position: relative;
}

.card-main {
    width: 50%;
    display: block;
    position: relative;
    height: 250px;
    background-color: transparent;
    margin-bottom: 8px;
}

.card-body {
    background: #ffffff;
    padding: 32px 0;
    border-radius: 24px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 16%);
    text-align: center;
    height: 100%;
}

.button-disable {
    border: 1px solid grey;
    color: gray;
    background: transparent;
}

.card-bottom {
    padding: 0 24px;
}

.minim-icon-reboot-badge {
  animation: unum-status-rebooting-animation;
  animation-duration: 0s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
