<template>
  <div class="minim-mobile-card-wrapper">
    <div class="minim-mobile-card" :class="cardClass" style="width: 100%; box-shadow: none;">
      <slot></slot>
    </div>
    <div class="minim-mobile-card-footer" :class="footerClass">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardClass: {
      type: String,
      default: ''
    },

    footerClass: {
      type: String,
      default: ''
    }
  }
};
</script>
