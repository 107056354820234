<template>
  <nav v-if="showNav" class="mobile-bottom-nav-bar bg-primary-color">
    <tab-link
      name="router"
      :homePath="homePath"
      :text="$I18n.t('mobile.navbar.network')"
      iconName="nav-network"
      :selected="isSelectedTab('router')"
    />
    <tab-link
      v-if="brand === 'motosync'"
      name="shop"
      iconName="shop-24"
      :text="$I18n.t('mobile.navbar.shop')"
      :selected="isSelectedTab('shop')"
    />
    <tab-link
      v-if="supportPlusSubscriber"
      name='customer_support_info'
      :homePath="{ name: 'customer_support_info_index_path', query: { tab_name: 'support' }}"
      :text="$I18n.t('mobile.navbar.support')"
      iconName= "help-chat-24"
      :selected="isSelectedTab('support')"
    />
    <tab-link
      v-else
      name='customer_support_info'
      :homePath="{ name: 'customer_support_info_index_path', query: { tab_name: 'support' }}"
      :text="$I18n.t('mobile.navbar.support')"
      iconName="nav-help"
      :selected="isSelectedTab('support')"
    />
    <tab-link
      name="account"
      style="margin-top: -1px;"
      :homePath="{ name: 'account_path', query: { tab_name: 'account' } }"
      :text="$I18n.t('mobile.settings.index.my_account')"
      iconName="account-24"
      :selected="isSelectedTab('account')"
    />
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TabLink from './tab_link.vue';

export default {
  components: {
    'tab-link': TabLink
  },

  computed: {
    ...mapState('BrandingStore', ['hideNavBar', 'brand']),
    ...mapState('HistoryStore', ['tabbed_history']),
    ...mapGetters({
      csi: 'CustomerSupportInfo/data',
      allUnumsAreUnmanaged: 'UnumStore/allUnumsAreUnmanaged',
      hasSubscription: 'UserProductSubscriptionsStore/hasSubscription',
    }),

    supportPlusSubscriber() {
      return this.hasSubscription('support_plus');
    },

    showNav() {
      let hideForBrand = this.$store.state['BrandingStore'].hideNavBar || false;
      return !this.$route.meta.hideBottomNavbar && !hideForBrand;
    },

    homePath() {
      if (this.$route.name === 'lan_path') {
        return { name: 'network_switcher_path', query: { tab_name: 'router'} };
      } else if (this.$route.name !== 'network_switcher_path') {
        return { name: 'lan_path', params: { id: this.$route.params.lanId }, query: { tab_name: 'router', hideBackBtn: true } };
      }

      return null;
    }
  },

  methods: {
    isSelectedTab(tabName) {
      return this.$store.getters['HistoryStore/getCurrentTab'] === tabName;
    }
  }
};
</script>
