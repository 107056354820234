<template>
  <router-link
    v-if="pausedDeviceCount > 0"
    :to="{
      name: 'people_path',
      params: { lanId: $route.params.lanId },
      query: { tab_name: 'people' }
    }"
    class="minim-mobile-card p-16 flex-container align-center justify-between"
  >
    <div class="flex-container align-center">
      <minim-mobile-icon iconName="paused" size="medium-large" color="negative" />
      <p class="minim-mobile-text secondary-font large p-0 m-0 m-l-12">
        {{ $I18n.t('mobile.components.paused_device_banner.num_devices_paused', { num: pausedDeviceCount }) }}
      </p>
    </div>
    <div class="flex-container align-center">
      <span class="minim-mobile-text small secondary-font text-neutral-darker-color">
        {{ $I18n.t('manage') }}
      </span>
      <minim-mobile-icon iconName="actionable" color="neutral-darker" size="x-small" />
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  async beforeCreate() {
    await this.$store.dispatch('PauseStatusStore/index', { id: this.$route.params.lanId });
  },

  computed: {
    ...mapGetters('PauseStatusStore', ['pausedDeviceCount'])
  }
};
</script>
