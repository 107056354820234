<template>
  <div class="minim-speed-test-wrapper">
  <div class="minim-mobile-card mobile-speed-tests-card">
    <div>
      <!-- Top section (card header & link to history page) -->
      <div class="flex-container justify-between p-r-32 p-l-32">
        <div class="flex-container align-center grow-1" v-skeleton="showSkeleton" >
          <h2 class="minim-mobile-text primary-font small">
            {{ $I18n.t('mobile.speed_tests.card.title') }}
          </h2>
          <minim-mobile-icon
            class="informational-button"
            style="top: 3px;"
            iconName="info"
            size="x-small"
            name="speed-test-info-tip"
          />
          <info-tippy
            to="speed-test-info-tip"
            ref="speed-test-info-tip"
            trigger="click"
            :textAlignLeft="true"
          >
            <span class="minim-mobile-text secondary-font medium text-neutral-lightest-color">
              {{ $I18n.t('mobile.speed_tests.card.info') }}
            </span>
          </info-tippy>
        </div>
        <minim-mobile-card-link
          v-if="showLinkToSpeedTestsIndex"
          :showSkeleton="showSkeleton"
          :to="{ name: 'speed_tests_path', params: { lanId: $route.params.lanId} }"
          :text="$I18n.t('history')"
        />
      </div>

      <!-- Main card content -->
      <div>
        <div class="relative m-l-8 m-r-8">
          <reboot-banner
            :isVisible="showBanner"
            :maxedOut="speedTest.download_speed_maxed_out || speedTest.upload_speed_maxed_out"
            @hide="userClosedBanner = true"
            @reboot="reboot"
          />
        </div>
        <h2
          v-skeleton="{ showSkeleton, styles: { width: '212px' } }"
          class="minim-mobile-text secondary-font large centered m-t-24 m-b-24"
        >
          {{ headerText }}
        </h2>
        <supported-usage-types
          class="m-b-32"
          :showSkeleton="showSkeleton"
          :speedTest="speedTest"
          :speedTestIsRunning="speedTestIsRunning"
        />
        <div v-if="speedTest.download_speed_maxed_out || speedTest.upload_speed_maxed_out" class="maxed_out_info">
          <p class="bold">{{ $I18n.t('mobile.speed_tests.maxed_out_header') }}</p>
          <p>{{ $I18n.t('mobile.speed_tests.maxed_out_message') }}</p>
        </div>
      </div>
      <speed-test-legend
        class="p-l-32 p-r-32"
        :showSkeleton="showSkeleton"
        :speedTest="speedTest"
        :speedTestIsRunning="speedTestIsRunning"
      />
      <animated-primary-button
        @click="runSpeedTest"
        :showSkeleton="showSkeleton"
        :running="speedTestIsRunning"
        :disabled="disableSpeedTestsButton"
        testId="run-speed-test-button"
        :readyText="$I18n.t('mobile.speed_tests.run_prompt')"
        :runningText="$I18n.t('mobile.speed_tests.currently_running')"
        :disabledText="$I18n.t('mobile.speed_tests.disabled')"
        :waitPeriodMs="600"
      />
      <p
        v-skeleton="{ showSkeleton, styles: { width: '138px' } }"
        class="minim-mobile-text secondary-font medium text-neutral-darker-color centered m-t-24 p-b-24"
      >
        {{ lastRunText }}
      </p>
    </div>
  </div>
    <speed-test-server-info
      v-if="speedTest && speedTest.endpoint"
      class="home-speed-test-card"
      :endpoint="speedTest.endpoint"
      :server_location="speedTest.location_name"
      :lat="speedTest.lat"
      :lon="speedTest.lon"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import $ from 'jquery';

import SpeedTestsMixin from 'mobile/shared/mixins/speed_tests_mixin';
import SpeedTestRebootPrompter from 'mobile/shared/utils/speed_test_reboot_prompter';
import createSubscription from 'mobile/shared/utils/create_subscription';

import AnimatedPrimaryButton from 'mobile/shared/components/buttons/animated_primary_button';
import SpeedTestLegend from 'mobile/shared/components/legends/speed_test_legend/index';
import SupportedUsageTypes from './supported_usage_types';
import SpeedTestServerInfo from './speed_test_server_info.vue';
import RebootBanner from './reboot_banner.vue';
import InfoTippy from 'mobile/shared/components/buttons/info_tippy.vue';

export default {
  mixins: [SpeedTestsMixin],

  components: {
    'animated-primary-button': AnimatedPrimaryButton,
    'reboot-banner': RebootBanner,
    'speed-test-legend': SpeedTestLegend,
    'supported-usage-types': SupportedUsageTypes,
    'speed-test-server-info': SpeedTestServerInfo,
    'info-tippy': InfoTippy
  },

  props: {
    showLinkToSpeedTestsIndex: {
      type: Boolean,
      required: false,
      default: false
    },

    // Boolean to signify whether or not we're updating any relevant data
    // in a store, such as the LAN store, by a call made in the parent
    loadingDataInParent: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      subscription: null,
      loadingDataInMounted: true,
      waitingForNewData: false,
      userClosedBanner: false,
      speedTestStarted: false,
      prevSpeedTestID: ''
    };
  },

  async mounted() {
    await this.fetchMostRecentSpeedTest();
    this.$store.dispatch('SpeedTestStore/index');

    if (this.speedTest.is_running) {
      // If the speed test is already running when we mount the component start listening to the speed test channel
      // so we can fetch the results when it's finished
      this.waitingForNewData = true;
      this.subscribeToSpeedTestChannel(this.speedTest.id);
    }

    this.loadingDataInMounted = false;
  },

  beforeDestroy() {
    // when we leave the page we wanna make sure we kill any active subscriptions
    if (this.subscription) this.subscription.unsubscribe();
  },

  computed: {
    ...mapGetters({
      speedTest: 'SpeedTestStore/mostRecentSpeedTest',
      speedTests: 'SpeedTestStore/getSpeedTests',
      primaryUnum: 'UnumStore/primaryUnum',
      lan: 'LanStore/currentLan',
    }),

    showSkeleton() {
      return (this.loadingDataInParent || this.loadingDataInMounted) && !this.speedTest.id;
    },

    showBanner() {
      // hack because there's some bug where waiting for new data returns false early.
      // causing it to show the previous result
      if(this.speedTest.id == this.prevSpeedTestID)
        return false;
      if( !this.speedTestStarted )
        return false;
      if( this.speedTestIsRunning )
        return false;
      if( this.userClosedBanner )
        return false;
      if( !this.speedTestCompleted )
        return false;

      return this.shouldPromptReboot() || this.speedTest.download_speed_maxed_out || this.speedTest.upload_speed_maxed_out;
    },

    speedTestIsRunning() {
      return this.speedTest.is_running || this.waitingForNewData;
    },

    disableSpeedTestsButton() {
      return !this.lan.is_online;
    },

    hasData() {
      return this.speedTest && this.speedTest.id;
    },

    headerText() {
      if (this.showSkeleton) return ' ';
      else if (!this.hasData) return this.$I18n.t('mobile.speed_tests.card.no_speed_tests');
      else if (this.speedTest.has_failed) return this.$I18n.t('mobile.speed_tests.card.failed_test');
      else return this.$I18n.t('mobile.speed_tests.card.speed_supports');
    },

    lastRunText() {
      if (this.showSkeleton || !this.hasData) return ' ';
      if (this.speedTestIsRunning) return this.$I18n.t('mobile.speed_tests.card.running');

      const startedAt = moment(this.speedTest.started_at).fromNow();

      return this.speedTest.has_failed
        ? this.$I18n.t('mobile.speed_tests.card.last_run_failed_at', { at: startedAt })
        : this.$I18n.t('mobile.speed_tests.card.last_run_at', { at: startedAt });
    },

    speedTestCompleted() {
      return !(this.speedTest.download_speed_kbps == null ||
        this.speedTest.upload_speed_kbps == null ||
        this.speedTest.latency_ms == null);
    }
  },

  methods: {
    async fetchMostRecentSpeedTest() {
      await this.$store.dispatch('SpeedTestStore/fetchMostRecent'),
      await this.$store.dispatch('SpeedTestStore/index');

      this.waitingForNewData = false;
    },

    async runSpeedTest() {
      if(this.speedTest)
        this.prevSpeedTestID = this.speedTest.id;
      this.speedTestStarted = true;
      this.userClosedBanner = false;
      if (this.speedTestIsRunning || this.disableSpeedTestsButton) return;
      this.waitingForNewData = true;
      const res = await this.$store.dispatch('SpeedTestStore/runSpeedTest');

      // If we don't get a "created" status code quit
      if (res.status !== 201) {
        this.waitingForNewData = false;
        this.$toasted.global.minim_error(this.$I18n.t('mobile.speed_tests.card.failed_to_run'));
        return;
      }

      this.subscribeToSpeedTestChannel(res.data.id);
    },

    subscribeToSpeedTestChannel(id) {
      this.subscription = createSubscription(
        { channel: 'SpeedTestChannel', id },
        { received: this.fetchMostRecentSpeedTest }
      );
    },

    shouldPromptReboot() {
      const prompter = new SpeedTestRebootPrompter(this.speedTests, this.speedTest);
      return prompter.shouldPromptReboot();
    },

    async reboot() {
      await this.$store.dispatch('UnumStore/reboot', this.primaryUnum);
      $('#app-body').animate({ scrollTop: 0 }, 800);
      this.userClosedBanner = true;
    }
  },
};
</script>
