<template>
  <div data-test-id="onboarding-begin-onboarding">
    <back-button :onClick="goBack" v-if="showBackButton" />
    <logout-button btnPosition="left" v-else />

    <div class="onboarding-flex-container">
      <div>
        <p class="onboarding-header-text m-b-16">
          {{ $I18n.t('mobile.onboarding.index.get_started') }}
        </p>

        <p
          v-html="brand !== 'motosync' ? secondaryText : motosyncSecondaryText"
          class="onboarding-secondary-text"
        />

        <p
          v-if="user.is_employee"
          @click="openMotoSyncShopPage"
          class="onboarding-secondary-text super-bold text-primary-color m-t-16"
        >
          {{ $I18n.t('mobile.onboarding.begin.see_list') }}
        </p>
      </div>

      <img v-if="img" :src="img" class="onboarding-center-image" />

      <div class="m-b-24">
        <button
          @click="$router.push({ name: 'qr_code_fork_path' })"
          id="setupRouterButton"
          class="minim-mobile-primary-button m-b-16"
          data-test-id="onboarding_setup_router_btn"
        >
          {{ $I18n.t('mobile.onboarding.begin.setup_new_router') }}
        </button>
        <button
          @click="gotoJoinAnExistingNetworkPage"
          class="minim-mobile-primary-button ghost m-b-16"
        >
          {{ $I18n.t('mobile.onboarding.begin.join_existing_network') }}
        </button>
        <purchase-device-button />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BackButton from '../components/shared/back_button';
import PurchaseDeviceButton from 'mobile/shared/components/buttons/purchase_device_button.vue';
import LogoutButton from 'mobile/shared/components/buttons/logout_button.vue';
import analytics from 'mobile/shared/utils/analytics';

export default {
  components: {
    'back-button': BackButton,
    'purchase-device-button': PurchaseDeviceButton,
    'logout-button': LogoutButton,
  },

  mounted() {
    // This is here for when we are adding a new network from the settings page.
    // When adding the network, we should blow away the current state of the unum store
    // since it won't be relevant to the new network we create
    this.$store.commit('UnumStore/clear');

    this.$store.commit('OnboardingStore/setIsOnboarding', true);
    this.$store.commit('OnboardingStore/setCreatingNewNetwork', true);
    this.$store.commit('OnboardingImageStore/preloadGenericImages');
  },

  computed: {
    ...mapState('BrandingStore', ['brand', 'brandNiceName']),
    ...mapState('UserStore', ['user']),
    ...mapGetters('OnboardingImageStore', ['getImage']),

    img() {
      return this.brand === 'motosync'
        ? this.getImage('motosync_devices')
        : this.getImage('default_devices');
    },

    secondaryText(){
      if(this.user.first_name){
        return this.$i18n.t('mobile.onboarding.begin.need_router_to_proceed_personalized', {first_name: this.user.first_name, brand_name:this.brandNiceName});
      } else {
        return this.$I18n.t('mobile.onboarding.begin.need_router_to_proceed', { brand_name: this.brandNiceName });
      }
    },

    motosyncSecondaryText(){
      if(this.user.first_name){
        return this.$i18n.t('mobile.onboarding.begin.need_router_to_proceed_personalized_motosync', {first_name: this.user.first_name, brand_name:this.brandNiceName});
      } else {
        return this.$I18n.t('mobile.onboarding.begin.need_router_to_proceed_motosync', { brand_name: this.brandNiceName });
      }
    },

    showBackButton() {
      return this.$route.query.hideBackBtn !== 'true' && this.$route.query.hideBackBtn !== true;
    }
  },

  methods: {
    openMotoSyncShopPage() {
      // TODO: needs to be updated with the Moto Sync shop page URL once it's live
      this.$native.exec('open_external_url', 'https://shop.minim.co');
    },

    goBack() {
      // Going back from this screen implies you are canceling onboarding, so update state as such.
      this.$store.commit('OnboardingStore/clearOnboarding');

      if (this.$route.query.backRoute) {
        this.$router.push(this.$route.query.backRoute);
      } else {
        const currentLanId = this.$store.getters['LanStore/currentLan'].id;
        this.$router.push({ name: 'add_new_device_path', params: { lanId: currentLanId }, query: { tab_name: 'onboarding' } });
      }
    },

    logUserJoinAnExistingNetwork() {
      try {
        analytics.logEvent('user_joined_an_existing_network');
      } catch (err) {
        // if pushing to amplitude fails just log it and move on
        // we don't want an error to be thrown and disrupt onboarding for the user over that
        console.log(err);
      }
    },

    gotoJoinAnExistingNetworkPage() {
      this.logUserJoinAnExistingNetwork();
      this.$router.push({ name: 'join_existing_network_path' });
    }
  }
};
</script>
