<template>
  <div class="back-button shifted" @click="onBack">
    <p class="minim-mobile-text small secondary-font">{{ $I18n.t('back') }}</p>
  </div>
</template>

<script>
import I18n from 'shared/lib/i18n';

export default {
  props: {
    onClick: {
      type: Function,
      required: false
    },

    text: {
      type: String,
      default: I18n.t('back')
    }
  },

  mounted() {
    this.$store.commit('HistoryStore/setNativeBackButtonOverride', this.onBack);
  },

  methods: {
    onBack() {
      if (this.onClick) {
        this.onClick();
      } else {
        // if no onClick function was passed just go back to the previous page
        this.$router.go(-1);
      }
    }
  }
};
</script>
