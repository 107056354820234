<template>
  <div class="mobile-tos-form" :class="brand">
    <div class="tos-checkbox">
      <div
        class="minim-mobile-checkbox bg-white"
        :class="value ? 'selected' : ''"
        data-test-id="accept_tos"
        @click="$emit('input', !value)"
      >
        <div class="check">
        </div>
      </div>
      <component :is="termsTemplate" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    newUser: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapGetters('BrandingStore', ['privacyPolicyLink', 'termsAndConditionsLink']),

    termsTemplate() {
      const openTermsLink = this.openTermsLink;
      const openPrivacyLink = this.openPrivacyLink;

      return {
        template: this.termsTemplateString,

        methods: {
          openTermsLink,
          openPrivacyLink
        }
      };
    },

    termsTemplateString() {
      if(this.brand === 'mca') {
        return this.$I18n.t('terms_of_service.mca_template');
      }
      if(this.newUser) {
        return this.$I18n.t('terms_of_service.age_privacy_and_terms_template');
      }
      return this.$I18n.t('terms_of_service.privacy_and_terms_template');
    }
  },

  methods: {
    openTermsLink() {
      this.$native.exec('open_external_url', this.termsAndConditionsLink);
    },

    openPrivacyLink() {
      this.$native.exec('open_external_url', this.privacyPolicyLink);
    }
  }
};
</script>
