<template>
  <minim-mobile-icon
    fallbackIconClass="fas fa-spinner fa-spin spinner"
    :color="color"
    :size="size"
    :style="{ 'opacity': opacity, 'margin-top': marginTop + '%'}"
  />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'disabled-color',
      required: false
    },

    size: {
      type: String,
      default: 'medium',
      required: false
    },

    opacity: {
      type: [String, Number],
      default: 0.65,
      required: false
    },

    marginTop: {
      type: Number,
      required: false
    }
  }
};
</script>
