<template>
  <form @submit.prevent="search" class="minim-mobile-search-field-container">
    <div class="minim-mobile-search-field">
      <input
        @input="$emit('input', $event.target.value)"
        class="search-field"
        type="text"
        :placeholder="placeholder"
        :value="value"
        autocapitalize="off"
        autocorrect="off"
        autocomplete="off"
      >

      <minim-mobile-icon
        v-if="showClearButton"
        @click="$emit('clear')"
        iconName="close"
        color="neutral-darker"
        size="small"
      />

      <minim-mobile-icon
        v-else-if="showSearchField"
        @click="search"
        iconName="search"
        color="neutral-darker"
        size="small"
      />
    </div>

    <p v-if="errorMessage && errorMessage.length" class="error-message">
      {{ errorMessage }}
    </p>
  </form>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      required: true
    },

    showClearButton: {
      type: Boolean,
      default: false
    },

    errorMessage: {
      type: [String, null],
      required: false
    }
  },

  computed: {
    showSearchField() {
      return !!this.value.length;
    }
  },

  methods: {
    search() {
      this.$emit('search');
    }
  }
};
</script>
