<template>
  <div class="mobile-login-container bg-white">
    <div class="center p-b-40">
      <login-icon />
      <h2 class="header">
        {{ header }}
      </h2>
      <p class="minim-mobile-text secondary-font large" v-html="deactivationText" />
      <p v-if="showSubscriptionBanner" v-html="subscriptionBannerText" :class="`data-revocation-banner m-t-32 m-b-32 ${this.brand}`"></p>
    </div>

    <div>
      <button @click="isRetailCustomer ? logOut() : returnHome()" class="minim-mobile-primary-button large m-b-24">
        {{ buttonText }}
      </button>
      <button v-if="showSubscriptionBanner && checkPlatformPaymentServiceType(platform)" @click="manageSubscription" class="minim-mobile-primary-button large ghost m-b-24">
        {{ $i18n.t('terms_of_service.privacy_compliance.manage_subscription') }}
      </button>
      <button v-if="isRetailCustomer" @click="returnHome()" class="minim-mobile-primary-button large negative-color ghost m-b-24">
        {{ $i18n.t('terms_of_service.data_consent.cancel_deactivation') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import LoginIcon from '../../components/shared/login_icon';


export default {
  components: {
    'login-icon': LoginIcon
  },

  data(){
    return {
      platform: null,
    };
  },

  async mounted(){
    this.platform = await this.$native.exec('get_payment_service_type');
    await this.$store.dispatch('LanStore/index');
  },

  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapState('LanStore', ['lans']),
    ...mapGetters('UserProductSubscriptionsStore', ['getActiveSubscriptions']),

    cancelDeadlineAt() {
      if (!this.$route.params.cancel_deadline_at) {
        return 'in <span class="data-deletion-deadline">7 days</span>';
      }
      const date = new Date(this.$route.params.cancel_deadline_at);
      return `on <span class="data-deletion-deadline">${date.toLocaleDateString(this.$i18n.locale, {year: 'numeric', month: 'long', day: 'numeric'})}</span>`;
    },

    isRetailCustomer() {
      return (!this.lans || this.lans.every((lan) => lan.features.includes('retail-isp')));
    },

    header() {
      return this.isRetailCustomer ? this.$I18n.t('terms_of_service.privacy_compliance.account_deactivated_header') : this.$I18n.t('terms_of_service.data_consent.revocation_requested');
    },

    deactivationText() {
      return this.isRetailCustomer
        ? this.$I18n.t('terms_of_service.privacy_compliance.account_deactivated_text', { deadline: this.cancelDeadlineAt })
        : this.$I18n.t('terms_of_service.data_consent.requires_approval');
    },

    subscriptionBannerText() {
      if(this.checkPlatformPaymentServiceType(this.platform)) {
        return this.$i18n.t('terms_of_service.privacy_compliance.account_deactivating_initiated_same_platform_text');
      } else if (this.platform == 'apple' && this.checkPlatformPaymentServiceType('google')) {
        return this.$i18n.t('terms_of_service.privacy_compliance.account_deactivating_initiated_different_platform_text', {platformType: this.$i18n.t('terms_of_service.privacy_compliance.google_play_store')});
      } else if (this.platform == 'google' && this.checkPlatformPaymentServiceType('apple')) {
        return this.$i18n.t('terms_of_service.privacy_compliance.account_deactivating_initiated_different_platform_text', {platformType: this.$i18n.t('terms_of_service.privacy_compliance.apple_app_store')});
      }

      return null;
    },

    showSubscriptionBanner() {
      return this.isRetailCustomer && this.platform && this.getActiveSubscriptions().length > 0;
    },

    buttonText(){
      return this.isRetailCustomer ? this.$i18n.t('log_out') : this.$i18n.t('terms_of_service.data_consent.return_to_app');
    }
  },
  methods: {
    logOut(){
      return this.$store.dispatch('UserStore/logout').then(() => {
        window.app.reload();
      });
    },
    returnHome() {
      window.app.reload();
    },

    manageSubscription(){
      this.$native.exec('manage_subscription');
    },

    checkPlatformPaymentServiceType(platform) {
      const subscriptions  = this.getActiveSubscriptions().filter(s => {
        return s.payment_service_type === platform;
      });
      return subscriptions.length > 0;
    }
  }
};

</script>
