import { std, mean} from 'mathjs';
/*
 * https://gist.github.com/izaac/daf81344fe6061c82172
 * used some of the functions from this gist
 */
export default class SpeedTestRebootPrompter {
  constructor(speedTests, recentSpeedtest) {
    this.speedTests = speedTests;
    if(this.speedTests) {
      this.speedTests = this.speedTests.slice(0,20);
    }
    this.removeElementsWithNull();
    this.recentSpeedtest = recentSpeedtest;
  }

  fieldAverage(field) {
    let arr = this.speedTests.map((x) => x[field]);
    return mean(arr);
  }

  fieldStdDev(field) {
    let arr = this.speedTests.map((x) => x[field]);
    // We will only ever be sampling, so Bessel's correction is always correct here
    return std(arr);
  }

  zScore(field) {
    let avg = this.fieldAverage(field);
    let std_x = this.fieldStdDev(field);
    let x = this.recentSpeedtest[field];
    if(std_x == 0)
      return 0;
    return (x - avg) / std_x;
  }

  removeElementsWithNull() {
    if(this.speedTests == null){
      return;
    }
    let tmp = [];
    for (const speedTest of this.speedTests) {
      let has_null = !speedTest.download_speed_kbps || !speedTest.upload_speed_kbps || !speedTest.latency_ms;
      if(has_null) {
        continue;
      } else {
        tmp.push(speedTest);
      }
    }
    this.speedTests = tmp;
  }

  shouldPromptReboot() {
    if(!this.recentSpeedtest) {
      return false;
    }

    if(this.speedTests.length < 5) {
      return false;
    }

    let update_is_below = this.zScore('upload_speed_kbps') < -2;
    let download_is_below = this.zScore('download_speed_kbps') < -2;
    let latency_is_above = this.zScore('latency_ms') > 2;

    return update_is_below || download_is_below || latency_is_above;
  }
}
