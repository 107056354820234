import MinimMobileIcon from './minim_mobile_icon.vue';
import MinimMobileIconHeaderCard from './minim_mobile_icon_header_card.vue';
import MinimMobileTabbedCard from './minim_mobile_tabbed_card/index.vue';
import MinimMobileModal from './minim_mobile_modal.vue';
import MinimMobileCardWithFooter from './minim_mobile_card_with_footer.vue';
import MinimMobileConfirmModal from './minim_mobile_confirm_modal.vue';
import MinimMobileTextField from './minim_mobile_text_field.vue';
import MinimMobilePasswordField from './minim_mobile_password_field.vue';
import MinimMobileCheckbox from './minim_mobile_checkbox.vue';
import MinimMobileRadioButton from './minim_mobile_radio_button.vue';
import MinimMobileSecondaryButton from './minim_mobile_secondary_button.vue';
import MinimMobileToggle from './minim_mobile_toggle.vue';
import MinimMobileSpinner from './minim_mobile_spinner.vue';
import MinimMobileToast from './minim_mobile_toast.vue';
import MinimMobileGraphCard from './minim_mobile_graph_card/index.vue';
import MinimMobileCardLink from './minim_mobile_card_link.vue';
import MinimMobileSearchField from './minim_mobile_search_field.vue';
import MinimMobileTabPicker from './minim_mobile_tab_picker.vue';
import MinimMobileMultilineTextField from './minim_mobile_multiline_text_field.vue';
import BoxOverlay from './box-overlay';
import RemoveDeviceForm from '../forms/remove_device_from';

/**
 * All files imported into and exported from this file are made available globally
 * throughout the app. This means that to use them they do not have to be imported into
 * any component, they can just be referenced using the key provided for them in the
 * exported object below. All global components in the mobile app have been prefixed
 * with "minim-mobile" in order to prevent confusion about where these components are coming from.
 * Components should be made globally available if they'll be used frequently enough that
 * they are a recognizeable UI element and could be considered part of the "standard component llibrary"
 */
export default {
  'minim-mobile-icon': MinimMobileIcon,
  'minim-mobile-icon-header-card': MinimMobileIconHeaderCard,
  'minim-mobile-tabbed-card': MinimMobileTabbedCard,
  'minim-mobile-modal': MinimMobileModal,
  'minim-mobile-card-with-footer': MinimMobileCardWithFooter,
  'minim-mobile-confirm-modal': MinimMobileConfirmModal,
  'minim-mobile-text-field': MinimMobileTextField,
  'minim-mobile-password-field': MinimMobilePasswordField,
  'minim-mobile-checkbox': MinimMobileCheckbox,
  'minim-mobile-radio-button': MinimMobileRadioButton,
  'minim-mobile-secondary-button': MinimMobileSecondaryButton,
  'minim-mobile-toggle': MinimMobileToggle,
  'minim-mobile-spinner': MinimMobileSpinner,
  'minim-mobile-toast': MinimMobileToast,
  'minim-mobile-graph-card': MinimMobileGraphCard,
  'minim-mobile-card-link': MinimMobileCardLink,
  'minim-mobile-search-field': MinimMobileSearchField,
  'minim-mobile-tab-picker': MinimMobileTabPicker,
  'minim-mobile-multiline-text-field': MinimMobileMultilineTextField,
  'box-overlay': BoxOverlay,
  'remove-device-form': RemoveDeviceForm
};
