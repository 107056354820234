<template>
  <tippy
    :to="to"
    :trigger="trigger"
    ref="tippy"
    :theme="'minim primary' + (textAlignLeft ? ' text-left' : '')"
    :arrow="true"
    :interactive="true"
  >
    <slot class="minim-mobile-text secondary-font medium text-neutral-lightest-color"></slot>
  </tippy>
</template>

<script>

export default {
  props: {
    to: {
      type: String,
      required: true
    },

    trigger: {
      type: String,
      required: false,
      default: 'click'
    },

    content: {
      type: String,
      required: false,
    },

    interactive: {
      type: Boolean,
      default: false
    },

    textAlignLeft: {
      type: Boolean,
      default: false
    }
  }
};
</script>