import { mapGetters } from 'vuex';
import debounce from 'mobile/shared/utils/debounce';

const platformsToIconNames = {
  android: 'share-android',
  ios: 'share-apple',
};

export default {
  props: {
    ssid: {
      type: String,
      required: true
    },

    password: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters('NativeInfoStore', ['currentPlatform']),

    platform() {
      return (this.currentPlatform || '').toLowerCase();
    },

    showShareButton() {
      return ['android', 'ios'].includes(this.platform);
    },

    shareIconName() {
      return platformsToIconNames[this.platform];
    },

    hasSsid() {
      return !!this.ssid && !!this.ssid.length;
    },

    hasPassword() {
      return !!this.password && !!this.password.length;
    },

    sharingDisabled() {
      return !this.hasSsid;
    }
  },

  methods: {
    shareSsidAndPassword: debounce(async function() {
      if (this.sharingDisabled) return;

      let message = 'Network name (SSID):\n' + this.ssid;

      message += '\nPassword:\n';
      message += (this.hasPassword ? this.password : 'No password');
      this.$native.exec('open_native_share_menu', { message });
    }, 500)
  }
};
