import { mapGetters } from 'vuex';

const PRE_LOGIN_PATHS = ['login_path', 'registration_path', 'reset_password_path'];
const SERVICE_TERM_PATHS = ['terms_index_path', 'age_terms_path', 'data_privacy_path'];

export default {
  computed: {
    ...mapGetters('UserProductSubscriptionsStore', ['hasSubscription'])
  },

  methods: {
    async getProperPath() {
      // Check the user has logged in, if not validate that they're on an acceptable page for an unlogged in user to be on
      // and if they're not send them to the login page and then exit
      const user_data = this.$store.state['UserStore'].user;

      if (!user_data || Object.keys(user_data).length === 0) {
        if (!PRE_LOGIN_PATHS.includes(this.$route.name)) {
          this.$router.push({ name: 'login_path' });
        }

        return;
      }

      // Check that the user has accepted the TOS, if not send them to the TOS page and exit
      if (!this.$store.getters['UserServiceTermsStore/checkAcceptance']('privacy') || !this.$store.getters['UserServiceTermsStore/checkAcceptance']('t_and_c')) {
        this.$router.push({ name: 'terms_index_path' });
        return;
      }

      if (!this.$store.getters['UserServiceTermsStore/checkAcceptance']('age')) {
        this.$router.push({ name: 'age_terms_path' });
        return;
      }

      if (!this.$store.getters['UserServiceTermsStore/checkAcceptance']('data_consent')) {
        this.$router.push({ name: 'data_privacy_path' });
        return;
      }

      const lans = this.$store.state['LanStore'].lans || [];

      if (!lans.length) {
        this.$router.push({ name: 'onboarding_begin_path', query: { hideBackBtn: true } });
        this.$native.exec('set_config_item', { key: 'onboarded', value: false });
        return;
      } else {
        this.$native.exec('set_config_item', { key: 'onboarded', value: true });
      }

      // Commented out until we decide to revive Support+
      // const hasSeenSupportPlusPopup = await this.$native.exec('fetch_preference', 'hasSeenSupportPlusPopup');
      // if (user_data.is_employee && !hasSeenSupportPlusPopup && !this.hasSubscription('support_plus', false)) {
      //   await this.$native.exec('set_preference', { key: 'hasSeenSupportPlusPopup', value: true });
      //   this.$router.push({ name: 'subscription_pop_up', params: { productKey: 'support_plus'} });
      //   return;
      // }

      // If none of the above conditions were executed and the user is still some how on a path they shouldn't be on
      // route them to the network switcher. If not, don't route them anyhwhere, leave them where they are.
      if (this.$route.path === '/' || [...SERVICE_TERM_PATHS, ...PRE_LOGIN_PATHS].includes(this.$route.name)) {
        this.$router.push({
          name: 'network_switcher_path',
          query: { tab_name: 'router' }
        });
      }
    }
  }
};