<template>
  <div class="minim-mobile-graph">
    <minim-mobile-tabbed-card
      @tabChanged="handleTabChanged"
      :tabs="tabs"
      :initialTabIndex="initialToggleIndex"
    >
      <div
        v-for="toggle in graphToggles"
        :slot="toggle.name"
        :key="`${toggle.name}-${toggle.value}`"
        style="overflow: hidden;"
        class="p-b-32"
        :class="graphToggles.length > 1 ? 'p-t-48' : 'p-t-32'"
      >
        <time-scale-picker
          @resetZoom="handleResetZoom"
          @undoZoom="handleUndoZoom"
          @timeScaleChanged="handleTimeScaleChanged"
          :selectedTimeScale.sync="selectedTimeScale"
          :isZoomed="isZoomed"
        />
        <slot name="text_area" />
        <graph
          ref="minimMobileGraph"
          @chart="chart => $emit('chart', chart)"
          @zoomChange="handleZoomChange"
          @finishedLoading="handleLoadingFinished"
          :isZoomed="isZoomed"
          :graphName="toggle.value"
          :timeScale="selectedTimeScale"
          :queryOpts="{ ...queryOpts, ...(toggle.overrideQueryOpts || {}) }"
          :autoRefresh="autoRefresh"
          :refreshInterval="refreshInterval"
          :graphContainerClasses="graphContainerClasses"
        />
        <slot :name="`custom_legend_slot_${toggle.value}`" />
      </div>
    </minim-mobile-tabbed-card>
  </div>
</template>

<script>
import Graph from './graph';
import TimeScalePicker from './time_scale_picker';
import debounce from 'mobile/shared/utils/debounce';
import ScrolledIntoViewMixin from 'mobile/shared/mixins/scrolled_into_view_mixin';
import analytics from 'mobile/shared/utils/analytics';

export default {
  mixins: [ScrolledIntoViewMixin],

  components: {
    'graph': Graph,
    'time-scale-picker': TimeScalePicker
  },

  props: {
    graphToggles: {
      type: Array,
      required: false
    },

    queryOpts: {
      type: Object,
      required: false
    },

    autoRefresh: {
      type: Boolean,
      required: false
    },

    refreshInterval: {
      type: Number,
      required: false
    },

    initialToggleIndex: {
      type: Number,
      required: false,
      default: 0
    },

    graphContainerClasses: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      selectedTimeScale: 'last_hour',
      isZoomed: false,
      appBody: null,
      hasBeenScrolled: false,
      selectedGraphName: ''
    };
  },
  
  computed: {
    tabs() {
      return this.graphToggles.map(toggle => toggle.name);
    }
  },

  mounted() {
    this.selectedGraphName = this.tabs[this.initialToggleIndex].toLowerCase().split(' ').join('_');
    
    // Once the DOM has mounted find the app body, store it in state for later use and attatch a scroll listener to it
    this.appBody = document.getElementById('app-body');
    this.appBody.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    this.appBody.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleZoomChange(isZoomed) {
      this.isZoomed = isZoomed;
    },

    handleResetZoom() {
      this.$refs.minimMobileGraph[0].resetZoom();
    },

    handleUndoZoom() {
      this.$refs.minimMobileGraph[0].undoZoom();
    },

    handleTimeScaleChanged(newTimeScale) {
      this.$emit('timeScaleChanged', newTimeScale);
      this.selectedTimeScale = newTimeScale;
      analytics.logEvent('mobile_graph_time_scale_changed', {time_scale: newTimeScale, graph_name: this.selectedGraphName});
    },

    handleLoadingFinished() {
      this.$emit('finishedLoading');
    },

    handleScroll: debounce(async function() {
      if(!this.hasBeenScrolled && this.isScrolledIntoView(this.$refs.minimMobileGraph)) {
        // setting this propert to true inorder to make sure it log event only once when scrolled into full view
        this.hasBeenScrolled = true;
        analytics.logEvent(`${this.$route.name}_full_view`);
      }
    }, 150),
    
    handleTabChanged(newTabName) {
      this.$emit('graphToggled', newTabName);
      this.selectedGraphName = newTabName.toLowerCase().split(' ').join('_');
      analytics.logEvent('mobile_graph_tab_changed', {tab_name: newTabName, graph_name: this.selectedGraphName});
    }
  }
};
</script>
