export default {
  validateDomain(domain) {
    const VALID_DOMAIN_REGEX = /^[a-z0-9][a-z0-9\-_]{0,62}(\.[a-z0-9][a-z0-9\-_]{0,62})+$/i;

    return domain.match(VALID_DOMAIN_REGEX);
  },

  validateMacAddress(macAddress) {
    const MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]?){5}([0-9A-Fa-f]{2})$/;

    return MAC_ADDRESS_REGEX.test(macAddress);
  },

  validatePassword(password) {
    let errors = [];
    if(password.length < 8) {
      errors.push(window.$I18n.t('devise.validations.password'));
      // no need to add other validations if blank
      return errors;
    }
    return errors;
  },

  validateEmail(email) {
    let errors = [];
    if(email === '') {
      errors.push(window.$I18n.t('devise.validations.email'));
      // no need to add other validations if blank
      return errors;
    }
    return errors;
  },

  validateIPAddress(address){
    const validIpRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

    return validIpRegex.test(address);
  },

  validateSubnetMask(subnetMask){
    const validSubnetRegex = /^(254|252|248|240|224|192|128)\.0\.0\.0|255\.(254|252|248|240|224|192|128|0)\.0\.0|255\.255\.(254|252|248|240|224|192|128|0)\.0|255\.255\.255\.(254|252|248|240|224|192|128|0)$/;

    return validSubnetRegex.test(subnetMask);
  }
};
