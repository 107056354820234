function set_in_local_storage(resolve, _reject, opts) {
  let key = opts.key;
  let value = opts.value;

  localStorage.setItem(key, value);
  resolve();
}

function get_from_local_storage(resolve, _reject, key) {
  resolve(localStorage.getItem(key));
}

function remove_from_local_storage(resolve, _reject, key) {
  localStorage.removeItem(key);
  resolve();
}

export default {
  exec(name, opts, resolve, reject) {
    let args = opts || {};

    if(typeof this[name] === 'function') {
      this[name](resolve, reject, args);
    } else {
      resolve(null);
    }
  },

  device_data(resolve) {
    resolve({ platform: 'browser' });
  },

  request(resolve) {
    resolve({ data: JSON.stringify({ mac_address: prompt('Native is making a request... what do you want to return?', '') }) });
  },

  scan(resolve, reject) {
    let scan_results = prompt('enter data for the QR scanner simulator', '');

    if(scan_results) {
      resolve(scan_results);
    } else {
      reject(scan_results);
    }
  },

  // Temporarily need to have fallbacks for the get_config_item, set_config_item and remove_config_item methods.
  // Eventually, everything in the app should use the set_preference, fetch_preference and remove_preference functions,
  // and at that point we can delete the legacy config_item versions
  set_config_item: set_in_local_storage,
  set_preference: set_in_local_storage,
  get_config_item: get_from_local_storage,
  fetch_preference: get_from_local_storage,
  remove_config_item: remove_from_local_storage,
  remove_preference: remove_from_local_storage,

  get_gateway_ip(resolve, reject) {
    const ip = prompt('Enter the gateway IP address:', '');

    if (ip) {
      resolve(ip);
    } else {
      reject();
    }
  },

  open_external_url(resolve, reject, url) {
    window.open(url, '_blank');
    resolve();
  },

  open_webview(resolve, reject, { startUrl, query = {} }) {
    let queryString = Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join('&');
    startUrl += queryString? ('?' + queryString): '';
    window.open(startUrl, '_blank');
    resolve();
  },

  set_root_endpoint(resolve, _reject, endpoint) {
    resolve(window.location.href = endpoint + '/mobile');
  }
};
