<template>
  <div class="text-center" style="padding-bottom: 130px;">
    <h2
      class="unum-card-header-text"
      v-html="$I18n.t('mobile.components.unum_card.network_offline')"
    />
    <p class="minim-mobile-text secondary-font medium text-neutral-lightest-color m-t-12  m-l-32 m-r-32">
      {{ $I18n.t('mobile.components.unum_card.internet_may_be_down') }}
    </p>
    <button
      @click="openDownDetector"
      class="minim-mobile-primary-button negative-text light neutral-lightest-color m-t-24"
      style="width: 250px;"
    >
      {{ $I18n.t('mobile.components.unum_card.see_if_internet_is_down') }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('UnumStore', ['primaryUnum'])
  },

  methods: {
    openDownDetector() {
      this.$native.exec('open_external_url', 'https://downdetector.com/');
    }
  }
};
</script>
