<template>
  <form class="mobile-login-container bg-white" @submit.prevent="register" novalidate>
    <div class="w-full m-b-32">
      <login-icon />
      <h2 class="header">{{ $I18n.t('devise.register_header') }}</h2>
      <minim-mobile-text-field
        testId="email_field"
        :placeholder="$I18n.t('devise.sessions.enter_email')"
        v-model="email"
        :errors="errors.email"
        type="email"
      >
        <span slot="leftMostIcon">
          <minim-mobile-icon
            iconName="envelope-16"
            color="neutral-dark"
            size="x-small"
          />
        </span>
      </minim-mobile-text-field>
      <minim-mobile-password-field
        testId="password_field"
        :placeholder="$I18n.t('devise.sessions.enter_password')"
        v-model="password"
        :errors="errors.password"
      />
      <accept-tos v-if="showTOS" v-model="accepted" :newUser="true" />
    </div>

    <minim-mobile-spinner v-if="loading" class="p-48" />
    <div v-else>
      <button
        type="submit"
        class="minim-mobile-primary-button m-b-32"
        data-test-id="create_account_action_btn"
      >
        {{ $I18n.t('devise.sessions.create_account') }}
      </button>
      <router-link
        class="flex-container align-center column"
        :to="{ name: 'login_path' }"
      >
        <p
          class="minim-mobile-text primary-font small light text-neutral-darker-color m-t-2 flex-container align-center"
          data-test-id="already_have_account_btn"
        >
          {{ $I18n.t('devise.passwords.go_back') }}
          <minim-mobile-icon
            iconName="actionable"
            color="neutral-dark"
            size="x-small"
          />
        </p>
      </router-link>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

import analytics from 'mobile/shared/utils/analytics';

import GetProperPathMixin from 'mobile/shared/mixins/root_path_mixin';
import Validators from 'mobile/shared/utils/validators';

import AcceptTOS from '../components/shared/accept_tos';
import LoginIcon from '../components/shared/login_icon';

const LOADING_TIMEOUT = 1000;

export default {
  components: {
    'accept-tos': AcceptTOS,
    'login-icon': LoginIcon,
  },

  data () {
    return {
      firstName: '',
      email: '',
      password: '',
      accepted: false,
      loading: false,
      errors: { password: [], email: [] }
    };
  },

  mixins: [GetProperPathMixin],

  async mounted(){
    await this.$store.dispatch('ServiceTermsStore/index');
  },

  computed: {
    ...mapGetters({
      brand: 'BrandingStore/getBrand',
      terms: 'ServiceTermsStore/getNonDataConsentTerms'
    }),

    showTOS() {
      return ['minim', 'motosync'].includes(this.brand);
    }
  },

  methods: {
    async register() {
      if (!this.validations()) {
        return;
      }

      try {
        this.loading = true;

        await this.$store.dispatch('UserStore/register', {
          email: this.email,
          password: this.password
        });

        if (this.showTOS){
          // Dispatch action to accept each individual service term before creating a user
          try {
            await Promise.all(this.terms.map(async term => {
              await this.$store.dispatch('ServiceTermsStore/accept', term);
            }));
          } catch(e){
            console.log(e);
            this.$toasted.global.minim_error(this.$I18n.t('terms_of_service.network_error'));
            return;
          }
        }

        await window.app.afterLoginSetup();

        analytics.logEvent('mobile_sign_up', { successful: true });

        setTimeout(() => { this.loading = false; }, LOADING_TIMEOUT);
        this.getProperPath();
      } catch(error) {
        console.log(error);

        analytics.logEvent('mobile_sign_up', { successful: false });

        if(error.response.status === 401) {
          setTimeout(() => {
            this.loading = false;
            this.$toasted.global.minim_error(this.$I18n.t('devise.registrations.account_exists'));
          }, LOADING_TIMEOUT);

          return;
        }

        setTimeout(() => {
          this.loading = false;
          this.errors = error.response.data.error;
        }, LOADING_TIMEOUT);
      }
    },

    validations() {
      let is_valid = true;

      if(!this.accepted && this.showTOS) {
        this.$toasted.global.minim_error(this.$I18n.t('terms_of_service.error'));
        is_valid = false;
      }

      this.errors.password = Validators.validatePassword(this.password);
      this.errors.email = Validators.validateEmail(this.email);

      return is_valid && this.errors.password.length === 0 && this.errors.email.length === 0;
    }
  }
};
</script>