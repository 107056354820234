<template>
  <div :class="['minim-mobile-card', cardMargin]">
    <minim-mobile-icon
      :iconName="iconName"
      :color="color"
      :size="size"
      :skeletal="skeletal"
      :isCardHeaderIcon="true"
    />
    <div :class="contentMargin">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    iconName: {
      type: String,
      required: false
    },

    skeletal: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      required: true
    },

    size: {
      type: String,
      required: true,
      validator(value) {
        // Should be one of these three sizes as these are the only three sizes with classes defined
        // more sizes can be defined within the minim_icon.scss file
        return ['small', 'medium', 'large'].includes(value);
      }
    },

    noContent: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    cardMargin() {
      return this.size === 'small' ? 'm-t-24' : 'm-t-72';
    },

    contentMargin() {
      if(this.noContent) {
        return '';
      }
      return this.size === 'small' ? 'm-t-6' : 'm-t-16';
    }
  }
};
</script>
