<template>
  <div>
    <!-- Confetti animation -->
    <transition name="fade">
      <canvas class="confetti" id="canvas" v-show="showConfetti" />
    </transition>
    <transition name="fade">
      <div class="minim-mobile-card card-transition-duration" v-if="showAppFeedbackComponent">
        <div class="flex-container justify-end">
          <minim-mobile-icon class="close-button" iconName="close" color="neutral-darker" size="small" @click="hideCard" />
        </div>
        <!-- Help Section -->
        <transition name="fade">
          <div v-if="showHelpSection" class="m-b-16">
            <h3 class="minim-mobile-text primary-font medium centered" v-html="title"></h3>
            <p class="minim-mobile-text secondary-font large centered m-t-32 m-b-24">
              {{ $I18n.t('mobile.components.add_feedback_card.help_description') }}
            </p>

            <div class="p-t-4 flex-container justify-center">
              <button class="minim-mobile-primary-button primary-color ghost small m-6" @click="hideCard">
                {{ $I18n.t('no') }}
              </button>
              <button class="minim-mobile-primary-button primary-color small m-6 shadow-large" @click="onClickYes">
                {{ $I18n.t('yes') }}
              </button>
            </div>
          </div>
        </transition>

        <!-- Thumbs-up buttons -->
        <transition name="fade">
          <div v-if="!showHelp">
            <h3 class="minim-mobile-text primary-font medium centered" v-html="title"></h3>
            <div class="flex-container justify-center m-t-28 p-b-4">
              <button :class="['minim-mobile-primary-button shadow-none m-l-12', isPositiveFeedback ? 'positive-color' : 'ghost']"
                @click="onFeedbackSelect(1)">
                <minim-mobile-icon iconName="thumbs-up-24" :color="isPositiveFeedback ? '' : 'primary'" size="small" />
              </button>
              <button :class="['minim-mobile-primary-button shadow-none m-l-12', isNegativeFeedback ? 'negative-color' : 'ghost']"
                @click="onFeedbackSelect(0)">
                <minim-mobile-icon iconName="thumbs-up-24" :color="isNegativeFeedback ? '' : 'primary'" size="small"
                  :class="'rotate-180'" />
              </button>
            </div>
          </div>
        </transition>

        <!-- Negative feedback form -->
        <transition name="fade">
          <div v-if="isNegativeFeedback && !showHelp" class="m-b-16">
            <p class="minim-mobile-text secondary-font large centered m-t-32 m-b-32">
              {{ $I18n.t('mobile.components.add_feedback_card.bad_feedback_title') }}
            </p>
            <div class="p-t-4">
              <minim-mobile-multiline-text-field
                name="app-feedback-comment"
                v-model="comment"
                :enableScroll="true"
                :placeholder="$I18n.t('mobile.components.add_feedback_card.comment_placeholder')" />
              <button class="minim-mobile-primary-button large primary-color m-t-32" @click="submitNegativeFeedback" :disabled="loading">
                {{ $I18n.t('mobile.components.add_feedback_card.submit') }}
              </button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import minim_api from 'mobile/shared/utils/minim_api';
import confetti from 'mobile/shared/utils/confetti';
import Logger from 'mobile/shared/utils/logger';

export default {

  props: {
    triggeredScreen: {
      type: String
    },
  },

  data() {
    return {
      feedbackType: null,
      comment: '',
      timeout: null,
      showHelp: false,
      withDelay: false,
      showConfetti: false,
      loading: false
    };
  },

  async created() {
    await this.$store.dispatch('AppFeedbackStore/fetchOnboardingFeedbackPrompts');
  },

  computed: {
    ...mapState('BrandingStore', ['brand', 'brandNiceName']),
    ...mapGetters({
      currentLan: 'LanStore/currentLan',
      unums: 'UnumStore/unums',
      showAppFeedbackByLan: 'AppFeedbackStore/showAppFeedbackByLan'
    }),

    title() {
      if(this.showHelp) {
        return this.$I18n.t('mobile.components.add_feedback_card.help_title');
      }else if (this.isPositiveFeedback) {
        return this.$I18n.t('mobile.components.add_feedback_card.title_2', { brand: this.brandNiceName });
      }else {
        return this.$I18n.t('mobile.components.add_feedback_card.title_1');
      }
    },

    isPositiveFeedback() {
      return this.feedbackType === 1;
    },

    isNegativeFeedback() {
      return this.feedbackType === 0;
    },

    macAddresses() {
      return this.unums.map((unum) => unum.id);
    },

    showHelpSection() {
      return this.isNegativeFeedback && this.showHelp && this.withDelay;
    },

    showAppFeedbackComponent() {
      return this.showAppFeedbackByLan(this.currentLan.id);
    }
  },

  methods: {
    onFeedbackSelect(type) {
      if (type === this.feedbackType && this.feedbackType !== null) {
        this.feedbackType = null;
        return;
      }

      this.feedbackType = type;

      if (this.isPositiveFeedback) {
        this.showConfetti = true;
        confetti.loadConfetti(this.brand, true);
        setTimeout(() => {
          this.submitPositiveFeedback();
        }, 2000);
      }
    },

    async submitPositiveFeedback() {
      let payload = {
        thumbs_up: true,
        triggered_screen: this.triggeredScreen,
        mac_addresses: this.macAddresses,
        lan_id: this.currentLan.id
      };

      if(!this.loading) {
        try {
          this.loading = true;
          await minim_api.post('api/v1/user_feedback', payload);
          this.loading = false;
          this.hideCard();
        } catch (error) {
          this.loading = false;
          Logger.error(JSON.stringify(error));
          this.$toasted.global.minim_error(this.$I18n.t('mobile.components.add_feedback_card.unable_to_submit_feeback_error'));
        }
      }
    },

    async submitNegativeFeedback() {
      let payload = {
        thumbs_up: false,
        triggered_screen: this.triggeredScreen,
        mac_addresses: this.macAddresses,
        comments: this.comment,
        lan_id: this.currentLan.id
      };

      if(!this.loading) {
        try {
          this.loading = true;
          await minim_api.post('api/v1/user_feedback', payload);
          this.loading = false;
          this.showHelp = true;
          setTimeout(() => { this.withDelay = true; }, 650);
        } catch (error) {
          this.loading = false;
          Logger.error(JSON.stringify(error));
          this.$toasted.global.minim_error(this.$I18n.t('mobile.components.add_feedback_card.unable_to_submit_feeback_error'));
        }
      }
    },

    async hideCard() {
      await this.resetOnboardingFeedbackByLanStore();
      this.showConfetti = false;
    },

    async onClickYes() {
      await this.resetOnboardingFeedbackByLanStore();
      this.$router.push({name: 'customer_support_info_index_path', query: { tab_name: 'support' }});
    },

    async resetOnboardingFeedbackByLanStore() {
      await this.$store.dispatch('AppFeedbackStore/deleteOnboardingFeedbackByLan', { uuid: this.currentLan.id });
    }
  }
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}

.fade-enter-active, .fade-leave-active {
  opacity: 1;
  transition: opacity 0.5s ease 0.225s;
  height: auto;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.card-transition-duration {
  transition-duration: .5s;
  transition-property: all;
}
.confetti {
  position: fixed;
  background: transparent;
  z-index: 9999999 !important;
  top: 0;
  left: 0;
}
</style>