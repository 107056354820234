<template>
  <div class="text-center" style="padding-bottom: 130px;">
    <h2 class="unum-card-header-text">
      {{ headerText }}
    </h2>
    <p class="minim-mobile-text secondary-font medium text-neutral-lightest-color m-t-12">
      {{ $I18n.t('mobile.components.unum_card.reboot_your_network') }}
    </p>
    <button
      @click="reboot"
      class="minim-mobile-primary-button small negative-text neutral-lightest-color m-t-24"
    >
      {{ $I18n.t('reboot') }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UnumStatusMixin from 'mobile/shared/mixins/unum_status_mixin';

export default {
  mixins: [UnumStatusMixin],

  computed: {
    ...mapGetters('UnumStore', ['primaryUnum', 'offlineUnums', 'onlineUnums']),
    ...mapGetters('LanStore', ['currentLan']),

    extenderTerm() {
      return this.primaryUnum.hardware_kind_id === 'zoom_7020'
        ? this.$I18n.t('mobile.components.unum_card.satellites')
        : this.$I18n.t('mobile.components.unum_card.extenders');
    },

    headerText() {
      const status = this.$I18n.t('mobile.components.unum_card.offline');

      return this.generateUnumStatusText(this.offlineUnums, status, this.extenderTerm);
    }
  },

  methods: {
    async reboot() {
      await this.$store.dispatch('UnumStore/rebootAll', { lanId: this.currentLan.id });
    }
  }
};
</script>
