<template>
  <div v-if="showCard" class="minim-mobile-card p-b-48">
    <div class="flex-container justify-between">
      <h2 v-skeleton="showSkeleton" class="minim-mobile-text primary-font small">
        {{ $I18n.t('mobile.content_filters_v2.card.title') }}
      </h2>
      <minim-mobile-card-link
        :text="$I18n.t('mobile.content_filters_v2.card.recently_blocked')"
        :to="recentlyBlockedPageRouteConfig"
        :showSkeleton="showSkeleton"
      />
    </div>

    <div v-if="personId">
      <h2 v-skeleton="{ showSkeleton, styles: { width: '124px', height: '30px' } }" class="minim-mobile-text primary-font large m-t-32">
        {{ $I18n.n(totalFilteredQueries) }}/{{ $I18n.n(totalQueries) }}
      </h2>
      <p v-skeleton="{ showSkeleton, styles: { width: '124px', height: '30px' } }" class="minim-mobile-text secondary-font medium text-neutral-darker-color p-t-4">
        {{ $I18n.t('mobile.content_filters_v2.card.visits_blocked', { percentage: $I18n.n(blockedVisitsPercentage, { style: 'percent' }) }) }}
      </p>

      <div class="blocked-sites-percentage-bar m-t-24">
        <div class="inner-bar" :style="`width: ${Math.round(blockedVisitsPercentage * 100)}%;`" />
      </div>
    </div>

    <router-link
      v-if="!personId"
      tag="button"
      class="m-t-48 minim-mobile-primary-button bg-primary-color"
      v-skeleton="{ showSkeleton, classes: ['skeleton-bg-primary'] }"
      :to="{
        name: 'lan_content_filters_add_domain_to_list_path',
        params: {
          lanId: $route.params.lanId,
          listKind: 'blocked'
        }
      }"
    >
      {{ $I18n.t('mobile.content_filters_v2.card.filter_a_website') }}
    </router-link>

    <template v-if="personId">
      <hr class="minim-mobile-divider m-t-32">
      <filter-list-stats-section
        :filteredDomains="queryStats.filtered_domains"
        :personId="personId"
        :showSkeleton="showSkeleton"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import FilterListStatsSection from './filter_list_stats_section';

export default {
  components: {
    'filter-list-stats-section': FilterListStatsSection
  },

  props: {
    personId: {
      type: String,
      default: null
    }
  },

  created() {
    if (this.personId) {
      this.$store.dispatch('PersonStore/fetchQueryStats', this.personId);
    } else {
      this.$store.dispatch('LanStore/fetchQueryStats');
    }
  },

  computed: {
    ...mapState('UserStore', ['user']),
    ...mapGetters({
      lanQueryStats: 'LanStore/quertyStatsForLan',
      lan: 'LanStore/currentLan',
      personQueryStats: 'PersonStore/queryStatsForPerson',
      unumFeatures: 'UnumStore/features'
    }),

    showCard() {
      const filteringIsEnabled = this.lan.filter_settings && !this.lan.filter_settings.includes('disabled');
      const hasRequiredFeatureFlags =
        this.unumFeatures.includes('isp_content_filtering') && this.unumFeatures.includes('hardware_content_filtering');

      return hasRequiredFeatureFlags && filteringIsEnabled;
    },

    queryStats() {
      let queryStats;

      if (this.personId) {
        queryStats = this.personQueryStats(this.personId);
      } else {
        queryStats = this.lanQueryStats;
      }

      return queryStats || {};
    },

    showSkeleton() {
      // show loading skeleton only if we've never fetched any data
      return Object.keys(this.queryStats).length === 0;
    },

    recentlyBlockedPageRouteConfig() {
      if (this.personId) {
        return {
          name: 'person_content_filters_recently_blocked_path',
          params: {
            lanId: this.$route.params.lanId,
            personId: this.personId
          }
        };
      } else {
        return {
          name: 'lan_content_filters_recently_blocked_path',
          params: { lanId: this.$route.params.lanId }
        };
      }
    },

    queries() {
      return this.queryStats.queries || {};
    },

    totalQueries() {
      return this.queries.total || 0;
    },

    totalFilteredQueries() {
      const { blocked = 0, redirected = 0 } = this.queries;
      return blocked + redirected;
    },

    blockedVisitsPercentage() {
      if (this.totalFilteredQueries === 0) return 0;

      return (this.totalFilteredQueries / this.totalQueries);
    }
  }
};
</script>
