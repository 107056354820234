<template>
  <router-link :to="to" class="minim-mobile-text secondary-font medium text-neutral-darker-color">
    <span class="flex-container align-center">
      <span v-skeleton="{ showSkeleton, styles: { width: '98px' } }" class="m-r-8" :class="textClass">{{ text }}</span>
      <minim-mobile-icon
        v-if="displayIcon"
        v-skeleton="{ showSkeleton, classes: ['rounded-infinite'], styles: { width: '22px', height: '22px' } }"
        iconName="actionable"
        size="x-small"
        color="neutral-darker"
      />
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    showSkeleton: {
      type: Boolean
    },

    to: {
      type: Object,
      required: true
    },

    text: {
      type: String,
      default: ' '
    },

    textClass: {
      type: String,
      default: ' '
    },

    displayIcon: {
      type: Boolean,
      default: true
    },
  }
};
</script>
