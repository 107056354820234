<template>
  <header class="mobile-logo-header m-t-16">
    <div v-if="inAppBrandingLogo" class="in-app-brand-logo-container">
      <img :src="inAppBrandingLogo" class="in-app-brand-logo" />
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('BrandingStore', ['brand']),
    ...mapGetters('LanStore', ['currentLan']),

    lanHasInAppBranding() {
      return !!this.currentLan.primary_color;
    },

    inAppBrandingLogo() {
      return this.currentLan.mobile_app_logo;
    },
  }
};
</script>
