import $ from 'jquery';

export default {
  methods: {
    isScrolledIntoView(refs) {
      var docViewTop = $('#app-body').scrollTop();
      var docViewBottom = docViewTop + $('#app-body').height();

      refs = (refs && typeof (refs[0]) !== 'undefined') ? refs[0].$el : refs;
      if (!refs) {
        return false;
      }

      var elemTop = $(refs).offset().top;
      var elemBottom = elemTop + $(refs).height();

      return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
  }
};
