import ActionCable from 'actioncable';
import minim_api from 'mobile/shared/utils/minim_api';

// We use this helper to create new subscriptions
// so that we will always have an action able consumer
// that passes a valid (not expired) access token
// when setting up a new channel subscription
export default function createSubscription(...args) {
  return ActionCable
    .createConsumer(`/cable?access_token=${minim_api.accessToken.access_token}`)
    .subscriptions
    .create(...args);
}
