<template>
  <div class="speed-test-server-info" @click="openGoogleMaps(lat, lon)">
    <div class="server-info">
      <div v-if="validLocation">
        <i class="minim-mobile-icon minim-icon-location-16 small neutral-darker"></i>
      </div>
      <div>
        <div v-if="server_location" class="minim-mobile-text secondary-font medium text-neutral-darker-color">{{ locationText }}</div>
        <div class="minim-mobile-text secondary-font small text-neutral-darker-color">{{ endpoint }}</div>
      </div>
    </div>
    <i v-if="server_location" class="minim-mobile-icon minim-icon-forward x-small neutral-darker"></i>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default{
  props: {
    endpoint: {
      type: String
    },
    server_location: {
      type: String
    },
    lat: {
      type: Number
    },
    lon: {
      type: Number
    }
  },

  methods: {
    openGoogleMaps(lat, lon) {
      if (!this.validLocation) {
        return;
      }
      // Default to google maps,
      // but if device is an iOS, use apple maps instead
      let url = `https://maps.google.com/?q=loc:${lat},${lon}`;
      if (this.currentPlatform.toUpperCase() === 'IOS') {
        url = `https://maps.apple.com/?q=${lat},${lon}`;
      }
      this.$native.exec('open_external_url', url);
    },
  },

  computed: {
    ...mapGetters({
      currentPlatform: 'NativeInfoStore/currentPlatform'
    }),

    locationText() {
      return this.server_location ? this.server_location : 'Address Unavailable';
    },

    validLocation() {
      return this.lat && this.lon;
    }
  }
};

</script>
