function getBrandColors(brand) {
  switch(brand) {
  case 'minim':
    return {
      'light_color': '#006373',
      'dark_color': '#004D59'
    };
  case 'motosync':
    return {
      'light_color': '#0D2C4B',
      'dark_color': '#071828'
    };
  case 'mca':
    return {
      'light_color': '#179EDD',
      'dark_color': '#1486BC'
    };
  case 'trusted-home':
    return {
      'light_color': '#5D176A',
      'dark_color': '#401048'
    };
  case 'ezecom':
    return {
      'light_color': '#6C54A3',
      'dark_color': '#4C3483'
    };
  case 'vgi':
    return {
      'light_color': '#F36E2D',
      'dark_color': '#ea560d'
    };
  case 'intellifi':
    return {
      'light_color': '#3F7F70',
      'dark_color': '#316458'
    };
  case 'inq':
    return {
      'light_color': '#661723',
      'dark_color': '#450f18'
    };
  default:
    return {
      'light_color': '#006373',
      'dark_color': '#004D59'
    };
  }
}

export default {
  loadConfetti(brand, diableClickEvent = false, callback = null) {
    const color = getBrandColors(brand);

    var disable = true;
    //-----------Var Inits--------------
    let canvas = document.getElementById('canvas');
    let ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    let confetti = [];
    const confettiCount = 300;
    const gravity = 0.5;
    const terminalVelocity = 5;
    const drag = 0.075;
    const colors = [
      { front: '#f7f7f7' },
      { front: '#ababab' },
      { front: '#ededed' },
      { front: color.light_color },
      { front: color.dark_color }];
    //-----------Functions--------------
    const randomRange = (min, max) => Math.random() * (max - min) + min;
    const initConfetti = () => {
      for (let i = 0; i < confettiCount; i++) {
        confetti.push({
          color: colors[Math.floor(randomRange(0, colors.length))],
          dimensions: {
            x: randomRange(10, 20),
            y: randomRange(10, 30) },
          position: {
            x: randomRange(0, canvas.width),
            y: canvas.height - 1 },
          rotation: randomRange(0, 2 * Math.PI),
          scale: {
            x: 0.5,
            y: 0.5 },
          velocity: {
            x: randomRange(-25, 25),
            y: randomRange(0, -50) } });
      }
    };
    //---------Render-----------
    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      confetti.forEach((confetto, index) => {
        let width = confetto.dimensions.x * confetto.scale.x;
        let height = confetto.dimensions.y * confetto.scale.y;
        // Move canvas to position and rotate
        ctx.translate(confetto.position.x, confetto.position.y);
        ctx.rotate(confetto.rotation);
        // Apply forces to velocity
        confetto.velocity.x -= confetto.velocity.x * drag;
        confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity);
        confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();
        // Set position
        confetto.position.x += confetto.velocity.x;
        confetto.position.y += confetto.velocity.y;
        // Delete confetti when out of frame
        if (confetto.position.y >= canvas.height) confetti.splice(index, 1);
        // Loop confetto x position
        if (confetto.position.x > canvas.width) confetto.position.x = 0;
        if (confetto.position.x < 0) confetto.position.x = canvas.width;
        // Spin confetto by scaling y
        confetto.scale.y = Math.cos(confetto.position.y * 0.1);
        ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;
        // Draw confetto
        ctx.fillRect(-width / 2, -height / 2, width, height);
        // Reset transform matrix
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      });
      window.requestAnimationFrame(render);
    };
    //---------Execution--------
    initConfetti();
    render();
    if(!diableClickEvent) {
      //------------Click------------
      window.addEventListener('click', function () {
        if(!disable){
          return;
        }
        disable = false;
        setTimeout(() => {
          disable = true;
        }, 1000);
        initConfetti();
      });
    }

    if(callback) {
      document.getElementById('canvas').addEventListener('click', callback, false);
    }
  },

  destroyConfettiEvents(callback) {
    document.getElementById('canvas').removeEventListener('click', callback, false);
  }
};
