<template>
  <div class="time-scale-picker">
    <template v-if="isZoomed">
      <button @click="$emit('undoZoom')" class="time-scale-picker-button">
        <i class="fas fa-undo" />
      </button>
      <button @click="$emit('resetZoom')" class="time-scale-picker-button">
        {{ $I18n.t('mobile.graphs.reset_zoom') }}
      </button>
    </template>
    <template v-else>
      <button
        @click="changeTimeScale(timeScale.value)"
        v-for="timeScale in timeScales"
        :class="[
          'time-scale-picker-button',
          isSelectedTimeScale(timeScale.value) ? 'selected bg-primary-color' : null
        ]"
        :key="timeScale.name"
      >
        {{ timeScale.name }}
      </button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    selectedTimeScale: {
      type: String,
      required: true
    },

    isZoomed: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      timeScales: [
        { value: 'last_hour', name: this.$I18n.t('past_hour') },
        { value: 'last_day', name: this.$I18n.t('past_day') },
        { value: 'last_week', name: this.$I18n.t('past_week') }
      ]
    };
  },

  methods: {
    changeTimeScale(newTimeScale) {
      this.$emit('timeScaleChanged', newTimeScale);
    },

    isSelectedTimeScale(timeScale) {
      return timeScale === this.selectedTimeScale;
    }
  }
};
</script>
