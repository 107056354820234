import AccountDeactivated from './views/privacy_compliance/account_deactivated.vue';
import RevokeData from './views/data_consent/revoke_data.vue';
import DataRevoked from './views/data_consent/data_revoked.vue';
import DoesNotMeetAgeTOS from './views/privacy_compliance/doesnt_meet_age_tos.vue';
import DataPrivacy from './views/data_privacy.vue';
import ForgotPassword from './views/forgot_password.vue';
import LogIn from './views/log_in.vue';
import AgeTOS from './views/privacy_compliance/index.vue';
import Registration from './views/registration.vue';
import TOS from './views/tos.vue';

import apply_metadata from 'mobile/shared/utils/apply_metadata';

const routes = [
  {
    path: 'registration',
    component: Registration,
    name: 'registration_path'
  },
  {
    path: 'log_in',
    component: LogIn,
    name: 'login_path'
  },
  {
    path: 'reset_password',
    component: ForgotPassword,
    name: 'reset_password_path'
  },
  {
    path: 'terms_of_service',
    component: TOS,
    name: 'terms_index_path'
  },
  {
    path: 'age_terms',
    component: AgeTOS,
    name: 'age_terms_path'
  },
  {
    path: 'doesnt_meet_age_tos',
    component: DoesNotMeetAgeTOS,
    name: 'doesnt_meet_age_tos_path'
  },
  {
    path: 'account_deactivated',
    component: AccountDeactivated,
    name: 'account_deactivated_path'
  },
  {
    path: 'revoke_data',
    component: RevokeData,
    name: 'data_revocation_path',
    meta: { layout: 'authentication'}
  },
  {
    path: 'data_revoked',
    component: DataRevoked,
    name: 'data_revoked_path'
  },
  {
    path: 'data_privacy',
    component: DataPrivacy,
    name: 'data_privacy_path'
  }
];

export default apply_metadata(routes, {
  layout: 'authentication',
  statusBarStyle: {
    bgColorHex: '#FFFFFF',
    textMode: 'dark'
  }
});
