<template>
  <form @submit.prevent="login" class="mobile-login-container bg-white" novalidate>
    <div class="w-full text-center m-b-32">
      <login-icon />
      <h2 class="header">{{  $I18n.t('devise.sign_in_header') }}</h2>
      <minim-mobile-text-field
        testId="email_field"
        :placeholder="$I18n.t('devise.sessions.enter_email')"
        v-model="username"
        :errors="errors.user"
        type="email"
      >
        <span slot="leftMostIcon">
          <minim-mobile-icon
            iconName="envelope-16"
            color="neutral-dark"
            size="x-small"
          />
        </span>
      </minim-mobile-text-field>
      <minim-mobile-password-field
        testId="password_field"
        :placeholder="$I18n.t('devise.sessions.enter_password')"
        v-model="password"
        :errors="errors.password"
      />
      <router-link
        class="forgot-password"
        :to="{ name: 'reset_password_path'}"
      >
        {{ $I18n.t('devise.sessions.forgot') }}
      </router-link>
    </div>

    <minim-mobile-spinner v-if="loading" class="p-48" />
    <div v-else>
      <button 
        type="submit" 
        class="minim-mobile-primary-button m-b-32"
        data-test-id="sign_in">
        {{ $I18n.t('devise.sign_in') }}
      </button>
      <router-link
        v-if="brand !== 'mca'"
        :to="{ name: 'registration_path' }"
        tag="button"
        class="minim-mobile-primary-button ghost"
        data-test-id="create_account_btn"
      >
        {{ $I18n.t('devise.sessions.create_account') }}
      </router-link>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';

import LoginIcon from '../components/shared/login_icon';
import GetProperPathMixin from 'mobile/shared/mixins/root_path_mixin';

import analytics from 'mobile/shared/utils/analytics';

const LOADING_TIMEOUT = 1000;

export default {
  mixins: [GetProperPathMixin],

  components: {
    'login-icon': LoginIcon,
  },

  data() {
    return {
      username: '',
      password: '',
      errors: {},
      loading: false
    };
  },

  mounted() {
    if (this.$route.params.flash){
      this.$toasted.show(this.$route.params.flash, {
        duration: 5500,
        fullWidth: true,
        keepOnHover: true,
        closeOnSwipe: false,
        className: ['minim-toast', 'blue-toast'],
      });
    }
  },

  computed: {
    ...mapState('BrandingStore', ['brand'])
  },

  methods: {
    async login() {
      this.loading = true;

      try {
        await this.$store.dispatch('UserStore/login', { email: this.username, password: this.password});
        await window.app.afterLoginSetup();

        analytics.logEvent('mobile_log_in', { successful: true });

        setTimeout(() => { this.loading = false; }, LOADING_TIMEOUT);
        this.getProperPath();
      } catch(error) {
        console.log(error);

        analytics.logEvent('mobile_log_in', { successful: false });

        setTimeout(() => {
          this.loading = false;
          this.$toasted.global.minim_error(this.$I18n.t('devise.failure.invalid'));
        }, 1000);
      }
    }
  }
};
</script>


