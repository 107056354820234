export default {
  // Takes an integer in bytes and returns the highest conversion with unit label
  roundBytes(bytes) {
    if (bytes < 1024) return (window.$I18n.n(bytes) + ' ' + window.$I18n.t('units.bytes_abbrev'));
    bytes = bytes / 1024;
    if (bytes < 1024) return (window.$I18n.n(Math.round(bytes * 10) / 10) + ' ' + window.$I18n.t('units.kilobytes_abbrev'));
    bytes = bytes / 1024;
    if (bytes < 1024) return (window.$I18n.n(Math.round(bytes * 10) / 10) + ' ' + window.$I18n.t('units.megabytes_abbrev'));
    bytes = bytes / 1024;
    if (bytes < 1024) return (window.$I18n.n(Math.round(bytes * 10) / 10) + ' ' + window.$I18n.t('units.gigabytes_abbrev'));
    bytes = bytes / 1024;
    if (bytes < 1024) return (window.$I18n.n(Math.round(bytes * 10) / 10) + ' ' + window.$I18n.t('units.terabytes_abbrev'));
    bytes = bytes / 1024;
    if (bytes < 1024) return (window.$I18n.n(Math.round(bytes * 10) / 10) + ' ' + window.$I18n.t('units.petabytes_abbrev'));
  }
};
