import minim_api from 'mobile/shared/utils/minim_api';
import router from 'mobile/router';
import store from 'mobile/vuex/index.js';

/**
 * This file exports an object containing functions that are meant to be accessible from the NativeBridge.
 * This basically means that the native app can make a call to have functions from this object be executed
 * inside of the application's iframe.
 *
 * When editing this file please be mindful that functions may be used by the native app and changes could
 * effect how the app performs. The Zoom MotoManage app makes heavy use of functions declared in here so please
 * be especially mindful of that.
 */

export default {
  /**
   * Local function meant to be called through the native bridge to allow the native app to make
   * requests to be made from within the Minim iframe. Making the requests from within the iframe
   * is necessary for working around various CORS issues.
   *
   * @param {Object} options - Configuration object for the request
   * Properties:
   *  - method: the request method to be used (i.e. get, post, put, patch, delete)
   *  - url: URL that will be used for the request (do NOT include the endpoint for this)
   *  - opts: optional payload or axios request options
   *  - endpoint: the base endpoint for the request - defaults to the window's origin
   */
  send_request({ method, url, opts, endpoint = window.location.origin }) {
    return minim_api.request_local(method.toLowerCase(), endpoint + url, opts);
  },

  /**
  * A method that exposes a way to change the current route via Vue router's push method.
  *
  * @param {Object} route_config - The configuration for the route you want to go to. Can include name, path, query, params and meta properties.
  */
  router_push(route_config) {
    router.push(route_config);
  },

  /**
  * Gets the current route from the Vue router instance.
  * Extracts out non-relevant properties that are not JSON seriazable
  * in order to allow the object to be passed through the native bridge.
  */
  get_current_route() {
    const current_route = router.currentRoute;

    return {
      name: current_route.name,
      path: current_route.path,
      params: current_route.params,
      query: current_route.query,
      meta: current_route.meta
    };
  },

  async dispatch_action({ action, payload }) {
    return await store.dispatch(action, payload);
  },

  commit_mutation({ mutation, payload }) {
    return store.commit(mutation, payload);
  },

  call_getter({ getter, payload = null }) {
    if (payload) {
      return store.getters[getter](payload);
    } else {
      return store.getters[getter];
    }
  }
};
