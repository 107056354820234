<template>
  <div
    v-skeleton="{ showSkeleton, classes: ['skeleton-bg-primary'] }"
    class="unum-card-header-section"
    :class="headerClass"
  >
    <component
      v-if="!showSkeleton"
      :is="statusComponent"
      :estimatedTime="estimatedTime"
    />

    <div class="header-section-left-indicator" v-if="isSiteIssue">
      <div class="indicator-box">
        <minim-mobile-icon
          iconName="cloud-issue-64"
          size="large"
          color="neutral-lightest"
          class="icon"
        />
      </div>
      <div class="semi-circle-container-inverted">
        <div class="semi-circle-inverted" />
      </div>  
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PowerCycleMixin from 'mobile/shared/mixins/power_cycle_mixin';

import AllUnumsOnline from './header_section_states/all_unums_online';
import PowerCycleDevice from './header_section_states/power_cycle_device';
import CheckInternetService from './header_section_states/check_internet_service';
import ExtendersOffline from './header_section_states/extenders_offline';
import RebootingUnums from './header_section_states/rebooting_unums';
import UpdatingUnums from './header_section_states/updating_unums';
import CloudServicesDisrupted from './header_section_states/cloud_services_disrupted';
import CloudServicesScheduledMaintenance from './header_section_states/cloud_services_scheduled_maintenance';

export default {
  mixins: [PowerCycleMixin],

  components: {
    'all-unums-online': AllUnumsOnline,
    'power-cycle-device': PowerCycleDevice,
    'check-internet-service': CheckInternetService,
    'extenders-offline': ExtendersOffline,
    'rebooting-unums': RebootingUnums,
    'updating-unums': UpdatingUnums,
    'cloud-services-disrupted': CloudServicesDisrupted,
    'cloud-services-scheduled-maintenance': CloudServicesScheduledMaintenance
  },

  props: {
    showSkeleton: {
      type: Boolean,
      required: true
    },

    siteStatus: {
      type: String,
      required: true,
      default: 'none'
    },

    estimatedTime: {
      type: String,
      required: true,
      default: ''
    },

  },

  computed: {
    ...mapGetters('LocalStorageStore', ['lastPowerCycled']),
    ...mapGetters('UnumStore', [
      'unums',
      'primaryUnum',
      'offlineUnums',
      'rebootingUnums',
      'updatingUnums',
    ]),

    isSiteIssue() {
      return ['scheduled_maintenance', 'major', 'critical'].includes(this.siteStatus);
    },

    headerClass() {
      if (this.isSiteIssue) {
        return 'negative-state';
      } else if (this.rebootingUnums.length > 0) {
        return 'negative-state';
      } else if (this.updatingUnums.length > 0) {
        return 'positive-state';
      }  else if (this.offlineUnums.length > 0) {
        return 'negative-state';
      } else {
        return 'normal-state';
      }
    },

    statusComponent() {
      if (this.showSkeleton) return '';

      if (this.siteStatus == 'scheduled_maintenance') {
        return 'cloud-services-scheduled-maintenance';
      } else if (['major', 'critical'].includes(this.siteStatus)) {
        return 'cloud-services-disrupted';
      } else if (this.rebootingUnums.length > 0) {
        return 'rebooting-unums';
      } else if (this.updatingUnums.length > 0) {
        return 'updating-unums';
      } else if (this.offlineUnums.length > 0) {
        if (!this.primaryUnum.is_online) {
          return this.primaryUnumOfflineComponent;
        } else {
          return 'extenders-offline';
        }
      } else {
        return 'all-unums-online';
      }
    },

    primaryUnumOfflineComponent() {
      if (this.hasRecentlyPowerCycledDevice(this.lastPowerCycled, this.primaryUnum)) {
        return 'check-internet-service';
      } else {
        return 'power-cycle-device';
      }
    }
  }
};
</script>
