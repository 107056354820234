<template>
  <div class="minim-mobile-card security-status-card">
    <!-- Main content -->
    <div class="flex-container justify-between">
      <div class="flex-container align-center grow-1" v-skeleton="{ showSkeleton, styles: { width: '60px' }}">
        <h2 class="minim-mobile-text primary-font small">{{ $I18n.t('lans.tabs.security') }}</h2>
        <minim-mobile-icon
          class="informational-button"
          style="top: 3px;"
          iconName="info"
          size="x-small"
          name="security-status-info-tip"
        />
        <info-tippy
          to="security-status-info-tip"
          ref="security-status-info-tip"
          trigger="click"
          :textAlignLeft="true"
        >
          <span class="minim-mobile-text secondary-font medium text-neutral-lightest-color">
            {{ $I18n.t('lans.tabs.info') }}
          </span>
        </info-tippy>
      </div>
      <minim-mobile-card-link
        :showSkeleton="showSkeleton"
        :text="$I18n.t('security.manage')"
        @click.native="logEvent"
        :to="{
          name: 'lan_security_path',
          params: {
            lanId: $route.params.lanId
          }
        }"
      />
    </div>

    <div
      class="security-card-list m-t-32 m-b-12"
      :class="[intrusionsCount > 0 ? 'darker-orange' : '']"
    >
      <div class="flex-container align-center">
        <minim-mobile-icon
          class="minim-mobile-icon positive p-r-12"
          :color="intrusionsCount > 0 ? 'neutral-lightest' : 'neutral-dark'"
          iconName="question-block-64"
          size="medium-large"
        />
          <p class="minim-mobile-text light secondary-font medium text-width-100" :class="[intrusionsCount > 0 ? 'text-neutral-lightest-color' : 'text-neutral-dark-color']">{{ $I18n.t('security.unrecognized_devices') }}</p>
          <span class="badge" v-if="intrusionsCount > 0">{{ intrusionsCount }}</span>
      </div>
    </div>

    <div
      class="security-card-list m-t-12 m-b-12"
      :class="malwareCount > 0 ? severityColor: ''"
    >
      <div class="flex-container align-center">
        <minim-mobile-icon
          class="minim-mobile-icon positive p-r-12"
          :color="malwareCount > 0 ? 'neutral-lightest': 'neutral-dark'"
          iconName="malware-64"
          size="medium-large"
        />
          <p class="minim-mobile-text light secondary-font medium text-width-100" :class="[malwareCount > 0 ? 'text-neutral-lightest-color' : 'text-neutral-dark-color']">{{ $I18n.t('security.malware') }}</p>
          <span class="badge" v-if="malwareCount > 0">{{ malwareCount }}</span>
      </div>
    </div>

    <div
      class="security-card-list m-t-12 m-b-32"
      :class="[vulnerabilitiesCount > 0 ? 'negative' : '']"
    >
      <div class="flex-container align-center">
        <minim-mobile-icon
          class="minim-mobile-icon positive p-r-12"
          :color="vulnerabilitiesCount > 0 ? 'neutral-lightest' : 'neutral-dark'"
          iconName="time-bomb-64"
          size="medium-large"
        />
          <p class="minim-mobile-text light secondary-font medium text-width-100" :class="[vulnerabilitiesCount > 0 ? 'text-neutral-lightest-color' : 'text-neutral-dark-color']">{{ $I18n.t('security.known_vulns') }}</p>
          <span class="badge" v-if="vulnerabilitiesCount > 0">{{ vulnerabilitiesCount }}</span>
      </div>
    </div>

    <h3 v-skeleton="{ showSkeleton, styles: { width: '158px' } }" class="minim-mobile-text primary-font medium-large centered">
      <span v-if="threatCount > 0" class="minim-mobile-text primary-font medium-large text-negative-color m-r-2">
        {{ $I18n.n(threatCount) }}
      </span>
      <span>{{ securityStatusText }}</span>
    </h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import analytics from 'mobile/shared/utils/analytics';
import IssueSeverityMixin from 'mobile/shared/mixins/issue_severity_mixin.js';
import InfoTippy from 'mobile/shared/components/buttons/info_tippy.vue';

export default {
  mixins: [IssueSeverityMixin],

  components: {
    'info-tippy': InfoTippy
  },

  data() {
    return {
      loading: true
    };
  },

  async mounted() {
    await this.$store.dispatch('SecurityAuditStore/index');
    this.loading = false;
  },

  computed: {
    ...mapGetters({
      lanIsSafe: 'SecurityAuditStore/lanIsSafe',
      intrusions: 'SecurityAuditStore/intrusions',
      malware: 'SecurityAuditStore/malware',
      metrics: 'SecurityAuditStore/metrics',
      threatCount: 'SecurityAuditStore/threatCount',
      unknownDevices: 'SecurityAuditStore/unknownDevices',
      vulnerabilities: 'SecurityAuditStore/vulnerabilities',
    }),

    securityStatusText() {
      if (this.lanIsSafe) {
        return this.$I18n.t('security.safe_and_sound');
      } else {
        // Be grammatically correct about the amount of threats detected
        return this.threatCount === 1 ? this.$I18n.t('security.threat_detected') : this.$I18n.t('security.threats_detected');
      }
    },

    showSkeleton() {
      return this.loading;
    },

    intrusionsCount() {
      return this.intrusions.length + this.unknownDevices.length;
    },

    vulnerabilitiesCount() {
      return this.vulnerabilities.length;
    },

    malwareCount() {
      return this.malware.length;
    },

    incident() {
      return this.malware.length > 0 ? this.malware[0] : {};
    },

    issue() {
      return this.incident.issue;
    },

    severityColor() {
      return this.severityClass((this.issue || {}).severity);
    }
  },

  methods: {
    logEvent() {
      analytics.logEvent('security_center_card_full_view');
      return null;
    }
  }
};
</script>
